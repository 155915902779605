import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import { TagOutlined, DollarCircleOutlined } from "@ant-design/icons";
import { updateItemApi } from "../../../../api/billing/item";
import { getAccessToken } from "../../../../api/auth";
import { getCategoriesActiveApi } from "../../../../api/billing/category";
//import { minLengthValidation, numberValidation } from "../../../../utils/formValidation";

import "./EditItemForm.scss";
import { getTypesActiveApi } from "../../../../api/billing/type";
import { getBrandsActiveApi } from "../../../../api/billing/brand";
import { getUnitsActiveApi } from "../../../../api/billing/unit";

export default function EditItemForm(props) {
  const { item, setIsVisibleModal, setReloadItems } = props;
  const [itemData, setItemData] = useState({});
  const [listCategoriesActive, setListCategoriesActive] = useState([]);
  const [listTypesActive, setListTypesActive] = useState([]);
  const [listBrandsActive, setListBrandsActive] = useState([]);
  const [listUnitsActive, setListUnitsActive] = useState([]);

  //estado relacionado a si el usuario realizó modificación en los inputs que deben ser validados
  //const [modifDataToValid, setModifDataToValid] = useState({ item_code: false, description: false });
  //estado que almacena el resultado de validacion de los campos correspondientes
  //const [validData, setValidData] = useState({ item_code: false, });

  //trae los datos del producto
  useEffect(() => {
    setItemData({
      item_code: item.item_code,
      description: item.description,
      category: item.category?item.category._id : null,
      sku: item.sku,
      brand: item.brand? item.brand._id : null,
      type:item.type? item.type._id : null,
      unit_1: item.unit_1?item.unit_1._id : null,
      unit_2: item.unit_2?item.unit_2._id : null,
      unit_:item.unit_3?item.unit_3._id : null
    });
  }, [item]);

  const accessToken = getAccessToken();

  useEffect(() => {
    getCategoriesActiveApi(accessToken, true).then((response) => {
      setListCategoriesActive(response.categories);
    });
  }, []);

  useEffect(() => {
    getTypesActiveApi(accessToken, true).then((response) => {
      setListTypesActive(response.types);
    });
  }, []);

  useEffect(() => {
    getBrandsActiveApi(accessToken, true).then((response) => {
      setListBrandsActive(response.brands);
    });
  }, []);

  useEffect(() => {
    getUnitsActiveApi(accessToken, true).then((response) => {
      setListUnitsActive(response.units);
    });
  }, []);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (
      !itemData.item_code ||
      !itemData.description ||
      !itemData.category ||
      !itemData.type 
    ) {
      notification["error"]({
        message:
          "Obligatorios: Código, Descripcion, categoria y Tipo.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const updateItem = (e) => {
    e.preventDefault();
    console.log(itemData)
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let itemUpdate = itemData; //es let porque se va actualizando

      updateItemApi(token, itemUpdate, item._id).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadItems(true);
        }
      });
    }
  };

  return (
    <div className="edit-item-form">
      <EditForm
        itemData={itemData}
        setItemData={setItemData}
        updateItem={updateItem}
        listCategoriesActive={listCategoriesActive}
        listTypesActive={listTypesActive}
        listBrandsActive={listBrandsActive}
        listUnitsActive={listUnitsActive}
      />
    </div>
  );
}

function EditForm(props) {
  const { itemData, setItemData, updateItem, listCategoriesActive, listBrandsActive, listTypesActive, listUnitsActive } = props;
  const { Option } = Select;

  return (
    <Form className="form-edit" onSubmit={updateItem}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <Input
              prefix={<TagOutlined />}
              placeholder="Código"
              value={itemData.item_code}
              onChange={(e) =>
                setItemData({ ...itemData, item_code: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Descripción
          </label>
          <Form.Item>
            <Input
              placeholder="Descripcion"
              value={itemData.description}
              onChange={(e) =>
                setItemData({ ...itemData, description: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={12}>
          <label className="control-label">
            Tipo
          </label>
          <Form.Item>
            <Select
              placeholder="Selecciona Tipo"
              value={itemData.type}
              onChange={(e) => setItemData({ ...itemData, type: e })}
            >
              {listTypesActive
                ? listTypesActive.map((type) => {
                    return (
                      <Option value={type._id}>
                        {type.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">SKU</label>
          <Form.Item>
            <Input
              placeholder="SKU"
              name="sku"
              value={itemData.sku}
              onChange={(e) =>
                setItemData({ ...itemData, sku: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Categoria
          </label>
          <Form.Item>
            <Select
              placeholder="Selecciona Categoria"
              value={itemData.category}
              onChange={(e) => setItemData({ ...itemData, category: e })}
            >
              {listCategoriesActive
                ? listCategoriesActive.map((category) => {
                    return (
                      <Option key={category._id}>{category.description}</Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Marca
          </label>
          <Form.Item>
            <Select
              placeholder="Selecciona Marca"
              value={itemData.brand}
              onChange={(e) => setItemData({ ...itemData, brand: e })}
            >
              {listBrandsActive
                ? listBrandsActive.map((brand) => {
                    return (
                      <Option value={brand._id}>
                        {brand.name}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
             Unidad 1
          </label>
          <Form.Item>
          <Select
              placeholder="Selecciona Unidad"
              value={itemData.unit_1}
              onChange={(e) => setItemData({ ...itemData, unit_1: e })}
            >
              {listUnitsActive
                ? listUnitsActive.map((unit) => {
                    return (
                      <Option value={unit._id}>
                        {unit.name}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
             Unidad 2
          </label>
          <Form.Item>
          <Select
              placeholder="Selecciona Unidad"
              value={itemData.unit_2}
              onChange={(e) => setItemData({ ...itemData, unit_2: e })}
            >
              {listUnitsActive
                ? listUnitsActive.map((unit) => {
                    return (
                      <Option value={unit._id}>
                        {unit.name}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            Unidad 3
          </label>
          <Form.Item>
          <Select
              placeholder="Selecciona Unidad"
              value={itemData.unit_3}
              onChange={(e) => setItemData({ ...itemData, unit_3: e })}
            >
              {listUnitsActive
                ? listUnitsActive.map((unit) => {
                    return (
                      <Option value={unit._id}>
                        {unit.name}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateItem}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
