import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  notification,
  Table,
  Divider,
  Popconfirm,
  Typography,
  TimePicker,
} from "antd";
import {
  TagOutlined,
  PlusOutlined,
  CloseOutlined,
  UserOutlined,
  SaveOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  addMerchandiseOrderApi,
  getMerchandiseOrderLastItemPriceApi,
  getNextSequenceCodeByBranchApi,
} from "../../../../api/billing/merchandiseOrder";
import { getUserByIdApi } from "../../../../api/user";
import { getSuppliersActiveApi } from "../../../../api/billing/supplier";
import { getSalePointsByUserApi } from "../../../../api/billing/salePoint";
import { getStateBillsActiveApi } from "../../../../api/billing/stateBill";
import { getDeliveryTermsActiveApi } from "../../../../api/billing/deliveryTerm";
// import { getSaleConditionsActiveApi } from "../../../../api/billing/saleCondition";
import { getPaymentTypesActiveApi } from "../../../../api/billing/paymentType";
import { getItemsActiveApi } from "../../../../api/billing/item";
import { getAccessToken } from "../../../../api/auth";
import { getBillTypesActiveApi } from "../../../../api/billing/billType";
import jwtDecode from "jwt-decode";
//import { minLengthValidation, numberValidation } from "../../../../utils/formValidation";

import "./AddMerchandiseOrderForm.scss";
import TextArea from "antd/lib/input/TextArea";
import {
  formatDateCalendar,
  formatDateView,
} from "../../../../utils/formatDate";
import { useBranch } from "../../../../hooks/useBranch";
import moment from "moment";
import { getBranchApi } from "../../../../api/branch";
import { getCoinTypesActiveApi } from "../../../../api/billing/coinType";

const { Text } = Typography;

export default function AddMerchandiseOrderForm(props) {
  const { setIsVisibleModal, setReloadMerchandiseOrders } = props;

  const [activeSuppliers, setActiveSuppliers] = useState([]);
  const [activeDeliveryTerms, setActiveDeliveryTerms] = useState([]);
  const [activePaymentTypes, setActivePaymentTypes] = useState([]);
  const [activeItems, setActiveItems] = useState([]);
  const [activeBillTypes, setActiveBillTypes] = useState([]);
  const [activeCoinTypes, setActiveCoinTypes] = useState([]);

  const [activeUserSalePoints, setActiveUserSalePoints] = useState([]);
  const [buyerUsername, setBuyerUsername] = useState("");
  const [idMerchandiseOrder, setIdMerchandiseOrder] = useState("");
  const [cancelledBill, setCancelledBill] = useState(false);
  const [billsData, setBillsData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [newCode, setNewCode] = useState(null);

  const accessToken = getAccessToken();
  const metaToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  const { branch } = useBranch();

  const [inputs, setInputs] = useState({
    code: "",
    billType: "",
    // saleCondition: "",
    paymentType: "",
    date: formatDateCalendar(moment()),
    total: 0,
    items: [],
    item: "",
    buyer: metaToken._id,
    quantity: 1,
    unitValue: "",
    //coin:"pesos",
    paid: false,
    active: true,
    branch: branch,
  });

  useEffect(() => {
    getUserByIdApi(accessToken, metaToken._id).then((response) => {
      setBuyerUsername(response.user.username);
    });
  }, []);

  useEffect(() => {
    getSalePointsByUserApi(accessToken, metaToken._id).then((response) => {
      setActiveUserSalePoints(response.salePoints);
    });
  }, []);

  useEffect(() => {
    getSuppliersActiveApi(accessToken, true).then((response) => {
      setActiveSuppliers(response.suppliers);
    });
  }, []);

  useEffect(() => {
    getDeliveryTermsActiveApi(accessToken, true).then((response) => {
      setActiveDeliveryTerms(response.deliveryTerms);
    });
  }, []);

  useEffect(() => {
    getPaymentTypesActiveApi(accessToken, true).then((response) => {
      setActivePaymentTypes(response.paymentTypes);
    });
  }, []);

  useEffect(() => {
    getItemsActiveApi(accessToken, true).then((response) => {
      setActiveItems(response.items);
    });
  }, []);

  useEffect(() => {
    getBillTypesActiveApi(accessToken, true).then((response) => {
      setActiveBillTypes(response.billTypes);
    });
  }, []);

  useEffect(() => {
    getCoinTypesActiveApi(accessToken, true).then((response) => {
      setActiveCoinTypes(response.coinTypes);
    });
  }, []);

  useEffect(() => {
    if (branch && branch !== 0 && branch !== "0") {
      getBranchApi(accessToken, branch).then((response) => {
        setBranchData(response.branch);
      });
    }
  }, [branch]);

  useEffect(() => {
    getNextSequenceCodeByBranchApi(accessToken, branch).then((response) => {
      setNewCode(response.code);
    });
  }, [branch]);

  useEffect(() => {
    setInputs({ ...inputs, code: newCode });
  }, [newCode]);

  //Validar el formulario en relacion a los datos ingresados
  const isFormValid = (e) => {
    let errorExists = false;
    if (!inputs.date || !inputs.items || !inputs.buyer || !inputs.supplier) {
      notification["error"]({
        message: "Obligatorios: Comprador, Proveedor y Fecha.",
      });
      errorExists = true;
    }
    if (inputs.items.length === 0) {
      notification["error"]({
        message: "Obligatorio: Debe seleccionar al menos un producto.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  //Agregar una nueva orden de compra
  const addMerchandiseOrder = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();
      console.log(inputs);
      await addMerchandiseOrderApi(accessToken, inputs, metaToken._id)
        .then((response) => {
          if (
            response === "ERR_CONNECTION_REFUSED" ||
            response === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: "Factura de compra creada",
            });
            setIsVisibleModal(false);
            setIdMerchandiseOrder(response.merchandiseOrder._id);
          }
          setReloadMerchandiseOrders(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-merchandiseorder-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        addMerchandiseOrder={addMerchandiseOrder}
        activeSuppliers={activeSuppliers}
        activeDeliveryTerms={activeDeliveryTerms}
        // activeSaleConditions={activeSaleConditions}
        activePaymentTypes={activePaymentTypes}
        accessToken={accessToken}
        buyerUsername={buyerUsername}
        activeUserSalePoints={activeUserSalePoints}
        activeItems={activeItems}
        activeCoinTypes={activeCoinTypes}
        activeBillTypes={activeBillTypes}
        billsData={billsData}
        setBillsData={setBillsData}
        cancelledBill={cancelledBill}
        setCancelledBill={setCancelledBill}
        branchData={branchData}
      />
    </div>
  );
}

function AddForm(props) {
  const {
    inputs,
    setInputs,
    addMerchandiseOrder,
    activeSuppliers,
    // activeSaleConditions,
    activePaymentTypes,
    accessToken,
    buyerUsername,
    activeUserSalePoints,
    activeItems,
    activeDeliveryTerms,
    branchData,
    activeCoinTypes,
  } = props;

  const { Option } = Select;

  //Funcion que setea el valor del array de items
  const setListItems = (items) => {
    setInputs({ ...inputs, items: items });
  };

  //Funcion que agrega item al array de items
  const addItems = () => {
    if (inputs.item && inputs.quantity) {
      //Verifico si el item no esta en el detalle
      let itemExistInDetail = inputs.items.find((i) => i._item === inputs.item);
      if (!itemExistInDetail) {
        let item = activeItems.filter((i) => i._id === inputs.item)[0];
        let description = item.description;
        let item_code = item.item_code;

        const newData = {
          _item: inputs.item,
          item_code: item_code,
          description: description,
          unitValue: inputs.unitValue,
          unitPrice: inputs.unitPrice,
          quantity: inputs.quantity,
        };
        // setItemsData([...itemsData, newData]);
        setInputs({ ...inputs, items: [...inputs.items, newData] });
      } else {
        notification["error"]({
          message: "El producto ya se encuentra en el detalle de la factura",
        });
      }
    } else {
      notification["error"]({
        message: "Debe elegir al menos un producto y la cantidad requerida",
      });
    }
  };

  // useEffect(() => {
  //   if (inputs.item) {
  //     getMerchandiseOrderLastItemPriceApi(accessToken, inputs.item).then((response) => {
  //       if (response.merchandiseOrder) {
  //         let itemSelected = response.merchandiseOrder.items.filter(
  //           (i) => i._item === inputs.item
  //         );
  //         if (itemSelected) {
  //           let date = response.merchandiseOrder.date;
  //           setInputs({
  //             ...inputs,
  //             unitValue: { value: itemSelected[0].unitPrice, date: date },
  //             unitPrice: null,
  //           });
  //         }
  //       } else {
  //         setInputs({ ...inputs, unitValue: null, unitPrice: null });
  //       }
  //     });
  //   }
  // }, [inputs.item]);

  // useEffect(() => {
  //   if(!inputs.salePoint && activeUserSalePoints.length > 0){
  //     setInputs({...inputs, salePoint: activeUserSalePoints[0]._id});
  //   }
  //   }, [activeUserSalePoints]);

  //Calcular el total en relacion a la lista de items seleccionados
  // useEffect(() => {
  //   let totals = 0;
  //   inputs.items.forEach(({ unitPrice, quantity }) => {
  //     totals += unitPrice * quantity;
  //   });
  //   setInputs({ ...inputs, total: totals });
  // }, [inputs.items]);

  return (
    <Form
      className="form-add"
      layout="vertical"
      bodyStyle={{ height: "100vh" }}
      size={"middle"}
      onSubmit={addMerchandiseOrder}
    >
      {/* <Divider orientation="left">Datos</Divider> */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">Código</label>
          <Form.Item required>
            <Input
              name="code"
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={
                inputs.code ? inputs.code.toString().padStart(6, "0") : null
              }
              //onChange={(e) => setInputs({ ...inputs, code: e.target.value })}
              //disabled={true}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Comprador
          </label>
          <Form.Item>
            <Input
              showSearch
              name="buyerUsername"
              value={buyerUsername}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <label className="control-label">Sucursal</label>
            <Input
              showSearch
              name="branch"
              value={branchData.name}
              onChange={(e) => setInputs({ ...inputs, branch: e.target.value })}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Proveedor
          </label>
          <Form.Item>
            <Select
              showSearch
              prefix={<UserOutlined />}
              name="supplier"
              value={inputs.supplier}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  supplier: e,
                })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activeSuppliers
                ? activeSuppliers.map((i) => {
                    return <Option key={i._id}>{i.denomination}</Option>;
                  })
                : "No hay proveedores disponibles"}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Fecha de Carga
          </label>
          <Form.Item rules={[{ required: true }]}>
            <Input
              type="date"
              placeholder="Fecha"
              value={inputs.date}
              onChange={(e) => setInputs({ ...inputs, date: e.target.value })}
              required
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <label className="control-label">Tipo de Pago</label>
          <Form.Item>
            <Select
              showSearch
              name="paymentType"
              value={inputs.paymentType}
              onChange={(e) => setInputs({ ...inputs, paymentType: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activePaymentTypes
                ? activePaymentTypes.map((i) => {
                    return <Option key={i.description}>{i.description}</Option>;
                  })
                : "No hay tipos de pago disponibles"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Moneda</label>
          <Form.Item rules={[{ required: true }]}>
            <Select
              showSearch
              name="coinType"
              value={inputs.coinType}
              //defaultValue={"pesos"}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  coinType: e,
                })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activeCoinTypes
                ? activeCoinTypes.map((i) => {
                    return <Option key={i._id}>{i.description}</Option>;
                  })
                : "No hay monedas disponibles"}
            </Select>
          </Form.Item>
        </Col>
        {inputs.coinType !== "pesos" && inputs.coinType ? (
          <Col span={6}>
            <label className="control-label">Cotizacion</label>
            <Form.Item rules={[{ required: true }]}>
              <Input
                placeholder="Cotizacion"
                value={inputs.quotation}
                onChange={(e) =>
                  setInputs({ ...inputs, quotation: e.target.value })
                }
                required
              />
            </Form.Item>
          </Col>
        ) : null}
      </Row>

      <Divider orientation="left" style={{ margin: 5 }}></Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={5}>
          <label className="control-label">
            <span className="control-required">*</span> Producto/Servicio
          </label>
          <Form.Item>
            <Select
              showSearch
              placeholder="Seleccione un producto/servicio"
              name="items"
              value={inputs.item}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  item: e,
                })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activeItems
                ? activeItems.map((i) => {
                    return <Option key={i._id}>{i.description}</Option>;
                  })
                : "No hay productos disponibles"}
            </Select>
          </Form.Item>
        </Col>
        {/* <Col span={5}>
          <label className="control-label">Precio última compra</label>
          <Form.Item>
            <Input
              name="unitValue"
              value={
                inputs.unitValue
                  ? "$" +
                    inputs.unitValue.value +
                    " - " +
                    formatDateView(inputs.unitValue.date)
                  : null
              }
              onChange={(e) =>
                setInputs({ ...inputs, unitValue: e.target.value })
              }
              disabled={true}
            />
          </Form.Item>
        </Col> */}
        {/* <Col span={5}>
          <label className="control-label">Precio Unitario</label>
          <Form.Item>
            <Input
              name="unitValue"
              value={inputs.unitPrice}
              onChange={(e) =>
                setInputs({ ...inputs, unitPrice: e.target.value })
              }
            />
          </Form.Item>
        </Col> */}
        <Col span={5}>
          <label className="control-label">Cantidad</label>
          <Form.Item>
            <InputNumber
              placeholder="Cantidad"
              value={inputs.quantity}
              onChange={(e) => setInputs({ ...inputs, quantity: e })}
              min={1}
              max={100}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={4} align="center">
          <Form.Item>
            <Button
              type="primary"
              className="button-add"
              size="middle"
              onClick={addItems}
            >
              <PlusOutlined /> Agregar
            </Button>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          {/* {inputs.items.length > 0 ? ( */}
          <Items itemsData={inputs.items} setItemsData={setListItems} />
          {/* ) : null} */}
        </Col>
      </Row>
      <Divider orientation="left" style={{ margin: 5 }}></Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {/* <Col span={6}>
          <label className="control-label">TOTAL</label>
          <Form.Item>
            <Input
              type="text"
              disabled={true}
              value={inputs.total}
              // onChange={(e) => setInputs({ ...inputs, total: e.target.value })}
            />
          </Form.Item>
        </Col> */}
        <Col span={6}>
          <label className="control-label">
            {/* <span className="control-required">*</span> */} Plazo de Entrega
          </label>
          <Form.Item>
            <Select
              showSearch
              // placeholder="Seleccione un plazo de entrega"
              name="deliveryTerm"
              value={inputs.deliveryTerm}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  deliveryTerm: e,
                })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activeDeliveryTerms
                ? activeDeliveryTerms.map((i) => {
                    return <Option key={i._id}>{i.description}</Option>;
                  })
                : "No hay plazo de entrega disponibles"}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">Fecha de Entrega</label>
          <Form.Item>
            <Input
              type="date"
              placeholder="Fecha"
              value={inputs.deliveryDate}
              onChange={(e) =>
                setInputs({ ...inputs, deliveryDate: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Hora desde</label>
          <Form.Item>
            <TimePicker
              prefix={<UserOutlined />}
              placeholder="Hora desde"
              format="HH:mm"
              value={inputs.deliveryScheduleStart}
              onChange={(e) =>
                setInputs({ ...inputs, deliveryScheduleStart: e })
              }
              minuteStep={10}
              style={{ width: "100%" }}
              showNow={false}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Hora hasta</label>
          <Form.Item>
            <TimePicker
              prefix={<UserOutlined />}
              placeholder="Hora hasta"
              value={inputs.deliveryScheduleEnd}
              format="HH:mm"
              minuteStep={10}
              onChange={(e) => setInputs({ ...inputs, deliveryScheduleEnd: e })}
              style={{ width: "100%" }}
              showNow={false}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left" style={{ margin: 5 }}></Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">Observaciones</label>
          <Form.Item>
            <TextArea
              placeholder="Observaciones"
              name="observation"
              value={inputs.observation}
              onChange={(e) =>
                setInputs({ ...inputs, observation: e.target.value })
              }
              size="middle"
              //onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addMerchandiseOrder}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}

//calcula el total de la transacción y lo pasa como componente al input del form
// function Total(props) {
//   const { itemsData, totalSum, setTotalSum } = props; //inputs, setInputs

//   let totals = itemsData.reduce((obj, data) => {
//     obj += data.unitValue * data.quantity;
//     return obj; //calculo del precio total del item
//   }, 0);

//   totalSum.total = totals;

//   /*
//   const columns = [
//     {
//       title: "",
//       key: "action",
//       render: (text, item) => (
//         <span>
//             Total: ${totals}
//         </span>
//       ),
//     },
//   ]*/

//   return (
//     <Input
//       hidden="true"
//       dataSource={itemsData}
//       totals={totals}
//       totalSum={totalSum}
//     />
//   );
// }

function Items(props) {
  const { itemsData, setItemsData } = props;
  //console.log(itemsData);

  const columns = [
    {
      title: "Código",
      dataIndex: "item_code",
      render: (_item, row) =>
        row.item_code ? row.item_code : row._item.item_code,
      width: "10%",
      editable: false,
    },
    {
      title: "Descripción",
      dataIndex: "description",
      render: (_item, row) =>
        row.description ? row.description : row._item.description,
      width: "50%",
      editable: false,
    },
    // {
    //   title: "Precio última compra",
    //   dataIndex: "unitValue",
    //   render: (_item, row) =>
    //     row.unitValue
    //       ? "$" + row.unitValue.value + " - " + formatDateView(row.unitValue.date)
    //       : null,
    //   width: "20%",
    //   editable: false,
    // },
    // {
    //   title: "Precio Unitario",
    //   dataIndex: "unitPrice",
    //   width: "15%",
    //   editable: true,
    // },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      width: "15%",
      editable: true,
    },
    // {
    //   title: "Total",
    //   // dataIndex:"quantity",
    //   render: (_item, row) =>
    //     row.unitPrice ? row.unitPrice * row.quantity : 0,
    //   width: "10%",
    //   editable: false,
    // },
  ];
  return (
    <EditableItemTable
      columnsData={columns}
      data={itemsData}
      setData={setItemsData}
      rowKey="_item"
    />
  );
}

function EditableItemTable(props) {
  const { data, setData, columnsData, rowKey } = props;
  const [form] = Form.useForm();
  // const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Por favor ingrese ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record) => record._item === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      unitValue: "",
      quantity: "",
      ...record,
    });
    setEditingKey(record._item);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item._item);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const showDeleteConfirm = (record) => {
    const d = data.filter((i) => i !== record);
    if (d.length === 0) {
      setData([]);
    } else {
      setData(d);
    }
  };

  const columns = [
    ...columnsData,
    {
      title: "Acción",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="primary"
              size="small"
              onClick={() => save(record._item)}
            >
              <SaveOutlined />
            </Button>
            <Divider type="vertical" />
            <Popconfirm title="Está seguro de cancelar?" onConfirm={cancel}>
              <Button type="danger" size="small">
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Button
              type="default"
              size="small"
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <EditOutlined />
            </Button>
            <Divider type="vertical" />
            <Button
              className="button-item"
              type="danger"
              size="small"
              onClick={(e) => showDeleteConfirm(record)}
            >
              <DeleteOutlined />
            </Button>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "quantity" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        size="small"
        style={{ margin: 15 }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        rowKey={rowKey}
        scroll={{ y: 150 }}
        // summary={(pageData) => {
        //   let total = 0;

        //   pageData.forEach(({ unitPrice, quantity }) => {
        //     total += unitPrice * quantity;
        //   });

        //   return (
        //     <>
        //       <Table.Summary.Row>
        //         <Table.Summary.Cell>Total</Table.Summary.Cell>
        //         <Table.Summary.Cell>
        //           <Text type="danger">{total}</Text>
        //         </Table.Summary.Cell>
        //       </Table.Summary.Row>
        //     </>
        //   );
        // }}
      />
    </Form>
  );
}
