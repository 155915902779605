import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  Divider,
  notification,
} from "antd";

import { updateStockInventoryApi } from "../../../../api/billing/stockInventory";
import { getAccessToken } from "../../../../api/auth";

import "./EditStockInventoryForm.scss";
import { getStateInventoriesActiveApi } from "../../../../api/billing/stateInventory";

export default function EditStockInventoryForm(props) {
  const {
    itemStockInventory,
    setIsVisibleModal,
    setReloadStockInventory,
  } = props;

  const [itemInventoryStockData, setItemInventoryStockData] = useState({});
  const [listStateInventories, setListStateInventories] = useState([]);

  //trae los datos del inventario
  useEffect(() => {
    setItemInventoryStockData({
      item: itemStockInventory.item,
      quantity_init: itemStockInventory.quantity_init,
      expirationDate: itemStockInventory.expirationDate,
      buyOrderPrice: itemStockInventory.buyOrderPrice,
      stock: itemStockInventory.stock,
      state:itemStockInventory.state,
      observation: itemStockInventory.observation,
    });
  }, [itemStockInventory]);

  useEffect(() => {
    const token=getAccessToken();
    getStateInventoriesActiveApi(token, true).then((response) => {
      if (response.stateInventories) {
        setListStateInventories(response.stateInventories);
      }
    });
  }, []);

  const isFormValid = (e) => {
    let errorExists = false;
    if (!itemInventoryStockData.item) {
      notification["error"]({
        message: "Obligatorios: Producto",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const editStockInventory = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();
      await updateStockInventoryApi(
        accessToken,
        itemStockInventory._id,
        itemInventoryStockData
      )
        .then((response) => {
          if (
            response.message === "ERR_CONNECTION_REFUSED" ||
            response.message === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              //message: response
              message: "Inventario actualizado",
            });
            setIsVisibleModal(false);
          }
          setReloadStockInventory(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="edit-stock-inventory-form">
      <EditForm
        itemInventoryStockData={itemInventoryStockData}
        setItemInventoryStockData={setItemInventoryStockData}
        editStockInventory={editStockInventory}
        listStateInventories={listStateInventories}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    itemInventoryStockData,
    setItemInventoryStockData,
    editStockInventory,
    listStateInventories
  } = props;

  const { Option } = Select;

  return (
    <Form className="form-edit" onSubmit={editStockInventory}>
      <Divider orientation="center">Producto</Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Codigo</label>
          <Form.Item>
            <Input
              className="select-form"
              placeholder="Codigo"
              value={
                itemInventoryStockData.item
                  ? itemInventoryStockData.item.item_code
                  : ""
              }
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Producto</label>
          <Form.Item>
            <Input
              placeholder="descripcion"
              value={
                itemInventoryStockData.item
                  ? itemInventoryStockData.item.description
                  : ""
              }
              disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={12}>
          <label className="control-label">Cantidad Inicial</label>
          <Form.Item>
            <InputNumber
              placeholder="Cantidad inicial de productos"
              name="quantity_init"
              value={itemInventoryStockData.quantity_init}
              onChange={(e) => setItemInventoryStockData({ ...itemInventoryStockData, quantity_init: e })}
              style={{ width: "90%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Fecha de Vencimiento</label>
          <Form.Item>
          <Input
              type="date"
              placeholder="Fecha"
              value={itemInventoryStockData.expirationDate}
              onChange={(e) => setItemInventoryStockData({ ...itemInventoryStockData, expirationDate: e.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      {/* <Divider orientation="center">Control Stock</Divider> */}
      {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Cantidad Inicial</label>
          <Form.Item>
            <InputNumber
              placeholder="Cantidad inicial de productos"
              name="quantity_init"
              value={itemInventoryStockData.quantity_init}
              disabled={true}
              // onChange={(e) =>
              //   setItemInventoryStockData({
              //     ...itemInventoryStockData,
              //     quantity_init: e,
              //   })
              // }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Stock actual</label>
          <Form.Item>
            <InputNumber
              placeholder="stock"
              name="stock"
              value={itemInventoryStockData.stock}
              disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Cantidad Compra</label>
          <Form.Item>
            <InputNumber
              name="quantity_purchased"
              value={itemInventoryStockData.quantity_purchased}
              disabled={true}
              size="middle"
              decimalSeparator=","
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Cantidad Venta</label>
          <Form.Item>
            <InputNumber
              placeholder="Precio 2"
              name="quantity_sold"
              value={itemInventoryStockData.quantity_sold}
              disabled={true}
              size="middle"
              decimalSeparator=","
            />
          </Form.Item>
        </Col>
      </Row> */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Estado</label>
          <Form.Item>
          <Select
              className="select-form"
              placeholder="Selecciona el estado"
              onChange={(e) =>
                setItemInventoryStockData({ ...itemInventoryStockData, state: e})
              }
              value={itemInventoryStockData.state}
            >
              {listStateInventories.map((state) => (
                <Option key={state._id}>{`${state.name}`}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

      </Row>

      <Divider></Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">Observaciones</label>
          <Form.Item>
            <Input
              placeholder="Observaciones"
              name="observation"
              value={itemInventoryStockData.observation}
              onChange={(e) =>
                setItemInventoryStockData({
                  ...itemInventoryStockData,
                  observation: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={editStockInventory}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
