import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import {
  UserOutlined,
  MailOutlined,
  CarOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { addPersonApi } from "../../../../api/person";
import { getAccessToken } from "../../../../api/auth";
import { getDocumentTypesActiveApi } from "../../../../api/billing/documentType";
import {
  emailValidation,
  minLengthValidation,
  numberValidation,
  documentNumberValidation,
} from "../../../../utils/formValidation";

import "./AddPersonForm.scss";

export default function AddPersonForm(props) {
  const { setIsVisibleModal, setReloadPersons } = props;
  const [listDocumentTypesActive, setListDocumentTypesActive] = useState([]);

  useEffect(() => {
    const accessToken = getAccessToken();
    getDocumentTypesActiveApi(accessToken, true).then((response) => {
      setListDocumentTypesActive(response.documentTypes);
    });
  }, []);

  const [inputs, setInputs] = useState({
    type: "physical",
    name: "",
    lastname: "",
    address: "",
    phone1: "",
    documentType: "",
    documentNumber: "",
    birthDate: "",
    email: "",
    observation: "",
  });

  const [formValid, setFormValid] = useState({
    email: false,
    //documentNumber: false,
    phone1: false,
  });

  const inputValidation = (e) => {
    const { type, name } = e.target;

    setInputs({
      ...inputs,
      [name]: e.target.value,
    });

    if (name === "email") {
      setFormValid({
        ...formValid,
        [name]: emailValidation(e.target),
      });
    }
    if (name === "documentNumber") {
      let result = false;
      let valor = e.target;
      if (numberValidation(valor) && minLengthValidation(valor, 7)) {
        result = true;
      }
      setFormValid({
        ...formValid,
        //[name]: numberValidation(e.target)
        [name]: result,
      });
    }
    if (name === "phone1" || name === "phone2") {
      setFormValid({
        ...formValid,
        [name]: numberValidation(e.target),
      });
    }
  };

  const isFormValid = (e) => {
    let errorExists = false;
    if (inputs.type === "physical") {
      if (
        !inputs.name ||
        !inputs.address ||
        !inputs.documentType ||
        !inputs.documentNumber ||
        !inputs.email
      ) {
        //inputs.perception_bsas ===null || inputs.perception_caba ===null
        notification["error"]({
          message:
            "Obligatorios: Nombre, Apellido, Domicilio, Tipo de documento, Nro de documento y correo",
        });
        errorExists = true;
      }
    }
    if (inputs.type === "legal") {
      if (
        !inputs.name ||
        !inputs.cuit_cuil ||
        !inputs.address ||
        !inputs.email
      ) {
        //inputs.perception_bsas ===null || inputs.perception_caba ===null
        notification["error"]({
          message: "Obligatorios: Nombre, CUIL/CUIT, Domicilio y correo",
        });
        errorExists = true;
      }
    }
    if (!formValid.email && inputs.email !== "") {
      notification["error"]({
        message: "El correo es inválido",
      });
      errorExists = true;
    }
    if (!formValid.phone1 && inputs.phone1 !== "") {
      notification["error"]({
        message: "Ingreso de teléfono inválido",
      });
      errorExists = true;
    }
    if (
      inputs.type !=="legal" &&
      (!inputs.documentNumber ||
      !documentNumberValidation(inputs.documentNumber))
    ) {
      notification["error"]({
        message: "El número de documento es obligatorio",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addPerson = async (e) => {
    e.preventDefault();
    console.log(inputs);
    if (!isFormValid()) {
      const accessToken = getAccessToken();

      await addPersonApi(accessToken, inputs)
        .then((response) => {
          if (
            response === "ERR_CONNECTION_REFUSED" ||
            response === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: "Persona creada",
            });
            setIsVisibleModal(false);
          }
          setReloadPersons(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-person-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        inputValidation={inputValidation}
        addPerson={addPerson}
        listDocumentTypesActive={listDocumentTypesActive}
      />
    </div>
  );
}

function AddForm(props) {
  const {
    inputs,
    setInputs,
    inputValidation,
    addPerson,
    listDocumentTypesActive,
  } = props;
  const { Option } = Select;

  const [firstField, setFirstField] = useState("");
  const [secondField, setSecondField] = useState("");
  const [thirdField, setThirdField] = useState("");
  //const [campoMedio, setCampoMedio] = useState('');

  // Refs para los campos
  const secondFieldRef = useRef(null);
  const thirdFieldRef = useRef(null);

  // Función para manejar los cambios en los campos
  const handleFirstFieldChange = (e) => {
    const value = e.target.value.slice(0, 2); // Limitar a 2 dígitos
    setFirstField(value);
    // Si el primer campo está lleno, mover el foco al segundo campo
    if (value.length === 2 && secondFieldRef.current) {
      secondFieldRef.current.focus();
    }
  };

  const handleSecondFieldChange = (e) => {
    const value = e.target.value.slice(0, 8); // Limitar a 8 dígitos
    setSecondField(value); // Limitar a 8 dígitos
    if (inputs.type === "pyshical") {
      setInputs({
        ...inputs,
        documentNumber: value !== "" ? parseInt(value) : null,
      });
    }
    // Si el segundo campo está lleno, mover el foco al tercer campo
    if (value.length === 8 && thirdFieldRef.current) {
      thirdFieldRef.current.focus();
    }
  };

  const handleThirdFieldChange = (e) => {
    setThirdField(e.target.value.slice(0, 2)); // Limitar a 2 dígitos
  };

  const handleDocumentNumberChange = (e) => {
    const value = e.target.value.slice(0, 8); // Limitar a 8 dígitos
    setSecondField(value); // Limitar a 8 dígitos
    setInputs({
      ...inputs,
      documentNumber: value !== "" ? parseInt(value) : null,
    });
  };

  useEffect(() => {
    setInputs({
      ...inputs,
      cuit_cuil: `${firstField}-${secondField}-${thirdField}`,
    });
  }, [firstField, secondField, thirdField]);

  return (
    <Form className="form-add" onSubmit={addPerson}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span> Tipo
          </label>
          <Form.Item>
            <Select
              placeholder="Tipo"
              name="type"
              defaultValue={"physical"}
              value={inputs.type}
              onChange={(e) => setInputs({ ...inputs, type: e })}
            >
              <Option value="physical">Física</Option>
              <Option value="legal">Jurídica</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span>{" "}
            {inputs.type === "physical" ? "Nombre" : "Nombre de fantasia"}
          </label>
          <Form.Item>
            <Input
              prefix={<UserOutlined />}
              placeholder="Nombre"
              name="name"
              value={inputs.name}
              onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
            />
          </Form.Item>
        </Col>
        {inputs.type === "physical" ? (
          <Col span={8}>
            <label className="control-label">
              <span className="control-required">*</span> Apellido
            </label>
            <Form.Item>
              <Input
                prefix={<UserOutlined />}
                placeholder="Apellido"
                value={inputs.lastname}
                onChange={(e) =>
                  setInputs({ ...inputs, lastname: e.target.value })
                }
              />
            </Form.Item>
          </Col>
        ) : null}
        {inputs.type === "legal" ? (
          <Col span={8}>
            <label className="control-label">
              <span className="control-required">*</span>CUIL/CUIT
            </label>
            <Form.Item>
              <Row gutter={24}>
                {/* Primer campo (2 dígitos) */}
                <Col span={7}>
                  <Input
                    maxLength={2} // Solo permite dos caracteres
                    value={firstField}
                    onChange={handleFirstFieldChange}
                  />
                </Col>
                {/* Segundo campo (8 dígitos) */}
                <Col span={11}>
                  <Input
                    maxLength={8} // Solo permite ocho caracteres
                    value={secondField}
                    ref={secondFieldRef}
                    onChange={handleSecondFieldChange}
                  />
                </Col>
                {/* Tercer campo (2 dígitos) */}
                <Col span={6}>
                  <Input
                    maxLength={1} // Solo permite dos caracteres
                    value={thirdField}
                    onChange={handleThirdFieldChange}
                    ref={thirdFieldRef}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        ) : null}
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {inputs.type === "physical" ? (
          <Col span={8}>
            <label className="control-label">CUIL/CUIT</label>
            <Form.Item>
              <Row gutter={24}>
                {/* Primer campo (2 dígitos) */}
                <Col span={7}>
                  <Input
                    maxLength={2} // Solo permite dos caracteres
                    value={firstField}
                    onChange={handleFirstFieldChange}
                  />
                </Col>
                {/* Segundo campo (8 dígitos) */}
                <Col span={11}>
                  <Input
                    maxLength={8} // Solo permite ocho caracteres
                    value={secondField}
                    ref={secondFieldRef}
                    onChange={handleSecondFieldChange}
                  />
                </Col>
                {/* Tercer campo (2 dígitos) */}
                <Col span={6}>
                  <Input
                    maxLength={1} // Solo permite dos caracteres
                    value={thirdField}
                    onChange={handleThirdFieldChange}
                    ref={thirdFieldRef}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        ) : null}

        {inputs.type === "physical" ? (
          <Col span={8}>
            <label className="control-label">
              <span className="control-required">*</span>Nº de documento
            </label>
            <Form.Item>
              <Input
                prefix={<UserOutlined />}
                //maxLength={8}
                placeholder="Nº de documento"
                name="documentNumber"
                value={inputs.documentNumber}
                maxLength={8}
                //onChange={(e) => setInputs({ ...inputs, cuit: e.target.value })}
                onChange={handleDocumentNumberChange}
              />
            </Form.Item>
          </Col>
        ) : null}
        {inputs.type === "physical" ? (
          <Col span={8}>
            <label className="control-label">
              <span className="control-required">*</span>Tipo de documento
            </label>
            <Form.Item>
              <Select
                placeholder="Tipo de documento"
                name="documentType"
                defaultValue={"physical"}
                value={inputs.documentType}
                onChange={(e) => setInputs({ ...inputs, documentType: e })}
              >
                {listDocumentTypesActive.map((type) => {
                  return <Option value={type._id}>{type.description}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        ) : null}
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span> Correo Electrónico
          </label>
          <Form.Item>
            <Input
              prefix={<MailOutlined />}
              placeholder="Correo Electrónico"
              name="email"
              type="email"
              value={inputs.email}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span>Domicilio
          </label>
          <Form.Item>
            <Input
              prefix={<EnvironmentOutlined />}
              placeholder="Domicilio"
              name="address"
              value={inputs.address}
              onChange={(e) =>
                setInputs({ ...inputs, address: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        {inputs.type === "physical" ? (
          <Col span={8}>
            <label className="control-label">Fecha Nacimiento</label>
            <Form.Item>
              <Input
                type="date"
                placeholder="fecha nacimiento"
                value={inputs.birthDate}
                onChange={(e) =>
                  setInputs({ ...inputs, birthDate: e.target.value })
                }
              />
            </Form.Item>
          </Col>
        ) : null}
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}>
          <label className="control-label">Teléfono 1</label>
          <Form.Item>
            <Input
              prefix={<PhoneOutlined />}
              placeholder="Teléfono"
              name="phone1"
              //onChange={(e) => setInputs({ ...inputs, phone: e.target.value })}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <label className="control-label">Teléfono 2</label>
          <Form.Item>
            <Input
              prefix={<PhoneOutlined />}
              placeholder="Teléfono 2"
              name="phone2"
              //onChange={(e) => setInputs({ ...inputs, phone: e.target.value })}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
        {inputs.type === "legal" ? (
          <Col span={8}>
            <label className="control-label">Razon Social</label>
            <Form.Item>
              <Input
                prefix={<UserOutlined />}
                placeholder="Razon Social"
                name="businessName"
                value={inputs.business_name}
                onChange={(e) =>
                  setInputs({ ...inputs, businessName: e.target.value })
                }
              />
            </Form.Item>
          </Col>
        ) : null}
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">Observaciones</label>
          <Form.Item>
            <TextArea
              placeholder="Observaciones"
              name="observation"
              value={inputs.observation}
              onChange={(e) =>
                setInputs({ ...inputs, observation: e.target.value })
              }
              size="middle"
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addPerson}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
