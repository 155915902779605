import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  notification,
  Table,
  Typography,
  InputNumber,
  Popconfirm,
  Divider,
} from "antd";
import {
  PlusOutlined,
  CloseOutlined,
  SaveOutlined,
  EditOutlined,
  DeleteOutlined,
  EnvironmentOutlined
} from "@ant-design/icons";

import jwtDecode from "jwt-decode";

import {
  getStockInventoriesApi,
  updateAddStockInventoryApi,
  updateSubStockInventoryApi,
} from "../../../../api/billing/stockInventory";
import { getAccessToken } from "../../../../api/auth";
import { addMovementApi } from "../../../../api/billing/movement";
import { getBuyBillsApi } from "../../../../api/billing/buyBill";
import { getBillSellsApi } from "../../../../api/billing/billsell";

import "./AddMovementForm.scss";
import { formatDateCalendar } from "../../../../utils/formatDate";

const { TextArea } = Input;
const { Text } = Typography;

export default function AddMovementForm(props) {
  const { setIsVisibleModal, setReloadMovements } = props;

  const [inventorySelected, setInventorySelected] = useState("");
  const [listBills, setListBills] = useState([]);

  const [inputs, setInputs] = useState({
    date: formatDateCalendar(new Date()),
    movement_type: "",
    items: [],
    item: "",
    origin: "",
    buyBill: "",
    billSell: "",
    quantity: "",
    observation: "",
    user: "",
  });

  const [formValid, setFormValid] = useState({
    date: false,
    movement_type: false,
    item: false,
    origin: false,
  });

  const [listStockInventory, setListStockInventory] = useState([]);

  useEffect(() => {
    getStockInventoriesApi(getAccessToken()).then((response) => {
      if (response.stockInventories) {
        setListStockInventory(response.stockInventories);
      } else {
        notification["error"]({
          message: "Primero debe agregar un inventario",
        });
      }
    });
  }, []);

  const isFormValid = (e) => {
    let errorExists = false;
    if (
      !inputs.date ||
      !inputs.movement_type ||
      !inputs.items ||
      !inputs.origin 
    ) {
      notification["error"]({
        message: "Obligatorios: fecha, tipo, productos, origen, cantidad",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addMovement = async (e) => {
    // try{
    e.preventDefault();
    const accessToken = getAccessToken();
    //Verifico los datos ingresados, en primer lugar actualizo stock y luego genero el movimiento
    if (!isFormValid()) {
           //Creo el movimiento
     const result=await addMovementApi(accessToken, inputs);

     if(result.code===200){
      //Movimiento creado
      inputs.items.map(async (item) => {
        let data = {
          quantity: item.quantity,
        };
        //Recorro cada el item del inventario stock seleccionado
        let inventorySelected = listStockInventory.filter(
          (i) => i._id === item.stockInventory
        )[0];
        //inputs.item = inventoryData.item._id;
        if (inputs.movement_type === "input") {
          await updateAddStockInventoryApi(accessToken, inventorySelected._id, data)
            .then((response) => {
              if (
                response.message === "ERR_CONNECTION_REFUSED" ||
                response.message === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
                return false;
              } else if (response.code !== 200) {
                notification["error"]({
                  message: response.message,
                });
                return false;
              } else {
                notification["success"]({
                  message: "Stock actualizado",
                });
                setReloadMovements(true);
                setIsVisibleModal(false);
                //inputs.user = jwtDecode(accessToken).id;
                //return addMovementApi(accessToken, inputs);
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        } else if (inputs.movement_type === "output") {
          await updateSubStockInventoryApi(accessToken, inventorySelected._id, data)
            .then((response) => {
              if (
                response.message === "ERR_CONNECTION_REFUSED" ||
                response.message === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else if (response.code !== 200) {
                notification["error"]({
                  message: response.message,
                });
              } else {
                notification["success"]({
                  message: "Stock actualizado",
                });
                setReloadMovements(true);
                setIsVisibleModal(false);
                //inputs.user = jwtDecode(accessToken).id;
                //return addMovementApi(accessToken, inputs);
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        }
      });
     }
  
    }
  };

  return (
    <div className="add-movement-form">
      <AddForm
        listStockInventory={listStockInventory}
        inputs={inputs}
        setInputs={setInputs}
        addMovement={addMovement}
        inventorySelected={inventorySelected}
        setInventorySelected={setInventorySelected}
        listBills={listBills}
        setListBills={setListBills}
      />
    </div>
  );
}

function AddForm(props) {
  const {
    listStockInventory,
    inputs,
    setInputs,
    inventorySelected,
    setInventorySelected,
    addMovement,
    listBills,
    setListBills,
  } = props;
  const { Option } = Select;

  // useEffect(() => {
  //   let accessToken = getAccessToken();
  //   if (inputs.origin === "billBuy") {
  //     getBillBuysApi(accessToken).then((response) => {
  //       setListBills(response.billBuys);
  //     });
  //   } else if (inputs.origin === "billSell") {
  //     getBillSellsApi(accessToken).then((response) => {
  //       setListBills(response.billSells);
  //     });
  //   }
  // }, [inputs.origin]);
  //Funcion que setea el valor del array de items
  const setListItems = (items) => {
    setInputs({ ...inputs, items: items });
  };

  //Funcion que agrega item al array de items
  const addItems = () => {
    if (inputs.item && inputs.quantity) {
      //Verifico si el item no esta en el detalle
      let itemExistInDetail = inputs.items.find((i) => i._item === inputs.item);
      if (!itemExistInDetail) {
        let inventory = listStockInventory.filter(
          (i) => i.item._id === inputs.item
        )[0];
        let description = inventory.item.description;
        let item_code = inventory.item.item_code;

        const newData = {
          _item: inventory.item._id,
          stockInventory: inventory._id,
          item_code: item_code,
          description: description,
          quantity: inputs.quantity,
        };
        // setItemsData([...itemsData, newData]);
        setInputs({ ...inputs, items: [...inputs.items, newData] });
      } else {
        notification["error"]({
          message: "El producto ya se encuentra en el detalle de la factura",
        });
      }
    } else {
      notification["error"]({
        message: "Debe elegir al menos un producto y la cantidad requerida",
      });
    }
  };

  return (
    <Form className="form-add" onSubmit={addMovement}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Tipo
          </label>
          <Form.Item>
            <Select
              className="select-form"
              placeholder="Selecciona tipo de movimiento"
              onChange={(e) => setInputs({ ...inputs, movement_type: e })}
              value={inputs.movement_type}
            >
              <Option value="input">Entrada</Option>
              <Option value="output">Salida</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span>Fecha
          </label>
          <Form.Item>
            <Input
              type="date"
              placeholder="Fecha"
              value={inputs.date}
              onChange={(e) => setInputs({ ...inputs, date: e.target.value })}
              required
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span>Origen
          </label>
          <Form.Item>
            <Select
              className="select-form"
              placeholder="Selecciona origen"
              onChange={(e) => setInputs({ ...inputs, origin: e })}
              value={inputs.origin}
            >
              {/* <Option value="inventory">Inventario</Option> */}
              <Option value="adjustment">Ajuste a Inventario</Option>
              <Option value="transfer">Traslado</Option>
              {/* {inputs.movement_type === "input" ? (
                <Option value="billbuy">Factura de Compra</Option>
              ) : null}
              {inputs.movement_type === "output" ? (
                <Option value="billsell">Factura de Venta</Option>
              ) : null}
              {inputs.movement_type === "output" ? (
                <Option value="canceledBuy">Cancelacion Compra</Option>
              ) : null}
              {inputs.movement_type === "input" ? (
                <Option value="canceledSell">Cancelacion Venta</Option>
              ) : null} */}
            </Select>
          </Form.Item>
        </Col>
        {/* {inputs.origin === "billBuy" ? (
          <Col span={12}>
            <label className="control-label">
              <span className="control-required">*</span> Codigo Factura{" "}
            </label>
            <Form.Item>
              <Select
                showSearch
                className="select-form"
                placeholder="Selecciona el Factura"
                onChange={(e) => setInputs({ ...inputs, billBuy: e })}
                value={inputs.billBuy}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {listBills.map((b) => (
                  <Option key={b._id}>{`${b.billbuy_code}`}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        ) : null}
        {inputs.origin === "billSell" ? (
          <Col span={12}>
            <label className="control-label">
              <span className="control-required">*</span> Codigo Factura{" "}
            </label>
            <Form.Item>
              <Select
                showSearch
                className="select-form"
                placeholder="Selecciona el Factura"
                onChange={(e) => setInputs({ ...inputs, billSell: e })}
                value={inputs.billSell}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {listBills.map((b) => (
                  <Option key={b._id}>{`${b.billsell_code}`}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        ) : null} */}
      </Row>
     {inputs.origin==="transfer"?
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Desde
          </label>
          <Form.Item>
            <Input
              placeholder="Desde"
              prefix={<EnvironmentOutlined/>}
              value={inputs.addressFrom}
              onChange={(e) => setInputs({ ...inputs, addressFrom: e.target.value })}
              required
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            Hasta
          </label>
          <Form.Item>
            <Input
              placeholder="Hasta"
              prefix={<EnvironmentOutlined/>}
              value={inputs.addressTo}
              onChange={(e) => setInputs({ ...inputs, addressTo: e.target.value })}
              required
            />
          </Form.Item>
        </Col>
      </Row> : null}

      {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span>Producto
          </label>
          <Form.Item>
            <Select
              showSearch
              className="select-form"
              placeholder="Selecciona el producto"
              onChange={(e) => setInventorySelected(e)}
              value={inventorySelected}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listStockInventory.map((d) => (
                <Option
                  key={d._id}
                >{`${d.item.item_code} - ${d.item.description}`}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Cantidad
          </label>
          <Form.Item>
            <Input
              name="quantity_purchased"
              placeholder="Cantidad"
              value={inputs.quantity}
              onChange={(e) =>
                setInputs({ ...inputs, quantity: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row> */}
      <Divider orientation="left" style={{ margin: 5 }}></Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span> Producto/Servicio
          </label>
          <Form.Item>
            <Select
              showSearch
              placeholder="Seleccione un producto/servicio"
              name="items"
              value={inputs.item}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  item: e,
                })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listStockInventory
                ? listStockInventory.map((i) => {
                    return (
                      <Option key={i.item._id}>{i.item.description}</Option>
                    );
                  })
                : "No hay productos disponibles"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
        <span className="control-required">*</span> <label className="control-label">Cantidad</label>
          <Form.Item>
            <InputNumber
              placeholder="Cantidad"
              value={inputs.quantity}
              onChange={(e) => setInputs({ ...inputs, quantity: e })}
              min={1}
              max={100}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={8} align="center">
          <Form.Item>
            <Button
              type="primary"
              className="button-add"
              size="middle"
              onClick={addItems}
            >
              <PlusOutlined /> Agregar
            </Button>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          {/* {inputs.items.length > 0 ? ( */}
          <Items itemsData={inputs.items} setItemsData={setListItems} />
          {/* ) : null} */}
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">Observaciones</label>
          <Form.Item>
            <TextArea
              placeholder="Observaciones"
              value={inputs.observation}
              onChange={(e) =>
                setInputs({ ...inputs, observation: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addMovement}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}

function Items(props) {
  const { itemsData, setItemsData } = props;
  //console.log(itemsData);

  const columns = [
    {
      title: "Código",
      dataIndex: "item_code",
      render: (_item, row) =>
        row.item_code ? row.item_code : row._item.item_code,
      width: "10%",
      editable: false,
    },
    {
      title: "Descripción",
      dataIndex: "description",
      render: (_item, row) =>
        row.description ? row.description : row._item.description,
      width: "40%",
      editable: false,
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      width: "25%",
      editable: true,
    },
  ];
  return (
    <EditableItemTable
      columnsData={columns}
      data={itemsData}
      setData={setItemsData}
      rowKey="_item"
    />
  );
}

function EditableItemTable(props) {
  const { data, setData, columnsData, rowKey } = props;
  const [form] = Form.useForm();
  // const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Por favor ingrese ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record) => record._item === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      //unitValue: "",
      quantity: "",
      ...record,
    });
    setEditingKey(record._item);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item._item);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const showDeleteConfirm = (record) => {
    const d = data.filter((i) => i !== record);
    if (d.length === 0) {
      setData([]);
    } else {
      setData(d);
    }
  };

  const columns = [
    ...columnsData,
    {
      title: "Acción",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="primary"
              size="small"
              onClick={() => save(record._item)}
            >
              <SaveOutlined />
            </Button>
            <Popconfirm title="Está seguro de cancelar?" onConfirm={cancel}>
              <Button type="danger" size="small">
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Button
              type="default"
              size="small"
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <EditOutlined />
            </Button> 
            <Divider type="vertical" />
            <Button
              className="button-item"
              type="danger"
              size="small"
              onClick={() => showDeleteConfirm(record)}
            >
              <DeleteOutlined />
            </Button>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "quantity" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        size="small"
        style={{ margin: 15 }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        rowKey={rowKey}
        scroll={{ y: 150 }}
        summary={(pageData) => {
          let total = 0;

          pageData.forEach(({ unitPrice, quantity }) => {
            total += unitPrice * quantity;
          });

          // return (
          //   <>
          //     <Table.Summary.Row>
          //       <Table.Summary.Cell>Total</Table.Summary.Cell>
          //       <Table.Summary.Cell>
          //         <Text type="danger">{total}</Text>
          //       </Table.Summary.Cell>
          //     </Table.Summary.Row>
          //   </>
          // );
        }}
      />
    </Form>
  );
}
