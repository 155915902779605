import React, { useState, useEffect, useCallback } from "react";
import {
  Form,
  InputNumber,
  Button,
  Row,
  Col,
  Input,
  notification,
  Avatar,
  Select,
  message,
  Upload,
  List,
  Divider,
  Popconfirm,
  Switch,
Space
  //notification
} from "antd";
import {
  TagOutlined,
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
  CloseOutlined,
  CheckOutlined,
  EditFilled,
  DeleteFilled,
  DragOutlined,
  PlusOutlined,
  SaveOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  //EnvironmentOutlined, PhoneOutlined,
} from "@ant-design/icons";
//import { getAccessToken } from "../../../../api/auth";
import "./EditItemForm.scss";
import {
  updateItemApi,
  uploadItemAvatarApi,
  getItemAvatarApi,
  getVariationCategoriesByBusinessOrBranchApi,
} from "../../../../api/item";
import { getAccessToken } from "../../../../api/auth";
import { getCategoriesApi } from "../../../../api/category";
import { getVariationCategoriesActiveApi } from "../../../../api/variationCategory";
import { useDropzone } from "react-dropzone";
import NoImage from "../../../../assets/img/png/no-image.png";
import Modal from "../../../Modal";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import EditVariationCategoryForm from "../../VariationCategories/EditVariationCategoryForm/EditVariationCategoryForm";
import { useBusiness } from "../../../../hooks/useBusiness";
import { updateItemBranchMenuByBranchApi } from "../../../../api/branchMenu";
import { useBranch } from "../../../../hooks/useBranch";
const { Option } = Select;

const { TextArea } = Input;

export default function EditGeneralItemForm(props) {
  const {
    item,
    reloadItems,
    setIsVisibleModal,
    setReloadItems,
    isEditingModal,
    handleDeleteItem,
    handleDesactivateItem,
    handleActivateItem,
  } = props;

  const [itemData, setItemData] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [categoriesActive, setCategoriesActive] = useState([]);
  const [variationCategoriesActive, setVariationCategoriesActive] = useState(
    []
  );
  const [reloadVariationCategories, setReloadVariationCategories] =
    useState(false);
  const [variationCategoriesData, setVariationCategoriesData] = useState([]);
  const {business}=useBusiness();
  // Estado para saber si el formulario ha cambiado
  const [isChanged, setIsChanged] = useState(false);
  const [variationsCategoryDeleted, setVariationsCategoryDeleted] = useState([]);

  //si tiene avatar lo trae, sino lo deja vacio
  useEffect(() => {
    if (item && item.image) {
      getItemAvatarApi(item.image).then((response) => {
        setImageUrl(response);
      });
      setImageData(item.image);
    } else {
      setImageUrl(null);
    }
  }, [item]);

  //si se carga un avatar lo prepara para enviar a la db
  useEffect(() => {
    setItemData({ ...itemData, image: imageData });
  }, [imageData]);

  // Detectar cambios en el formulario
  useEffect(() => {
    setIsChanged(JSON.stringify(itemData) !== JSON.stringify(item));
  }, [itemData]);

  useEffect(() => {
    if (item) {
      setItemData({
        ...item,
      });
    }
  }, [item]);

  useEffect(() => {
    if (item && item.variationCategories) {
      setVariationCategoriesData(item.variationCategories);
    }
  }, [item]);

  useEffect(() => {
    const accessToken = getAccessToken();
    getCategoriesApi(accessToken, true).then((response) => {
      setCategoriesActive(response.categories);
    });
  }, []);

  useEffect(() => {
    const accessToken = getAccessToken();
    getVariationCategoriesByBusinessOrBranchApi(accessToken, business).then((response) => {
      setVariationCategoriesActive(response.variationCategories);
    });
  }, []);


  useEffect(() => {
    if (reloadVariationCategories) {
      const accessToken = getAccessToken();
      getCategoriesApi(accessToken, true).then((response) => {
        setCategoriesActive(response.categories);
      });
      reloadVariationCategories(false);
    }
  }, [reloadVariationCategories]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!itemData.name) {
      notification["error"]({
        message: "Obligatorio: Nombre.",
      });
      errorExists = true;
    } else {
      /*
    else if (modifDataToValid.phone) {
      if (!validData.phone) {
        //solo si el email está vacio, falta la validación en el return de EditForm
        notification["error"]({
          message: "Ingreso de teléfono inválido"
        });
        errorExists = true;
      }
    }*/
      errorExists = false;
    }
    return errorExists;
  };

  const {branch}=useBranch();

  const updateItem = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let itemUpdate = { ...itemData }; //es let porque se va actualizando
      // if (imageUrl) {
      //   itemUpdate.image = imageUrl;
      // }
      if(item.price !== itemData.price){
        itemUpdate.previousprice=item.price;
      }
      if (variationCategoriesData) {
        const simplifiedData = variationCategoriesData.map((item) => ({
          ...item,
          name: item.name,
        }));
        itemUpdate.variationCategories = simplifiedData;
      }
      //console.log(variationsCategoryDeleted)
      itemUpdate.variationsCategoryDeleted=variationsCategoryDeleted;
      updateItemBranchMenuByBranchApi(token,branch, itemUpdate).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadItems(true);
          setIsChanged(false);
        }
      });
    }
  };

  const handleImageUpload = (url) => {
    setImageUrl(url);
  };

  return (
    <div className="edit-item-form">
      <EditForm
        itemData={itemData}
        setItemData={setItemData}
        updateItem={updateItem}
        imageUrl={imageUrl}
        setImage={setImageData}
        handleImageUpload={handleImageUpload}
        categoriesActive={categoriesActive}
        variationCategoriesActive={variationCategoriesActive}
        setReloadVariationCategories={setReloadVariationCategories}
        variationCategoriesData={variationCategoriesData}
        setVariationCategoriesData={setVariationCategoriesData}
        isEditingModal={isEditingModal}
        handleDeleteItem={handleDeleteItem}
        handleActivateItem={handleActivateItem}
        handleDesactivateItem={handleDesactivateItem}
        isChanged={isChanged}
        setIsChanged={setIsChanged}
        setVariationsCategoryDeleted={setVariationsCategoryDeleted}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    itemData,
    setItemData,
    updateItem,
    handleImageUpload,
    imageUrl,
    setImage,
    categoriesActive,
    setReloadVariationCategories,
    variationCategoriesData,
    setVariationCategoriesData,
    isEditingModal,
    handleDeleteItem,
    handleDesactivateItem,
    handleActivateItem,
    isChanged,
    setIsChanged,
    variationCategoriesActive,
    setVariationsCategoryDeleted
  } = props;

  return (
    <Form className="form-edit" onSubmit={updateItem} layout="vertical">
      {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}> */}
      <Row>
        <Col span={4}>
          <UploadAvatar
            onImageUpload={handleImageUpload}
            imageUrl={imageUrl}
            setImage={setImage}
          />
        </Col>
        <Col span={20}>
          <Row>
            <Col span={12}>
              <Form.Item label="Nombre">
                <Input
                  value={itemData.name}
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    setItemData({ ...itemData, name: e.target.value })
                  }
                  disabled={true}
                  className="ant-input-disabled"
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Estado">
                <Switch
                  checkedChildren="Disponible"
                  unCheckedChildren="No disponible"
                  style={{
                    // marginTop:35,
                    marginLeft: 10,
                  }}
                  checked={itemData.available}
                  onChange={(e) => setItemData({ ...itemData, available: e })}
                  //disabled={true}
                />
              </Form.Item>
            </Col>
            {!isEditingModal ? (
              <Col span={5}>
                <Form.Item>
                {isChanged? 
                  <> 
                  <Button
                  type={isChanged === true ? "primary" : null}
                  htmlType="submit"
                  className="button-edit"
                  style={{ border: 0 }}
                  onClick={updateItem}
                >
                  <SaveOutlined />
                </Button>
                  </>
                  : null}
                  <Button
                    htmlType="submit"
                    className="button-available"
                    style={{ border: 0 }}
                    onClick={() => {
                      itemData.active
                        ? handleDesactivateItem(itemData)
                        : handleActivateItem(itemData);
                    }}
                  >
                    {itemData.active ? (
                      <EyeOutlined />
                    ) : (
                      <EyeInvisibleOutlined />
                    )}
                  </Button>
                  
                  <Button
                    htmlType="submit"
                     className="button-delete"
                    style={{ border: 0 }}
                    onClick={() => {
                      handleDeleteItem(itemData);
                    }}
                    danger
                  >
                    <DeleteOutlined />
                  </Button>
                </Form.Item>
              </Col>
            ) : null}
            <Row>
              <Col>
                <Form.Item label="Descripcion">
                  <TextArea
                    showCount
                    disabled={true}
                    value={itemData.description}
                    maxLength={500}
                    style={{
                      width: 800,
                      height: 80,
                    }}
                    onChange={(e) =>
                      setItemData({ ...itemData, description: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col span={12}>
              <label>Categoria </label>
              <Select
                showSearch
                disabled={true}
                value={itemData.category}
                onChange={(e) => setItemData({ ...itemData, category: e })}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                style={{
                  width: "100%",
                }}
              >
                {categoriesActive
                  ? categoriesActive.map((category) => {
                      return (
                        <Option key={category._id} value={category._id}>
                          {" "}
                          {category.name}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </Col>
          </Row>
          {/* <Row>
              <Col>
                <Button type="default">Precio único</Button>
                <Button type="default">Precio múltiple</Button>
              </Col>
            </Row> */}
        </Col>
      </Row>
      <Divider orientation="left" orientationMargin="0">
        {" "}
        Establecer precio
      </Divider>
      <Row>
        <Col span={5} offset={4}>
          <label>Precio</label>
          <Input
            type="number"
            addonBefore="$"
            style={{ width: "100%" }}
            value={itemData.price}
            step="0.01"
            //keyboard={true}
            onChange={(e) =>
              setItemData({ ...itemData, price: e.target.value })
            }
            // onChange={onInputChange("price", index)}
          />
        </Col>
        <Col span={4} offset={1}>
          <label>Precio anterior </label>
          <Input
            type="number"
            addonBefore="$"
            style={{
              width: "100%",
              textDecoration: "line-through",
              pointerEvents: "none", // Asegúrate de que el texto tachado no interfiera con la interacción del input
            }}
            value={itemData.previousprice}
            step="0.01"
            //keyboard={true}
            onChange={(e) =>
              setItemData({ ...itemData, previousprice: e.target.value })
            }
            // onChange={onInputChange("price", index)}
          />
        </Col>
        <Col span={4} offset={1}>
          <label>Porcentaje de IVA </label>
          <Input
            addonAfter="%"
            type="number"
            style={{ width: "100%" }}
            value={itemData.iva}
            step="0.01"
            //keyboard={true}
            onChange={(e) => setItemData({ ...itemData, iva: e.target.value })}
          />
        </Col>
      </Row>
      <Divider orientation="left" orientationMargin="0">
        {" "}
        Agregar Adicional
      </Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}></Col>
        <Col span={16} offset={3}>
          {/* <ListVariations variationCategories={variationCategories}/> */}
        </Col>
      </Row>
      {/* </Row> */}
      <ListVariations
        variationCategories={variationCategoriesActive}
        items={variationCategoriesData}
        setItems={setVariationCategoriesData}
        setReloadVariationCategories={setReloadVariationCategories}
        setIsChanged={setIsChanged}
        setVariationsCategoryDeleted={setVariationsCategoryDeleted}
      />
      {isEditingModal ? (
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-submit"
            style={{ width: "100%" }}
            onClick={updateItem}
          >
            Actualizar
          </Button>
        </Form.Item>
      ) : null}
    </Form>
  );
}

function UploadAvatar(props) {
  const { onImageUpload, imageUrl, setImage } = props;
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [editing, setEditing] = useState(false);
  // const [fileName, setFileName] = useState('');

  useEffect(() => {
    if (imageUrl) {
      setPreviewImage(imageUrl);
    } else {
      setPreviewImage("");
    }
  }, [imageUrl]);

  // Maneja el cambio en el estado del archivo
  const handleChange = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} el archivo ha subido correctamente`);
      setFileList([info.file]); // Solo mostrar una imagen
      setPreviewImage(URL.createObjectURL(info.file.originFileObj));
      setEditing(false); // Termina el estado de edición
      onImageUpload(URL.createObjectURL(info.file.originFileObj));
      setImage(info.file.name); // Guardar el nombre del archivo
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} la carga del archivo falló.`);
    }
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append("file", file);
    const accessToken = getAccessToken();
    try {
      const response = await uploadItemAvatarApi(accessToken, formData);
      if (response.status === 200) {
        onSuccess();
      } else {
        onError(new Error("La carga falló."));
      }
    } catch (error) {
      onError(error);
    }
  };

  const handleRemove = () => {
    setFileList([]);
    setPreviewImage(null);
    onImageUpload("");
    setImage(null);
    message.success("Imagen eliminada correctamente");
  };

  // Maneja la edición de la imagen
  const handleEdit = () => {
    setEditing(true);
  };
  return (
    <div>
      <Upload
        customRequest={customRequest}
        onChange={handleChange}
        showUploadList={false} // No mostrar la lista de archivos
        accept="image/*"
        multiple={false} // Permitir solo un archivo
        disabled={editing} // Deshabilitar la carga durante la edición
      >
        <div>
          {previewImage && previewImage !== null ? (
            <>
              <Avatar
                size={200}
                src={previewImage}
                shape="square"
                style={{ display: "block", marginBottom: 8 }}
              />
              {/* <Text>{fileName}</Text> */}
              <div style={{ marginTop: 8 }}>
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={handleEdit}
                  style={{ marginRight: 8 }}
                ></Button>
                <Button
                  type="link"
                  icon={<DeleteOutlined />}
                  onClick={handleRemove}
                  // style={{ position: 'absolute', top: 0, right: 0 }}
                />
              </div>
            </>
          ) : (
            <Button icon={<UploadOutlined />} onClick={() => setEditing(true)}>
              Upload Image
            </Button>
          )}
        </div>
      </Upload>
    </div>
  );
}

// Estilo adicional para la lista y los elementos
const listItemStyle = { marginLeft: "30px" };
const dragHandleStyle = { cursor: "grab", marginLeft: 8 };
const SortableItem = SortableElement(
  ({ item, index, order, onEdit, onDelete }) => (
    <List.Item
      actions={[
        <>
          <Button shape="circle" onClick={() => onEdit(order, true)}>
            <EditFilled />
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title="¿Está seguro de eliminar este adicional?"
            onConfirm={() => onDelete(order)}
            okText="Si"
            cancelText="No"
          >
            <Button shape="circle">
              <DeleteFilled color="red" />
            </Button>
          </Popconfirm>
        </>,
      ]}
    >
      <DragOutlined style={dragHandleStyle} />
      {/* <div */}
      <Divider type="vertical" />
      <p
        style={{
          "white-space": "nowrap",
          overflow: "hidden" /* Oculta el contenido que excede el contenedor */,
          "text-overflow": "ellipsis",
        }}
      >
        {item.variation.name}
      </p>
    </List.Item>
  )
);

const SortableItem2 = SortableElement(
  ({ item, index, order, onEdit, onDelete }) => (
    <List.Item
      actions={[
        <>
          <Button shape="circle" onClick={() => onEdit(order, item)}>
            <EditFilled />
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title="¿Está seguro de eliminar este adicional?"
            onConfirm={() => onDelete(order)}
            okText="Si"
            cancelText="No"
          >
            <Button shape="circle">
              <DeleteFilled color="red" />
            </Button>
          </Popconfirm>
        </>,
      ]}
    >
      <DragOutlined style={dragHandleStyle} />
      {/* <div */}
      <Divider type="vertical" />
      <List.Item.Meta
        title={
          <div style={{ color: "gray", fontSize: "12px" }}>
            {"Seleccion " + (order + 1)}
          </div>
        }
        description={<div style={{ color: "black" }}>{item.name}</div>}
      />
    </List.Item>
  )
);

const SortableList = SortableContainer(({ items, onEdit, onDelete }) => (
  <List
    size="small"
    dataSource={items}
    renderItem={(item, index) => (
      <SortableItem2
        index={index}
        order={index}
        item={item}
        onDelete={onDelete}
        onEdit={onEdit}
      />
    )}
  />
));

function ListVariations(props) {
  const { variationCategories, setReloadVariationCategories, items, setItems, setIsChanged, setVariationsCategoryDeleted } =
    props;
  // const [items, setItems] = useState([]);
  const [count, setCount] = useState(items.length);
  const [showSelect, setShowSelect] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [editValue, setEditValue] = useState("");
  // const [reloadItems, setReloadItems] = useState(false);
  useEffect(() => {
    //actualizo las categorias por si modificaron valor en la actualizacion
    // Actualizar la mainList con los valores de updateList
    const updatedMainList = items.map((item) => {
      // Encontrar el elemento correspondiente en updateList
      const updateItem = variationCategories.find(
        (updateItem) => updateItem.name === item.name
      );

      // Si se encuentra un elemento en updateList, combinar los valores; de lo contrario, mantener el original
      if (updateItem) {
        let newData = {
          variation: updateItem,
          order: item.order,
        };
        return newData;
      } else {
        return item;
      }
    });
    setItems(updatedMainList);
  }, [variationCategories]);

  const editData = (index, newData) => {
    items[index] = newData;
    setItems(items);
    setIsChanged(true);
  };

  const handleEdit = (index, variationCategory) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${
        variationCategory.name
          ? variationCategory.name
          : "sin nombre"
      }`
    );
    setModalContent(
      <EditVariationCategoryForm
        id={index}
        variationCategory={variationCategory}
        setIsVisibleModal={setIsVisibleModal}
        setData={editData}
        setReloadVariationCategories={setReloadVariationCategories}
      />
    );
  };
  // Función para eliminar un elemento
  const handleDelete = (index) => {
    let itemDeleted=items.filter((_, i) => i === index);
    setVariationsCategoryDeleted(itemDeleted);
    let newArry = items.filter((_, i) => i !== index);
    setItems(newArry);
    setIsChanged(true);
  };

  const handleClick = () => {
    setShowSelect(!showSelect); // Alterna la visibilidad del Select
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = Array.from(items);
    const [movedItem] = newItems.splice(oldIndex, 1);
    newItems.splice(newIndex, 0, movedItem);
      // Actualizar el orden de los ítems basándonos en su nueva posición en el array
  newItems.forEach((item, index) => {
    // Actualizamos el campo `order` para reflejar la nueva posición
    item.order = index + 1; // Si el `order` empieza desde 1, o ajusta si es diferente
  });
    setItems(newItems);
    setIsChanged(true);
  };

  const handleAdd = (data) => {
    data.order = items.length;
    setItems([...items, data]);
    setCount(count + 1);
    setIsChanged(true);
  };

  return (
    <div style={{ width: "60%", marginLeft: "120px" }}>
      {items.length > 0 ? (
        <SortableList
          items={items}
          onSortEnd={onSortEnd}
          axis="y"
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      ) : null}
      {showSelect ? (
        <AddVariationCategory
          variationCategories={variationCategories}
          addVariationCategory={handleAdd}
          handleClick={handleClick}
          setShowSelect={setShowSelect}
        />
      ) : (
        <Button
          type="dashed"
          onClick={handleClick}
          style={{ marginBottom: 30, marginTop: 10, marginLeft: 30 }}
        >
          <PlusOutlined /> Agregar categoria
        </Button>
      )}
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={800}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function AddVariationCategory(props) {
  const {
    variationCategories,
    addVariationCategory,
    setShowSelect,
    handleClick,
  } = props;

  const [selectedOption, setSelectedOption] = useState("");
  const [name, setName] = useState('');
  const [items, setItems] = useState([...variationCategories]);

  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };


  const handleConfirmAdd = () => {
    if (selectedOption) {
      let categorySelected = items.find(
        (v) => v === selectedOption
      );
      const newData = {
        //key: Date.now(), // Un identificador único para cada fila
        name: categorySelected,
      };
      addVariationCategory(newData);
      //setDataSource([...dataSource, newData]);
      setSelectedOption(""); // Limpiar la selección después de añadir
      setShowSelect(false); // Ocultar el Select después de añadir
    }
  };
  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = () => {
    setItems([...items, name ]);
    setName('');
  };


  return (
    <Row style={{ marginTop: "30px", marginBottom: "30px" }}>
      <Col span={16} offset={2}>
        <Select
          //prefix={<DollarCircleOutlined />}
          showSearch
          value={selectedOption}
          onChange={handleSelectChange}
        //   optionFilterProp="children"
           style={{ width: "100%" }}
        //   filterOption={(input, option) =>
        //     option.children
        //       .toString()
        //       .toLowerCase()
        //       .indexOf(input.toLowerCase()) >= 0
        //   }
        // >
        //   {variationCategories
        //     ? variationCategories.map((variationCategory) => {
        //         return (
        //           <Option key={variationCategory}>
        //             {" "}
        //             {variationCategory}
        //           </Option>
        //         );
        //       })
        //     : null}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: '8px 0',
              }}
            />
            <Space
              style={{
                padding: '0 8px 4px',
              }}
            >
              <Input
                placeholder="Please enter item"
                value={name}
                onChange={onNameChange}
              />
              <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                Añadir Categoria
              </Button>
            </Space>
          </>
        )}
        options={items.map((item) => ({
          label: item,
          value: item,
        }))}
      />
        {/* </Select> */}
      </Col>
      <Col span={3}>
        <Button
          type="primary"
          onClick={handleConfirmAdd}
          style={{ marginLeft: 4 }}
        >
          <CheckOutlined />
        </Button>
      </Col>
      <Col span={3}>
        <Button
          type="primary"
          onClick={handleClick}
          style={{ marginBottom: 6 }}
          danger
        >
          <CloseOutlined />
        </Button>
      </Col>
    </Row>
  );
}
