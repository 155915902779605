import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  notification,
  Divider,
  Popconfirm,
  Table,
  Typography,
  TimePicker,
} from "antd";
import {
  UserOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseOutlined,
  TagOutlined,
} from "@ant-design/icons";
import {
  updateMerchandiseOrderApi,
  getMerchandiseOrderLastItemPriceApi,
} from "../../../../api/billing/merchandiseOrder";
import { getSuppliersActiveApi } from "../../../../api/billing/supplier";
import { getSalePointsByUserApi } from "../../../../api/billing/salePoint";
import { getUsersActiveApi } from "../../../../api/user";
import { getStateBillsActiveApi } from "../../../../api/billing/stateBill";
import {
  getDeliveryTermExportApi,
  getDeliveryTermsActiveApi,
} from "../../../../api/deliveryTerm";
import { getPaymentTypesActiveApi } from "../../../../api/billing/paymentType";
import { getItemsActiveApi } from "../../../../api/billing/item";
import { getAccessToken } from "../../../../api/auth";
import {
  convertToDate,
  formatDateCalendar,
  formatDateHourView,
  formatDateView,
} from "../../../../utils/formatDate";
import moment from "moment";
import jwtDecode from "jwt-decode";

import "./EditMerchandiseOrderForm.scss";
import TextArea from "antd/lib/input/TextArea";
import { getCoinTypesActiveApi } from "../../../../api/billing/coinType";

const { Text } = Typography;

export default function EditMerchandiseOrderForm(props) {
  const { merchandiseOrder, setIsVisibleModal, setReloadMerchandiseOrders } =
    props;
  const [merchandiseOrderData, setMerchandiseOrderData] = useState({
    items: [],
  });
  const [activeUsers, setActiveUsers] = useState([]);
  const [activeSuppliers, setActiveSuppliers] = useState([]);
  const [activeDeliveryTerms, setActiveDeliveryTerms] = useState([]);
  const [activeUserSalePoints, setActiveUserSalePoints] = useState([]);
  const [activePaymentTypes, setActivePaymentTypes] = useState([]);
  const [activeItems, setActiveItems] = useState([]);
  const [activeCoinTypes, setActiveCoinTypes] = useState([]);
  const [supplierDB, setSupplierDB] = useState([]);
  const [buyerUsername, setBuyerUsername] = useState([]);

  const accessToken = getAccessToken();
  const metaToken = jwtDecode(accessToken);

  const [updateItems, setUpdateItems] = useState(false);

  useEffect(() => {
    getSuppliersActiveApi(accessToken, true).then((response) => {
      setActiveSuppliers(response.suppliers);
    });
  }, []);

  useEffect(() => {
    getDeliveryTermsActiveApi(accessToken, true).then((response) => {
      setActiveDeliveryTerms(response.deliveryTerms);
    });
  }, []);

  useEffect(() => {
    getSalePointsByUserApi(accessToken, merchandiseOrder.buyer._id).then(
      (response) => {
        setActiveUserSalePoints(response.salePoints);
      }
    );
  }, [merchandiseOrder]);

  useEffect(() => {
    getPaymentTypesActiveApi(accessToken, true).then((response) => {
      setActivePaymentTypes(response.paymentTypes);
    });
  }, []);

  useEffect(() => {
    getItemsActiveApi(accessToken, true).then((response) => {
      setActiveItems(response.items);
    });
  }, []);

  useEffect(() => {
    getUsersActiveApi(accessToken, true).then((response) => {
      setActiveUsers(response.users);
    });
  }, []);

  useEffect(() => {
    getCoinTypesActiveApi(accessToken, true).then((response) => {
      setActiveCoinTypes(response.coinTypes);
    });
  }, []);

  //trae los datos del Producto
  useEffect(() => {
    setMerchandiseOrderData({
      code: merchandiseOrder.code,
      items: merchandiseOrder.items,
      total: merchandiseOrder.total,
      observation: merchandiseOrder.observation,
      date: formatDateCalendar(merchandiseOrder.date),
      paymentType: merchandiseOrder.paymentType,
      deliveryTerm: merchandiseOrder.deliveryTerm
        ? merchandiseOrder.deliveryTerm._id
        : null,
      supplier: merchandiseOrder.supplier._id,
      buyer: merchandiseOrder.buyer._id,
      //salePoint: merchandiseOrder.salePoint._id,
      branch: merchandiseOrder.branch,
      coinType: merchandiseOrder.coinType,
      quotation: merchandiseOrder.quotation,
      _item: merchandiseOrder._item,
      active: merchandiseOrder.active,
      quantity: 1,
      deliveryDate: formatDateCalendar(merchandiseOrder.deliveryDate),
      deliveryScheduleStart: merchandiseOrder.deliveryScheduleStart
        ? moment(new Date(merchandiseOrder.deliveryScheduleStart)).isValid()
          ? formatDateHourView(merchandiseOrder.deliveryScheduleStart)
          : formatDateHourView(
              convertToDate(merchandiseOrder.deliveryScheduleStart, "h:m")
            )
        : null,
      deliveryScheduleEnd: merchandiseOrder.deliveryScheduleEnd
        ? moment(new Date(merchandiseOrder.deliveryScheduleEnd)).isValid()
          ? formatDateHourView(merchandiseOrder.deliveryScheduleEnd)
          : formatDateHourView(
              convertToDate(merchandiseOrder.deliveryScheduleEnd, "h:m")
            )
        : null,
    });
    setBuyerUsername(merchandiseOrder.buyer.username);
  }, [merchandiseOrder]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (
      !merchandiseOrderData.code ||
      !merchandiseOrderData.date ||
      !merchandiseOrderData.supplier
    ) {
      notification["error"]({
        message: "Obligatorios: Código, Proveedor y Fecha .",
      });
      errorExists = true;
    }
    if (merchandiseOrderData.items.length === 0) {
      notification["error"]({
        message: "Obligatorios: debe seleccionar al menos un producto..",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const updateMerchandiseOrder = async (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      merchandiseOrderData.supplier = merchandiseOrderData.supplier._id;
      let merchandiseOrderUpdate = merchandiseOrderData;

      await updateMerchandiseOrderApi(
        token,
        merchandiseOrderUpdate,
        merchandiseOrder._id
      ).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setUpdateItems(true);
          setReloadMerchandiseOrders(true);
        }
      });
    }
  };

  return (
    <div className="edit-merchandiseorder-form">
      <EditForm
        merchandiseOrderData={merchandiseOrderData}
        setMerchandiseOrderData={setMerchandiseOrderData}
        updateMerchandiseOrder={updateMerchandiseOrder}
        buyerUsername={buyerUsername}
        activeUsers={activeUsers}
        activeSuppliers={activeSuppliers}
        activeDeliveryTerms={activeDeliveryTerms}
        activeUserSalePoints={activeUserSalePoints}
        activePaymentTypes={activePaymentTypes}
        activeItems={activeItems}
        activeCoinTypes={activeCoinTypes}
        supplierDB={supplierDB}
        setSupplierDB={setSupplierDB}
        accessToken={accessToken}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    merchandiseOrderData,
    setMerchandiseOrderData,
    updateMerchandiseOrder,
    buyerUsername,
    activeUserSalePoints,
    activePaymentTypes,
    activeSuppliers,
    activeItems,
    activeDeliveryTerms,
    accessToken,
    activeCoinTypes,
  } = props;
  const { Option } = Select;

  //Funcion que setea el valor del array de items
  const setListItems = (items) => {
    setMerchandiseOrderData({ ...merchandiseOrderData, items: items });
  };

  //Funcion que agrega un item al array de items
  const addItems = () => {
    if (merchandiseOrderData.item && merchandiseOrderData.quantity) {
      //Verifico si el item no esta en el detalle
      let itemExistInDetail = merchandiseOrderData.items.find(
        (i) =>
          i._item === merchandiseOrderData.item ||
          i._item._id === merchandiseOrderData.item
      );
      if (!itemExistInDetail) {
        let item = activeItems.filter(
          (i) => i._id === merchandiseOrderData.item
        )[0];
        let description = item.description;
        let item_code = item.item_code;

        const newData = {
          _item: merchandiseOrderData.item,
          item_code: item_code,
          description: description,
          unitValue: merchandiseOrderData.unitValue,
          unitPrice: merchandiseOrderData.unitPrice,
          quantity: merchandiseOrderData.quantity,
        };
        setMerchandiseOrderData({
          ...merchandiseOrderData,
          items: [...merchandiseOrderData.items, newData],
        });
      } else {
        notification["error"]({
          message: "El producto ya se encuentra en el detalle de la factura",
        });
      }
    } else {
      notification["error"]({
        message:
          "Debe elegir al menos un producto e ingresar precio y la cantidad requerida",
      });
    }
  };

  // useEffect(() => {
  //   if (merchandiseOrderData.item) {
  //     getMerchandiseOrderLastItemPriceApi(accessToken, merchandiseOrderData.item).then(
  //       (response) => {
  //         if (response.merchandiseOrder) {
  //           let itemSelected = response.merchandiseOrder.items.filter(
  //             (i) => i._item === merchandiseOrderData.item
  //           );
  //           if (itemSelected) {
  //             let date = response.merchandiseOrder.date;
  //             setMerchandiseOrderData({
  //               ...merchandiseOrderData,
  //               unitValue: {value:itemSelected[0].unitPrice , date: date},
  //               unitPrice: null,
  //             });
  //           }
  //         } else {
  //           setMerchandiseOrderData({
  //             ...merchandiseOrderData,
  //             unitValue: null,
  //             unitPrice: null,
  //           });
  //         }
  //       }
  //     );
  //   }
  // }, [merchandiseOrderData.item]);

  // useEffect(() => {
  //   let totals = 0;
  //   merchandiseOrderData.items.forEach(({ unitPrice, quantity }) => {
  //     totals += unitPrice * quantity;
  //   });

  //   setMerchandiseOrderData({ ...merchandiseOrderData, total: totals });
  // }, [merchandiseOrderData.items]);

  return (
    <Form
      className="form-edit"
      size={"middle"}
      onSubmit={updateMerchandiseOrder}
    >
      {/* <Divider orientation="left">Datos</Divider> */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Código
          </label>
          <Form.Item>
            <Input
              name="code"
              placeholder="Código"
              prefix={<TagOutlined />}
              value={
                merchandiseOrderData.code
                  ? merchandiseOrderData.code.toString().padStart(6, "0")
                  : null
              }
              onChange={(e) =>
                setMerchandiseOrderData({ ...merchandiseOrderData, code: e })
              }
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Comprador</label>
          <Form.Item>
            <Input
              placeholder="Comprador"
              name="buyer"
              value={buyerUsername}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <label className="control-label">Sucursal</label>
            <Input
              showSearch
              name="branch"
              value={
                merchandiseOrderData.branch
                  ? merchandiseOrderData.branch.name
                  : ""
              }
              //onChange={(e) => setMerchandiseOrderData({ ...merchandiseOrderData, branch: e.target.value })}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Proveedor
          </label>
          <Form.Item>
            <Select
              placeholder="Seleccione el proveedor"
              prefix={<UserOutlined />}
              value={
                merchandiseOrderData.supplier
                  ? merchandiseOrderData.supplier
                  : ""
              }
              //defaultValue={supplierDB.name}
              onChange={(e) =>
                setMerchandiseOrderData({
                  ...merchandiseOrderData,
                  supplier: e,
                })
              }
            >
              {activeSuppliers
                ? activeSuppliers.map((i) => {
                    return <Option key={i._id}>{i.denomination}</Option>;
                  })
                : "No hay proveedores activos"}
            </Select>
          </Form.Item>
        </Col>
        {/* <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Punto de venta
          </label>
          <Form.Item>
            <Select
              placeholder="Punto de venta"
              value={merchandiseOrderData.salePoint ? merchandiseOrderData.salePoint : ""}
              //defaultValue={supplierDB.name}
              onChange={(e) =>
                setMerchandiseOrderData({ ...merchandiseOrderData, salePoint: e })
              }
            >
              {activeUserSalePoints
                ? activeUserSalePoints.map((i) => {
                    return (
                      <Option key={i._id}>
                        {i.salepoint_code} {i.address} {i.number}{" "}
                        {i.postal_code} {i.locality}
                      </Option>
                    );
                  })
                : "No hay puntos de venta disponibles"}
            </Select>
          </Form.Item>
        </Col> */}
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Fecha de Carga
          </label>
          <Form.Item rules={[{ required: true }]}>
            <Input
              type="date"
              name="date"
              placeholder="Fecha"
              value={merchandiseOrderData.date}
              onChange={(e) =>
                setMerchandiseOrderData({
                  ...merchandiseOrderData,
                  date: e.target.value,
                })
              }
              required
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Tipo de pago</label>
          <Form.Item>
            <Select
              showSearch
              name="paymentType"
              value={merchandiseOrderData.paymentType}
              onChange={(e) =>
                setMerchandiseOrderData({
                  ...merchandiseOrderData,
                  paymentType: e,
                })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activePaymentTypes
                ? activePaymentTypes.map((i) => {
                    return <Option key={i._id}>{i.description}</Option>;
                  })
                : "No hay tipos de pago disponibles"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Moneda</label>
          <Form.Item rules={[{ required: true }]}>
            {/* <Input
              placeholder="Moneda"
              value={merchandiseOrderData.coinType}
              onChange={(e) =>
                setMerchandiseOrderData({ ...merchandiseOrderData, coinType: e.target.value })
              }
            /> */}
            <Select
              showSearch
              name="coinType"
              value={merchandiseOrderData.coinType}
              //defaultValue={"pesos"}
              onChange={(e) =>
                setMerchandiseOrderData({
                  ...merchandiseOrderData,
                  coinType: e,
                })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activeCoinTypes
                ? activeCoinTypes.map((i) => {
                    return <Option key={i._id}>{i.description}</Option>;
                  })
                : "No hay monedas disponibles"}
            </Select>
          </Form.Item>
        </Col>
        {merchandiseOrderData.coinType !== "pesos" &&
        merchandiseOrderData.coinType ? (
          <Col span={6}>
            <label className="control-label">Cotizacion</label>
            <Form.Item rules={[{ required: true }]}>
              <Input
                placeholder="Cotizacion"
                value={merchandiseOrderData.quotation}
                onChange={(e) =>
                  setMerchandiseOrderData({
                    ...merchandiseOrderData,
                    quotation: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
        ) : null}
      </Row>

      <Divider orientation="left" style={{ margin: 5 }}></Divider>

      <Row gutter={{ xs: 8, sm: 8, md: 24, lg: 32 }}>
        <Col span={5}>
          <label className="control-label">Producto/Servicio</label>
          <Form.Item>
            <Select
              // placeholder="Seleccione un producto/servicio"
              value={merchandiseOrderData.item}
              onChange={(e) =>
                setMerchandiseOrderData({
                  ...merchandiseOrderData,
                  item: e,
                })
              }
            >
              {activeItems
                ? activeItems.map((i) => {
                    return <Option key={i._id}>{i.description}</Option>;
                  })
                : "No hay productos disponibles"}
            </Select>
          </Form.Item>
        </Col>
        {/* <Col span={5}>
          <label className="control-label">Precio última compra</label>
          <Form.Item>
            <Input
              name="unitValue"
              value={
                merchandiseOrderData.unitValue ? "$" + merchandiseOrderData.unitValue.value + " - " + formatDateView(merchandiseOrderData.unitValue.date) : null
              }
              onChange={(e) =>
                setMerchandiseOrderData({
                  ...merchandiseOrderData,
                  unitValue: e.target.value,
                })
              }
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <label className="control-label">Precio Unitario</label>
          <Form.Item>
            <Input
              name="unitPrice"
              value={merchandiseOrderData.unitPrice}
              onChange={(e) =>
                setMerchandiseOrderData({
                  ...merchandiseOrderData,
                  unitPrice: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col> */}
        <Col span={5}>
          <label className="control-label">Cantidad</label>
          <Form.Item>
            <InputNumber
              placeholder="Cantidad"
              value={merchandiseOrderData.quantity}
              defaultValue={1}
              onChange={(e) =>
                setMerchandiseOrderData({
                  ...merchandiseOrderData,
                  quantity: e,
                })
              }
              min={1}
              max={100}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item>
            <Button
              type="primary"
              className="button-add"
              size="middle"
              onClick={addItems}
            >
              <PlusOutlined /> Agregar
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left" style={{ margin: 5 }}></Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          {/* {merchandiseOrderData.items.length > 0 ? ( */}
          <Items
            itemsData={merchandiseOrderData.items}
            setItemsData={setListItems}
            // showDeleteConfirm={showDeleteConfirm}
          />
          {/* ) : null} */}
        </Col>
      </Row>
      <Divider orientation="left" style={{ margin: 5 }}></Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {/* <Col span={6}>
          <label className="control-label">TOTAL</label>
          <Form.Item>
            <Input
              type="text"
              placeholder="Total"
              name="total"
              value={merchandiseOrderData.total}
              size="small"
              decimalSeparator=","
              disabled={true}
            />
          </Form.Item>
        </Col> */}
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Plazo de Entrega
          </label>
          <Form.Item>
            <Select
              showSearch
              // placeholder="Seleccione un plazo de entrega"
              name="deliveryTerm"
              value={merchandiseOrderData.deliveryTerm}
              onChange={(e) =>
                setMerchandiseOrderData({
                  ...merchandiseOrderData,
                  deliveryTerm: e,
                })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activeDeliveryTerms
                ? activeDeliveryTerms.map((i) => {
                    return <Option key={i._id}>{i.description}</Option>;
                  })
                : "No hay plazo de entrega disponibles"}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">Fecha de Entrega</label>
          <Form.Item>
            <Input
              type="date"
              placeholder="Fecha"
              value={merchandiseOrderData.deliveryDate}
              onChange={(e) =>
                setMerchandiseOrderData({
                  ...merchandiseOrderData,
                  deliveryDate: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Hora desde</label>
          <Form.Item>
            <TimePicker
              prefix={<UserOutlined />}
              placeholder="Hora desde"
              format="HH:mm"
              value={merchandiseOrderData.deliveryScheduleStart}
              onChange={(e) =>
                setMerchandiseOrderData({
                  ...merchandiseOrderData,
                  deliveryScheduleStart: e,
                })
              }
              minuteStep={10}
              style={{ width: "100%" }}
              showNow={false}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Hora hasta</label>
          <Form.Item>
            <TimePicker
              prefix={<UserOutlined />}
              placeholder="Hora hasta"
              value={merchandiseOrderData.deliveryScheduleEnd}
              format="HH:mm"
              minuteStep={10}
              onChange={(e) =>
                setMerchandiseOrderData({
                  ...merchandiseOrderData,
                  deliveryScheduleEnd: e,
                })
              }
              style={{ width: "100%" }}
              showNow={false}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left" style={{ margin: 5 }}></Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">Observaciones</label>
          <Form.Item>
            <TextArea
              placeholder="Observaciones"
              name="observation"
              value={merchandiseOrderData.observation}
              onChange={(e) =>
                setMerchandiseOrderData({
                  ...merchandiseOrderData,
                  observation: e.target.value,
                })
              }
              size="middle"
              //onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateMerchandiseOrder}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}

function Items(props) {
  const { itemsData, setItemsData } = props;

  const columns = [
    {
      title: "Código",
      dataIndex: "item_code",
      render: (_item, row) =>
        row.item_code ? row.item_code : row._item.item_code,
      width: "10%",
      editable: false,
    },
    {
      title: "Producto",
      dataIndex: "description",
      render: (_item, row) =>
        row.description ? row.description : row._item.description,
      width: "50%",
      editable: false,
    },
    // {
    //   title: "Precio última compra",
    //   dataIndex: "unitValue",
    //   render: (_item, row) =>
    //     row.unitValue ? "$" + row.unitValue.value + " - " + formatDateView(row.unitValue.date) : null,
    //   width: "20%",
    //   editable: false,
    // },
    // {
    //   title: "Precio Unitario",
    //   dataIndex: "unitPrice",
    //   width: "15%",
    //   editable: true,
    // },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      width: "15%",
      editable: true,
    },
    // {
    //   title: "Total",
    //   // dataIndex:"quantity",
    //   render: (_item, row) =>
    //     row.unitPrice ? row.unitPrice * row.quantity : 0,
    //   width: "10%",
    //   editable: false,
    // },
  ];
  return (
    <EditableItemTable
      columnsData={columns}
      data={itemsData}
      setData={setItemsData}
      rowKey="_item"
    />
  );
}

function EditableItemTable(props) {
  const { data, setData, columnsData, rowKey } = props;
  const [form] = Form.useForm();
  // const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Por favor ingrese ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record) => record._item === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      description: "",
      unitValue: "",
      quantity: "",
      ...record,
    });
    setEditingKey(record._item);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item._item);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      // console.log('Validate Failed:', errInfo);
    }
  };

  const showDeleteConfirm = (record) => {
    const d = data.filter((i) => i !== record);
    if (d.length === 0) {
      setData([]);
    } else {
      setData(d);
    }
  };

  const columns = [
    ...columnsData,
    {
      title: "Acción",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="primary"
              size="small"
              onClick={() => save(record._item)}
            >
              <SaveOutlined />
            </Button>
            <Divider type="vertical" />
            <Popconfirm title="Está seguro de cancelar?" onConfirm={cancel}>
              <Button type="danger" size="small">
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Button
              type="default"
              size="small"
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <EditOutlined />
            </Button>
            <Divider type="vertical" />
            <Button
              className="button-item"
              type="danger"
              size="small"
              onClick={(e) => showDeleteConfirm(record)}
            >
              <DeleteOutlined />
            </Button>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "quantity" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        style={{ margin: 15 }}
        size="small"
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        rowKey={rowKey}
        scroll={{ y: 150 }}
        // summary={(pageData) => {
        //   let total = 0;

        //   pageData.forEach(({ unitPrice, quantity }) => {
        //     total += unitPrice * quantity;
        //   });
        //   return (
        //     <>
        //       <Table.Summary.Row>
        //         <Table.Summary.Cell>Total</Table.Summary.Cell>
        //         <Table.Summary.Cell>
        //           <Text type="danger">{total}</Text>
        //         </Table.Summary.Cell>
        //       </Table.Summary.Row>
        //     </>
        //   );
        //}}
      />
    </Form>
  );
}
