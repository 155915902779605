import {downloadPdf, openPdf, getStyles, getHeader, getLogo} from "../../../../utils/pdfManager";

export function listItemsPdf(data,mainTitle,isDownload){
    const _format = (data)=>{
        return data.map(item=>{
            return ([
              {text: item.item_code},
              {text: item.description},
              {text: item.type},
              {text: item.sku},
              {text: item.category.description},
              {text: item.brand.name},
              {text: item.unit_1.name},
              {text: item.unit_2.name},
              {text: item.unit_3.name},
            ]);
        });
    }

    const formattedData=_format(data);

    const docDefinition={
        pageSize:'A4',
        pageOrientation: 'vertical',
        header:getHeader(),
        content:[
            //getLogo(),
        [
            { text:`${mainTitle}`, style:'title'},
        ],
      '\n',
      '\n',
            {
                table:{
                    headerRow:1,
                    widths:['auto','*','*','*','*','auto','auto','auto','auto'],
                    body:[
                      [
                        {text: 'Código', style:'tableHeader'},
                        {text: 'Descripción', style:'tableHeader'},
                        {text: 'Tipo', style:'tableHeader'},
                        {text: 'SKU', style:'tableHeader'},
                        {text: 'Categoria', style:'tableHeader'},
                        {text: 'Marca', style:'tableHeader'},
                        {text: 'Unidad 1', style:'tableHeader'},
                        {text: 'Unidad 2', style:'tableHeader'},
                        {text: 'Unidad 3', style:'tableHeader'},
                      ],
                        ...formattedData,
                    ],
                    style:"tableHeader"
                }
            }
        ],
        styles:getStyles()
    }

    if(isDownload){
        downloadPdf(docDefinition,`Listado${mainTitle}`);
    }else{
        openPdf(docDefinition);
    }

}

export function itemDetailPdf(data,mainTitle,isDownload){

    const _format = (data)=>{
        return ([
            {text:`Codigo: ${data.item_code? data.item_code : ""}`,style:'list'},
            {text:`Descripcion: ${data.description? data.description : ""}`,style:'list'},
            {text:`Catgeoria: ${data.category? (data.category.description) : ""}`,style:'list'},
            {text:`SKU: ${data.sku? data.sku : ""}`,style:'list'},
            {text:`Marca: ${data.brand? data.brand.name : ""}`,style:'list'},
            {text:`Unidad 1: ${data.unit_1? data.unit_1.name : ""}`,style:'list'},
            {text:`Unidad 2: ${data.unit_2? data.unit_2.name : ""}`,style:'list'},
            {text:`Unidad 3: ${data.unit_3? data.unit_3.name : ""}`,style:'list'},
            {text:`Observaciones: ${data.observation? data.observation : ""}`,style:'list'},
          
        ]);
    };

    const formattedData=_format(data);

    const docDefinition={
        pageSize:'A4',
        pageOrientation: 'vertical',
        header:getHeader(),
        content:[
            //getLogo(),
            '\n',
            [
                { text:`${mainTitle}`, style:'title'},
            ],
            '\n',
            '\n',
            {
                type:'none',
                ol:[
                    ...formattedData,
                ],
             } 
            ],
            styles:getStyles()
        }     

    let title=`${data.description}`;
    if(isDownload){
        downloadPdf(docDefinition,title);
    }else{
        openPdf(docDefinition);
    }

}