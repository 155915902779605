import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getBuyBillsActiveApi, getBuyBillsActiveByBranchApi, getBuyBillsPaidByBranchApi } from "../../../api/billing/buyBill";
//import { getSupplierApi } from "../../../../api/billing/supplier";
import ListBuyBills from "../../../components/Billing/BuyBills/ListBuyBills";

import "./BuyBills.scss";
import { useBranch } from "../../../hooks/useBranch";

export default function BuyBills() {
    const [buyBillsActive, setBuyBillsActive] = useState([]);
    const [buyBillsInactive, setBuyBillsInactive] = useState([]);
    const [buyBillsPaid, setBuyBillsPaid] = useState([]);
    const [buyBillsUnpaid, setBuyBillsUnpaid] = useState([]);
    const [reloadBuyBills, setReloadBuyBills ] = useState(false);

    const token = getAccessToken();
    const { branch } = useBranch();

    useEffect(() => {
        // if (branch && branch !== 0 && branch !== "0") {
        //     getBuyBillActiveByBranchApi(token, branch, false).then((response) => {
        //       setBuyOrdersInactive(response.buyOrders);
        //     });
        // }
        if (branch && branch !== 0 && branch !== "0") {
        getBuyBillsActiveByBranchApi(token, branch,true).then(response => {
            setBuyBillsActive(response.buyBills);
            console.log(response);
        });
        getBuyBillsActiveByBranchApi(token, branch,false).then(response => {
            setBuyBillsInactive(response.buyBills);
            //console.log(response);
        });
        }

        setReloadBuyBills(false);
    }, [token, reloadBuyBills,branch]);

    useEffect(() => {
        getBuyBillsPaidByBranchApi(token, branch,true).then(response => {
            setBuyBillsPaid(response.buyBills);
        });
        getBuyBillsPaidByBranchApi(token,branch,false).then(response => {
            setBuyBillsUnpaid(response.buyBills);
        });
        setReloadBuyBills(false);
    }, [token, reloadBuyBills, branch]);

    return (
        <div>
            <div className="buybills">
                <ListBuyBills buyBillsActive={buyBillsActive} buyBillsInactive={buyBillsInactive}
                                buyBillsPaid={buyBillsPaid} buyBillsUnpaid={buyBillsUnpaid}
                                setReloadBuyBills={setReloadBuyBills} token={token}  />
            </div>
        </div>

    );
}