import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditCoinTypeForm from "../EditCoinTypeForm";
import AddCoinTypeForm from "../AddCoinTypeForm";
import {
  activateCoinTypeApi,
  deleteCoinTypeApi,
} from "../../../../api/billing/coinType";

import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListCoinTypes.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListCoinTypes(props) {
  const {
    coinTypesActive,
    coinTypesInactive,
    setReloadCoinTypes,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewCoinTypesActives, setViewCoinTypesActives] = useState(
    true
  );
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [coinTypesActiveFilter, setCoinTypesActiveFilter] = useState(
    []
  );
  const [
    coinTypesInactiveFilter,
    setCoinTypesInactiveFilter,
  ] = useState([]);
  const [typeFilter, setTypeFilter] = useState("description");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listActive = [];
    if (coinTypesActive) {
      coinTypesActive.forEach((element) => {
        if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        } else {
          if (
            element.cointype_code
              .toString()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        }
      });
    }
    setCoinTypesActiveFilter(listActive);
  }, [filterText, coinTypesActive]);

  useEffect(() => {
    const listInactive = [];
    if (coinTypesInactive) {
      coinTypesInactive.forEach((element) => {
        if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        } else {
          if (
            element.cointype_code
              .toString()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        }
      });
    }
    setCoinTypesInactiveFilter(listInactive);
  }, [filterText, coinTypesInactive]);

  const addCoinTypeModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear  Tipo de moneda");
    setModalContent(
      <AddCoinTypeForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadCoinTypes={setReloadCoinTypes}
      />
    );
  };

  return (
    <div className="list-coinTypes">
      <div className="list-coinTypes__header">
        {checkAction(permissionsActive, userToken.role, "cointypes", "all") ||
        checkAction(permissionsActive, userToken.role, "cointypes", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-coinTypes__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewCoinTypesActives(!viewCoinTypesActives)
                }
              />
              <span>
                {viewCoinTypesActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
        ) : (
          <>
          </>
        )}

        {checkAction(permissionsActive, userToken.role, "cointypes", "all") ||
        checkAction(permissionsActive, userToken.role, "cointypes", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/> }onClick={addCoinTypeModal}>
              Nuevo 
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "cointypes", "all") ||
      checkAction(permissionsActive, userToken.role, "cointypes", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-coinTypes__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <Radio.Group
              options={[
                { label: "Descripción", value: "description" },
                { label: "Código", value: "code" },
              ]}
              onChange={(e) => {
                setTypeFilter(e.target.value);
              }}
              value={typeFilter}
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> ejemplos de tipos de monedas 'Pesos, Libra'
            </h3>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewCoinTypesActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "cointypes", "all") ||
          checkAction(permissionsActive, userToken.role, "cointypes", "view") || isAdmin(roleById) ? (
            <>
              <CoinTypesActive
                coinTypesActive={coinTypesActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadCoinTypes={setReloadCoinTypes}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "cointypes", "all") ||
          checkAction(permissionsActive, userToken.role, "cointypes", "view") || isAdmin(roleById) ? (
            <>
              <CoinTypesInactive
                coinTypesInactive={coinTypesInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadCoinTypes={setReloadCoinTypes}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function CoinTypesActive(props) {
  const {
    coinTypesActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadCoinTypes,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editCoinType = (docType) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${docType.description ? docType.description : "sin descripción"}`
    );
    setModalContent(
      <EditCoinTypeForm
        coinType={docType}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCoinTypes={setReloadCoinTypes}
      />
    );
  };

  const desactivateCoinType = (docType) => {
    const accessToken = getAccessToken();

    activateCoinTypeApi(accessToken, docType._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadCoinTypes(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (docType) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Tipo de Moneda",
      content: `¿Estás seguro que quieres eliminar a ${docType.description}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteCoinTypeApi(accessToken, docType._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadCoinTypes(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const edit_coinType = (
    <div>
      {" "}
      <p>Editar Tipo de Moneda</p>{" "}
    </div>
  );

  const desactivate_coinType = (
    <div>
      {" "}
      <p>Pasar a Tipos de Moneda inactivos</p>{" "}
    </div>
  );

  const delete_coinType = (
    <div>
      {" "}
      <p>Eliminar  Tipo de moneda</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "cointype_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, docType) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "cointypes", "all") ||
          checkAction(permissionsActive, userToken.role, "cointypes", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_coinType}>
                <Button
                  className="button-coinType"
                  type="default"
                  onClick={() => editCoinType(docType)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "cointypes", "all") ||
          checkAction(permissionsActive, userToken.role, "cointypes", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_coinType}>
                <Button
                  className="button-coinType"
                  type="primary"
                  onClick={() => desactivateCoinType(docType)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "cointypes", "all") ||
          checkAction(permissionsActive, userToken.role, "cointypes", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_coinType}>
                <Button
                  className="button-coinType"
                  type="danger"
                  onClick={() => showDeleteConfirm(docType)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={coinTypesActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function CoinTypesInactive(props) {
  const {
          coinTypesInactive,
          setReloadCoinTypes,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activateCoinType = (docType) => {
    const accessToken = getAccessToken();

    activateCoinTypeApi(accessToken, docType._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadCoinTypes(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (docType) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando  Tipo de moneda",
      content: `¿Estás seguro que quieres eliminar a ${docType.description}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteCoinTypeApi(accessToken, docType._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadCoinTypes(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const activate_coinType = (
    <div>
      {" "}
      <p>Pasar a Tipos de Moneda activos</p>{" "}
    </div>
  );

  const delete_coinType = (
    <div>
      {" "}
      <p>Eliminar  Tipo de moneda</p>
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "cointype_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, docType) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "cointypes", "all") ||
          checkAction(permissionsActive, userToken.role, "cointypes", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_coinType}>
                <Button
                  className="button-coinType"
                  type="primary"
                  onClick={() => activateCoinType(docType)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "cointypes", "all") ||
          checkAction(permissionsActive, userToken.role, "cointypes", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_coinType}>
                <Button
                  className="button-coinType"
                  type="danger"
                  onClick={() => showDeleteConfirm(docType)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={coinTypesInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
