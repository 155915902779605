import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getPersonsActiveApi } from "../../../api/billing/person";
import ListPersons from "../../../components/Billing/Persons/ListPersons";
import jwtDecode from "jwt-decode";

import "./Persons.scss";

export default function Persons() {
    const [personsActive, setPersonsActive] = useState([]);
    const [personsInactive, setPersonsInactive] = useState([]);
    const [ reloadPersons, setReloadPersons ] = useState(false);
    const token = getAccessToken();
    const userToken = jwtDecode(token);

    useEffect(() => {
        getPersonsActiveApi(token, true).then(response => {
            setPersonsActive(response.persons);
        });
        getPersonsActiveApi(token, false).then(response => {
            setPersonsInactive(response.persons);
        });
        setReloadPersons(false);
    }, [token, reloadPersons]);


    return (
        <div className="persons">
            <ListPersons personsActive={personsActive} personsInactive={personsInactive} setReloadPersons={setReloadPersons} />
        </div>
    );
}