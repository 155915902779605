import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getTypesActiveApi } from "../../../api/billing/type";
import ListTypes from "../../../components/Billing/Types/ListTypes";

export default function Types() {
    const [typesActive, setTypesActive] = useState([]);
    const [typesInactive, setTypesInactive] = useState([]);
    const [reloadTypes, setReloadTypes ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getTypesActiveApi(token, true).then(response => {
            setTypesActive(response.types);
        });
        getTypesActiveApi(token, false).then(response => {
            setTypesInactive(response.types);
        });
        setReloadTypes(false);
    }, [token, reloadTypes]);

    return (
        <div className="types">

            <ListTypes typesActive={typesActive} typesInactive={typesInactive} setReloadTypes={setReloadTypes} 
                />
        
        
        </div>
    );
}