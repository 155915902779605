import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditCategoryForm from "../EditCategoryForm";
import AddCategoryForm from "../AddCategoryForm";
import { activateCategoryApi, deleteCategoryApi } from "../../../../api/billing/category";
import { categoryExistInItemsApi } from "../../../../api/billing/item";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListCategories.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListCategories(props) {
  const { categoriesActive, categoriesInactive, setReloadCategories } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewCategoriesActives, setViewCategoriesActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [categoriesActiveFilter, setCategoriesActiveFilter] = useState([]);
  const [categoriesInactiveFilter, setCategoriesInactiveFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState("description");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listActive = [];
    if (categoriesActive) {
      categoriesActive.forEach((element) => {
        if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        } else {
            if (
              element.category_code
                .toString()
                .indexOf(filterText.toUpperCase()) !== -1 ||
              filterText === ""
            ) {
              listActive.push(element);
            }
        }
      });
    }
    setCategoriesActiveFilter(listActive);
  }, [filterText, categoriesActive]);

  useEffect(() => {
    const listInactive = [];
    if (categoriesInactive) {
      categoriesInactive.forEach((element) => {
        if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        } else {
          if (
            element.category_code
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        }
      });
    }
    setCategoriesInactiveFilter(listInactive);
  }, [filterText, categoriesInactive]);

  const addCategoryModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Categoria");
    setModalContent(
      <AddCategoryForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadCategories={setReloadCategories}
      />
    );
  };

  const verifyCategoryDeletion = async (category) => {
    const accessToken = getAccessToken();
    //verifico si existe en alguna factura
    let responseItems = await categoryExistInItemsApi(
      accessToken,
      category._id
    );

    if (responseItems.exist) {
      return false;
    }

    return true;
  };

  return (
    <div className="list-categories">
      <div className="list-categories__header">
        {checkAction(permissionsActive, userToken.role, "categories", "all") ||
        checkAction(permissionsActive, userToken.role, "categories", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-categories__header-switch">
              <Switch
                defaultChecked
                onChange={() => setViewCategoriesActives(!viewCategoriesActives)}
              />
              <span>
                {viewCategoriesActives ? "Activos" : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "categories", "all") ||
        checkAction(permissionsActive, userToken.role, "categories", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addCategoryModal}>
              Nuevo
            </Button>
          </>
          ) : (
            <>
            </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "categories", "all") ||
      checkAction(permissionsActive, userToken.role, "categories", "add") || isAdmin(roleById) ? (
        <>
          <div className="list-categories__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <Radio.Group
              options={[
                { label: "Descripción", value: "description" },
                { label: "Código", value: "code" },
              ]}
              onChange={(e) => {
                setTypeFilter(e.target.value);
              }}
              value={typeFilter}
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> ejemplos de categorías/rubros de productos 'alimentos, bebidas, limpieza, perfumería'
            </h3>
          </div>
        </>
        ) : (
          <>
          </>
      )}

      {viewCategoriesActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "categories", "all") ||
          checkAction(permissionsActive, userToken.role, "categories", "view") || isAdmin(roleById) ? (
            <>
              <CategoriesActive
                categoriesActive={categoriesActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadCategories={setReloadCategories}
                verifyCategoryDeletion={verifyCategoryDeletion}
                //showDeleteConfirm={showDeleteConfirm}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "categories", "all") ||
          checkAction(permissionsActive, userToken.role, "categories", "view") || isAdmin(roleById) ? (
            <>
              <CategoriesInactive
                categoriesInactive={categoriesInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadCategories={setReloadCategories}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                verifyCategoryDeletion={verifyCategoryDeletion}
                //showDeleteConfirm={showDeleteConfirm}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function CategoriesActive(props) {
  const {
    categoriesActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadCategories,
    verifyCategoryDeletion,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editCategory = (category) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${
        category.description ? category.description : "sin descripción"
      }`
    );
    setModalContent(
      <EditCategoryForm
        category={category}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCategories={setReloadCategories}
      />
    );
  };

  const desactivateCategory = (category) => {
    const accessToken = getAccessToken();

    activateCategoryApi(accessToken, category._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadCategories(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (category) => {
    const accessToken = getAccessToken();
    let result = await verifyCategoryDeletion(category);
    if (!result) {
      confirm({
        title: "Eliminando Categoria",
        content: `No se puede eliminar ${category.description} existen productos asociados, ¿Desea desactivarlo?`,
        okText: "Aceptar",
        okType: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivateCategory(category);
        },
      });
    } else {
      confirm({
        title: "Eliminando Categoria",
        content: `¿Estás seguro que quieres eliminar a ${category.description}?`,
        okText: "Eliminar",
        okType: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteCategoryApi(accessToken, category._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadCategories(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const edit_category = (
    <div>
      {" "}
      <p>Editar Categoria</p>{" "}
    </div>
  );

  const desactivate_category = (
    <div>
      {" "}
      <p>Pasar a Categorias inactivos</p>{" "}
    </div>
  );

  const delete_category = (
    <div>
      {" "}
      <p>Eliminar Categoria</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "category_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Accion",
      key: "action",
      render: (text, category) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "categories", "all") ||
          checkAction(permissionsActive, userToken.role, "categories", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_category}>
                <Button
                  className="button-category"
                  type="default"
                  onClick={() => editCategory(category)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "categories", "all") ||
          checkAction(permissionsActive, userToken.role, "categories", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_category}>
                <Button
                  className="button-category"
                  type="primary"
                  onClick={() => desactivateCategory(category)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "categories", "all") ||
          checkAction(permissionsActive, userToken.role, "categories", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_category}>
                <Button
                  className="button-category"
                  type="danger"
                  onClick={() => showDeleteConfirm(category)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={categoriesActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function CategoriesInactive(props) {
  const {
    categoriesInactive,
    setReloadCategories,
    verifyCategoryDeletion,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const activateCategory = (category) => {
    const accessToken = getAccessToken();

    activateCategoryApi(accessToken, category._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadCategories(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (category) => {
    const accessToken = getAccessToken();
    let result = await verifyCategoryDeletion(category);
    if (!result) {
      confirm({
        title: "Eliminando -> Categoria",
        content: `No se puede eliminar la Categoria ${category.description} existen productos asociados`,
        okText: "Aceptar",
        okType: "danger",
        okCancel: false,
      });
    } else {
      confirm({
        title: "Eliminando -> Categoria",
        content: `¿Estás seguro que quieres eliminar a ${category.description}?`,
        okText: "Eliminar",
        okType: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteCategoryApi(accessToken, category._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadCategories(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const activate_category = (
    <div>
      {" "}
      <p>Pasar a Categorias activos</p>{" "}
    </div>
  );

  const delete_category = (
    <div>
      {" "}
      <p>Eliminar Categoria</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "category_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, category) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "categories", "all") ||
          checkAction(permissionsActive, userToken.role, "categories", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_category}>
                <Button
                  className="button-category"
                  type="primary"
                  onClick={() => activateCategory(category)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "categories", "all") ||
          checkAction(permissionsActive, userToken.role, "categories", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_category}>
                <Button
                  className="button-category"
                  type="danger"
                  onClick={() => showDeleteConfirm(category)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={categoriesInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
