import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  notification,
} from "antd";

import { addStockInventoryApi } from "../../../../api/billing/stockInventory";
import { getAccessToken } from "../../../../api/auth";
import { useBranch } from "../../../../hooks/useBranch";

import "./AddStockInventoryForm.scss";
import { getStateInventoriesActiveApi } from "../../../../api/billing/stateInventory";

const { TextArea } = Input;

export default function AddStockInventoryForm(props) {
  const { listItems, setIsVisibleModal, setReloadStockInventory } = props;
  const { branch } = useBranch();
  const [listStateInventories, setListStateInventories] = useState([]);


  const [inputs, setInputs] = useState({
    item: "",
    quantity_init: 0,
    observation: "",
    branch:branch
  });

  const isFormValid = (e) => {
    let errorExists = false;
    if (!inputs.item) {
      notification["error"]({
        message: "Obligatorios: Seleccione un producto",
      });
      errorExists = true;
    }
    return errorExists;
  };

  useEffect(() => {
    const token=getAccessToken();
    getStateInventoriesActiveApi(token, true).then((response) => {
      if (response.stateInventories) {
        setListStateInventories(response.stateInventories);
      }
    });
  }, []);

  const addStockInventory = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();
      let idItem = inputs.item;
      await addStockInventoryApi(accessToken, idItem, inputs)
        .then((response) => {
          if (
            response.message === "ERR_CONNECTION_REFUSED" ||
            response.message === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: response.message
             // message: "Stock/inventario creado",
            });
            setIsVisibleModal(false);
          }
          setReloadStockInventory(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-stock-inventory-form">
      <AddForm
        listItems={listItems}
        inputs={inputs}
        setInputs={setInputs}
        addStockInventory={addStockInventory}
        listStateInventories={listStateInventories}
      />
    </div>
  );
}

function AddForm(props) {
  const { listItems, inputs, setInputs, addStockInventory, listStateInventories } = props;
  const { Option } = Select;

  return (
    <Form className="form-add" onSubmit={addStockInventory}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span>Producto
          </label>
          <Form.Item>
            <Select
              className="select-form"
              placeholder="Selecciona el producto"
              onChange={(e) =>
                setInputs({ ...inputs, item: e, observation: e.value })
              }
              value={inputs.item}
            >
              {listItems.map((d) => (
                <Option key={d._id}>{`${d.item_code}-${d.description}`}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Cantidad Inicial</label>
          <Form.Item>
            <InputNumber
              placeholder="Cantidad inicial de productos"
              name="quantity_init"
              value={inputs.quantity_init}
              onChange={(e) => setInputs({ ...inputs, quantity_init: e })}
              style={{ width: "90%" }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Fecha de Vencimiento</label>
          <Form.Item>
          <Input
              type="date"
              placeholder="Fecha"
              value={inputs.expirationDate}
              onChange={(e) => setInputs({ ...inputs, expirationDate: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Precio Orden de Compra</label>
          <Form.Item>
          <InputNumber
              name="buyOrderPrice"
              value={inputs.buyOrderPrice}
              onChange={(e) => setInputs({ ...inputs, buyOrderPrice: e })}
              style={{ width: "90%" }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Estado</label>
          <Form.Item>
          <Select
              className="select-form"
              placeholder="Selecciona el estado"
              onChange={(e) =>
                setInputs({ ...inputs, state: e})
              }
              value={inputs.state}
            >
              {listStateInventories.map((state) => (
                <Option key={state._id}>{`${state.name}`}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">Observaciones</label>
          <Form.Item>
            <TextArea
              placeholder="Observaciones"
              value={inputs.observation}
              onChange={(e) =>
                setInputs({ ...inputs, observation: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addStockInventory}
        >
          Crear Inventario
        </Button>
      </Form.Item>
    </Form>
  );
}
