import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Avatar,
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import {
  UserOutlined,
  MailOutlined,
  CarOutlined,
  ScheduleOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  DollarCircleOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import NoAvatar from "../../../../assets/img/png/no-avatar.png";
import { useDropzone } from "react-dropzone";
import {
  updatePersonApi,
  getPersonAvatarApi,
  uploadPersonAvatarApi,
} from "../../../../api/person";
import { getAccessToken } from "../../../../api/auth";
import { getDocumentTypesActiveApi } from "../../../../api/billing/documentType";
import {
  emailValidation,
  minLengthValidation,
  numberValidation,
} from "../../../../utils/formValidation";
import {
  formatDateCalendar
} from "../../../../utils/formatDate";

import "./EditPersonForm.scss";

export default function EditPersonForm(props) {
  const { person, setIsVisibleModal, setReloadPersons } = props;
  const [personData, setPersonData] = useState({});
  const [avatar, setAvatar] = useState(null);
  const [listDocumentTypesActive, setListDocumentTypesActive] = useState([]);

  //estado relacionado a si el usuario realizó modificación en los inputs que deben ser validados
  const [modifDataToValid, setModifDataToValid] = useState({
    email: false,
    documentNumber: false,
    phone: false,
  });
  //estado que almacena el resultado de validacion de los campos correspondientes
  const [validData, setValidData] = useState({
    email: false,
  });

  //trae los datos de la persona
  useEffect(() => {
    setPersonData({
      type: person.type,
      name: person.name,
      lastname: person.lastname,
      cuit_cuil: person.cuit_cuil,
      address: person.address,
      phone1: person.phone1,
      phone2: person.phone2,
      documentType: person.documentType,
      documentNumber: person.documentNumber,
      email: person.email,
      birthDate: formatDateCalendar(person.birthDate),
      businessName: person.businessName,
      observation: person.observation,
      avatar:person.avatar
    });
  }, [person]);

  useEffect(() => {
    const accessToken = getAccessToken();
    getDocumentTypesActiveApi(accessToken, true).then((response) => {
      setListDocumentTypesActive(response.documentTypes);
    });
  }, []);

  //si tiene avatar lo trae, sino lo deja vacio
  useEffect(() => {
    if (person.avatar) {
      getPersonAvatarApi(person.avatar).then((response) => {
        setAvatar(response);
      });
    } else {
      setAvatar(null);
    }
  }, [person]);

  //si se carga un avatar lo prepara para enviar a la db
  useEffect(() => {
    if (avatar) {
      setPersonData({ ...personData, avatar: avatar.file });
    }
  }, [avatar]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    //si el campo email fue modificado, verifica la validacion
    if (modifDataToValid.email) {
      if (!validData.email) {
        //solo si el email está vacio, falta la validación en el return de EditForm
        notification["error"]({
          message: "El email es inválido",
        });
        errorExists = true;
      }
    } else if(personData.type === "physical"){
      if (
        !personData.name ||
        !personData.address ||
        !personData.documentType ||
        !personData.documentNumber ||
        !personData.email
      ) {
        //inputs.perception_bsas ===null || inputs.perception_caba ===null
        notification["error"]({
          message:
            "Obligatorios: Nombre, Apellido, Domicilio, Tipo de documento, Nro de documento y correo",
        });
        errorExists = true;
      }
    } else if(personData.type === "legal"){
      if (
        !personData.name ||
        !personData.cuit_cuil ||
        !personData.address ||
        !personData.email
      ) {
        //inputs.perception_bsas ===null || inputs.perception_caba ===null
        notification["error"]({
          message:
            "Obligatorios: Nombre, CUIL/CUIT, Domicilio y correo",
        });
        errorExists = true;
      }
    } else if (modifDataToValid.phone) {
      if (!validData.phone1) {
        //solo si el email está vacio, falta la validación en el return de EditForm
        notification["error"]({
          message: "Ingreso de teléfono inválido",
        });
        errorExists = true;
      }
    } else {
      errorExists = false;
    }
    return errorExists;
  };

  const updatePerson = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let personUpdate = personData; //es let porque se va actualizando
      // console.log(personUpdate.avatar);

      if (typeof personUpdate.avatar === "object") {
        //actualiza el avatar de la persona
        uploadPersonAvatarApi(token, personUpdate.avatar, person._id).then(
          (response) => {
            if(response.code===200){
              personUpdate.avatar = response.avatarName;
              updatePersonApi(token, personUpdate, person._id).then((result) => {
                notification["success"]({
                  message: result.message, //el mensaje que viene del server
                });
                setIsVisibleModal(false);
                setReloadPersons(true);
              });
            }else{
              notification["error"]({
                message: response.message, //el mensaje que viene del server
              });
            }
          }
        );
      } else {
        updatePersonApi(token, personUpdate, person._id).then((result) => {
          if (
            result.message === "ERR_CONNECTION_REFUSED" ||
            result.message === "Failed to fetch" ||
            result.message === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
            setIsVisibleModal(true);
          } else if (result.code !== 200) {
            notification["error"]({
              message: result.message,
            });
            setIsVisibleModal(true);
          } else {
            notification["success"]({
              message: result.message, //el mensaje que viene del server
            });
            //console.log("respuesta: " + result.message);
            setIsVisibleModal(false);
            setReloadPersons(true);
          }
        });
      }
    }
  };

  return (
    <div className="edit-person-form">
      <UploadPersonAvatar avatar={avatar} setAvatar={setAvatar} />
      <EditForm
        personData={personData}
        setPersonData={setPersonData}
        updatePerson={updatePerson}
        setModifDataToValid={setModifDataToValid}
        validData={validData}
        modifDataToValid={modifDataToValid}
        setValidData={setValidData}
        listDocumentTypesActive={listDocumentTypesActive}
      />
    </div>
  );
}

function UploadPersonAvatar(props) {
  const { avatar, setAvatar } = props;
  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(() => {
    if (avatar) {
      if (avatar.preview) {
        setAvatarUrl(avatar.preview);
      } else {
        setAvatarUrl(avatar);
      }
    }
  }, [avatar]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      //console.log("file: " + file);
      if (file === undefined) {
        notification["error"]({
          message: "Formato de imagen no válido, aceptados: png, jpg, bpm.",
        });
        return;
      } else {
        //const file = acceptedFiles[0];
        console.log("URL.createObjectURL(file): " + URL.createObjectURL(file));
        setAvatar({ file, preview: URL.createObjectURL(file) });
      }
      //const file = acceptedFiles[0];
      //setAvatar({ file, preview: URL.createObjectURL(file) });
    },
    [setAvatar]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpeg, image/png, image/bmp",
    noKeyboard: true,
    onDrop,
  });

  return (
    <div className="upload-avatar" {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Avatar size={150} src={NoAvatar} />
      ) : (
        <Avatar size={150} src={avatarUrl ? avatarUrl : NoAvatar} />
      )}
    </div>
  );
}

function EditForm(props) {
  const {
    personData,
    setPersonData,
    setModifDataToValid,
    modifDataToValid,
    setValidData,
    validData,
    updatePerson,
    listDocumentTypesActive,
  } = props;
  const { Option } = Select;

  //validaciones de inputs
  const inputValidation = (e) => {
    const { type, name } = e.target;

    setPersonData({
      ...personData,
      [name]: e.target.value,
    });
    setModifDataToValid({
      ...modifDataToValid,
      [name]: true,
    });

    if (type === "email") {
      setValidData({
        ...validData,
        email: emailValidation(e.target),
      });
    }
    if (name === "documentNumber") {
      let result = false;
      if (numberValidation(e.target) && minLengthValidation(e.target, 7)) {
        result = true;
      }
      setValidData({
        ...validData,
        //[name]: numberValidation(e.target)
        [name]: result,
      });
    }
    if (name === "phone1") {
      setValidData({
        ...validData,
        [name]: numberValidation(e.target),
      });
    }
  };

  const [firstField, setFirstField] = useState("");
  const [secondField, setSecondField] = useState("");
  const [thirdField, setThirdField] = useState("");
  //const [campoMedio, setCampoMedio] = useState('');

  // Refs para los campos
  const secondFieldRef = useRef(null);
  const thirdFieldRef = useRef(null);

  // Función para manejar los cambios en los campos
  const handleFirstFieldChange = (e) => {
    const value = e.target.value.slice(0, 2); // Limitar a 2 dígitos
    setFirstField(value);
    // Si el primer campo está lleno, mover el foco al segundo campo
    if (value.length === 2 && secondFieldRef.current) {
      secondFieldRef.current.focus();
    }
  };

  const handleSecondFieldChange = (e) => {
    const value = e.target.value.slice(0, 8); // Limitar a 8 dígitos
    setSecondField(value); // Limitar a 8 dígitos
    if (personData.type === "pyshical") {
      setPersonData({
        ...personData,
        documentNumber: value !== "" ? parseInt(value) : null,
      });
    }
    // Si el segundo campo está lleno, mover el foco al tercer campo
    if (value.length === 8 && thirdFieldRef.current) {
      thirdFieldRef.current.focus();
    }
  };

  const handleThirdFieldChange = (e) => {
    setThirdField(e.target.value.slice(0, 2)); // Limitar a 2 dígitos
  };

  const handleDocumentNumberChange = (e) => {
    const value = e.target.value.slice(0, 8); // Limitar a 8 dígitos
    setSecondField(value); // Limitar a 8 dígitos
    setPersonData({
      ...personData,
      documentNumber: value !== "" ? parseInt(value) : null,
    });
  };

  useEffect(() => {
    setPersonData({
      ...personData,
      cuit_cuil: `${firstField}-${secondField}-${thirdField}`,
    });
  }, [firstField, secondField, thirdField]);

  useEffect(() => {
    if(personData.cuit_cuil){
      let fields = personData.cuit_cuil.split('-');
      setFirstField(fields[0]);
      setSecondField(fields[1]);
      setThirdField(fields[2]);
    }
  }, [personData]);


  return (
    <Form className="form-edit" onSubmit={updatePerson}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span> Tipo
          </label>
          <Form.Item>
            <Select
              placeholder="Tipo"
              value={personData.type}
              onChange={(e) =>
                setPersonData({ ...personData, type: e })
              }
            >
              <Option value="physical">Física</Option>
              <Option value="legal">Jurídica</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span> Nombre
          </label>
          <Form.Item>
            <Input
              prefix={<UserOutlined />}
              placeholder="Nombre"
              value={personData.name}
              onChange={(e) =>
                setPersonData({ ...personData, name: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        {personData.type==="physical" ?
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span> Apellido
          </label>
          <Form.Item>
            <Input
              prefix={<UserOutlined />}
              placeholder="Apellido"
              value={personData.lastname}
              onChange={(e) =>
                setPersonData({ ...personData, lastname: e.target.value })
              }
            />
          </Form.Item>
        </Col> : null}
        {personData.type==="legal" ?
        <Col span={8}>
          <label className="control-label">
          <span className="control-required">*</span>CUIL/CUIT</label>
          <Form.Item>
          <Row gutter={24}>
                {/* Primer campo (2 dígitos) */}
                <Col span={7}>
                  <Input
                    maxLength={2} // Solo permite dos caracteres
                    value={firstField}
                    onChange={handleFirstFieldChange}
                  />
                </Col>
                {/* Segundo campo (8 dígitos) */}
                <Col span={11}>
                  <Input
                    maxLength={8} // Solo permite ocho caracteres
                    value={secondField}
                    ref={secondFieldRef}
                    onChange={handleSecondFieldChange}
                  />
                </Col>
                {/* Tercer campo (2 dígitos) */}
                <Col span={6}>
                  <Input
                    maxLength={1} // Solo permite dos caracteres
                    value={thirdField}
                    onChange={handleThirdFieldChange}
                    ref={thirdFieldRef}
                  />
                </Col>
              </Row>
          </Form.Item>
        </Col> :  null }
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      {personData.type==="physical" ?
        <Col span={8}>
          <label className="control-label">CUIL/CUIT</label>
          <Form.Item>
          <Row gutter={24}>
                {/* Primer campo (2 dígitos) */}
                <Col span={7}>
                  <Input
                    maxLength={2} // Solo permite dos caracteres
                    value={firstField}
                    onChange={handleFirstFieldChange}
                  />
                </Col>
                {/* Segundo campo (8 dígitos) */}
                <Col span={11}>
                  <Input
                    maxLength={8} // Solo permite ocho caracteres
                    value={secondField}
                    ref={secondFieldRef}
                    onChange={handleSecondFieldChange}
                  />
                </Col>
                {/* Tercer campo (2 dígitos) */}
                <Col span={6}>
                  <Input
                    maxLength={1} // Solo permite dos caracteres
                    value={thirdField}
                    onChange={handleThirdFieldChange}
                    ref={thirdFieldRef}
                  />
                </Col>
              </Row>
          </Form.Item>
        </Col> :  null }
        {personData.type==="physical" ?
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span> Nº de Documento
          </label>
          <Form.Item>
            <Input
              prefix={<UserOutlined />}
              placeholder="Ingrese nº de documento"
              name="documentNumber"
              maxLength={11}
              value={personData.documentNumber}
              // onChange={(e) =>
              //   setClientData({ ...clientData, cuit: e.target.value })
              // }
              onChange={handleDocumentNumberChange}
            />
          </Form.Item>
        </Col> : null}
        {personData.type ==="physical" ?
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span>Tipo de documento
          </label>
          <Form.Item>
          <Select
              placeholder="Tipo de documento"
              name="documentType"
              defaultValue={"physical"}
              value={personData.documentType}
              onChange={(e) => setPersonData({ ...personData, documentType: e })}
            >
              {
                listDocumentTypesActive.map(type=>{
                  return <Option value={type._id}>{type.description}</Option>
                })
              }
            </Select>
          </Form.Item>
        </Col>
        : null}
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}>
          <label className="control-label">
          <span className="control-required">*</span>Correo Electrónico</label>
          <Form.Item>
            <Input
              prefix={<MailOutlined />}
              placeholder="Correo Electrónico"
              name="email"
              type="email"
              value={personData.email}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <label className="control-label">
          <span className="control-required">*</span>Domicilio</label>
          <Form.Item>
            <Input
              prefix={<EnvironmentOutlined />}
              placeholder="Domicilio"
              name="province"
              value={personData.address}
              onChange={(e) =>
                setPersonData({ ...personData, address: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        {personData.type==="physical" ?
        <Col span={8}>
          <label className="control-label">
            Fecha Nacimiento
          </label>
          <Form.Item>
            <Input
              type="date"
              placeholder="fecha nacimiento"
              value={personData.birthDate}
              onChange={(e) =>
                setPersonData({ ...personData, birthDate: e.target.valu })
              }
            />
          </Form.Item>
        </Col> : null}
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}>
          <label className="control-label">Teléfono 1</label>
          <Form.Item>
            <Input
              prefix={<PhoneOutlined />}
              label="Teléfono"
              placeholder="Teléfono"
              name="phone1"
              value={personData.phone1}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <label className="control-label">Teléfono 2 </label>
          <Form.Item>
            <Input
              prefix={<PhoneOutlined />}
              label="Teléfono"
              placeholder="Teléfono"
              name="phone2"
              value={personData.phone2}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
        {personData.type==="legal" ? 
        <Col span={8}>
          <label className="control-label">
             Razon Social
          </label>
          <Form.Item>
            <Input
              prefix={<UserOutlined />}
              placeholder="Razon Social"
              name="business_name"
              value={personData.businessName}
              onChange={(e) =>
                setPersonData({ ...personData, businessName: e.target.value })
              }
            />
          </Form.Item>
        </Col> : null }
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">Observaciones</label>
          <Form.Item>
            <TextArea
              placeholder="Observaciones"
              name="observation"
              value={personData.observation}
              onChange={(e) =>
                setPersonData({ ...personData, observation: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updatePerson}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
