import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import {
  getMerchandiseOrdersActiveApi,
  getMerchandiseOrdersPaidApi,
  getMerchandiseOrdersActiveByBranchApi,
} from "../../../api/billing/merchandiseOrder.js";
import ListMerchandiseOrders from "../../../components/Billing/MerchandiseOrders/ListMerchandiseOrders";

import "./MerchandiseOrders.scss";
import { useBranch } from "../../../hooks/useBranch";

export default function MerchandiseOrders() {
  const [merchandiseOrdersActive, setMerchandiseOrdersActive] = useState([]);
  const [merchandiseOrdersInactive, setMerchandiseOrdersInactive] = useState([]);
  const [reloadMerchandiseOrders, setReloadMerchandiseOrders] = useState(false);
  const token = getAccessToken();

  const { branch } = useBranch();

  // useEffect(() => {
  //     getMerchandiseOrdersActiveApi(token, true).then(response => {
  //         setMerchandiseOrdersActive(response.merchandiseOrders);
  //     });
  //     setReloadMerchandiseOrders(false);
  // }, [token, reloadMerchandiseOrders]);

  // useEffect(() => {
  //     getMerchandiseOrdersActiveApi(token, false).then(response => {
  //         setMerchandiseOrdersInactive(response.merchandiseOrders);
  //     });
  //     setReloadMerchandiseOrders(false);
  // }, [token, reloadMerchandiseOrders]);

  useEffect(() => {
    if (branch && branch !== 0 && branch !== "0") {
      getMerchandiseOrdersActiveByBranchApi(token, branch, true).then((response) => {
        setMerchandiseOrdersActive(response.merchandiseOrders);
        console.log(response)
      });
    }
    setReloadMerchandiseOrders(false);
  }, [token,branch, reloadMerchandiseOrders]);

  useEffect(() => {
    if (branch && branch !== 0 && branch !== "0") {
      getMerchandiseOrdersActiveByBranchApi(token, branch, false).then((response) => {
        setMerchandiseOrdersInactive(response.merchandiseOrders);
      });
    }
    setReloadMerchandiseOrders(false);
  }, [token, branch, reloadMerchandiseOrders]);

  return (
    <div className="merchandiseorders">
      <ListMerchandiseOrders
        merchandiseOrdersActive={merchandiseOrdersActive}
        MerchandiseOrdersInactive={merchandiseOrdersInactive}
        setReloadMerchandiseOrders={setReloadMerchandiseOrders}
        token={token}
      />
    </div>
  );
}
