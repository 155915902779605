import { basePath, apiVersion } from '../config';

// ORDEN DE PAGO

export function getPaymentOrderExportApi(token) {
    const url = `${basePath}/${apiVersion}/paymentorder-export-csv`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "text/csv",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'paymentOrder.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function addPaymentOrderApi(token, data){
    const url = `${basePath}/${apiVersion}/add-paymentorder`;
    const params = {
        method: "POST",
       // body: JSON.stringify(data),
        body: data,
        headers: {
            //"Content-Type": "multipart/form-data",
            Authorization: token
        },
    };

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function getPaymentOrdersApi(token) {
    const url = `${basePath}/${apiVersion}/paymentorders`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getPaymentOrdersActiveApi(token, status) {
    const url = `${basePath}/${apiVersion}/paymentorders-active?active=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function updatePaymentOrderApi(token, paymentOrderData, paymentOrderId) {
    const url = `${basePath}/${apiVersion}/update-paymentorder/${paymentOrderId}`;
    const params = {
        method: "PUT",
        headers: {
            //"Content-Type": "application/json",
            Authorization: token
        },
        //body: JSON.stringify(paymentOrderData)
        body:paymentOrderData
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function activatePaymentOrderApi(token, paymentOrderId, status) {
    const url = `${basePath}/${apiVersion}/activate-paymentorder/${paymentOrderId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            active: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function deletePaymentOrderApi(token, paymentOrderId) {
    const url = `${basePath}/${apiVersion}/delete-paymentorder/${paymentOrderId}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });
}

export function deleteReceiptPaymentOrderApi(token, filename) {
    const url = `${basePath}/${apiVersion}/delete-paymentorder-receipt/${filename}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });
}

export function getReceiptApi(fileName) {
    const url = `${basePath}/${apiVersion}/get-receipts-paymentorder/${fileName}`;
    return fetch(url)
      .then((response) => {
        //console.log(response)
        return response.url;
      })
      .catch((err) => {
        return err.message;
      });
  }