import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import { TagOutlined } from "@ant-design/icons";
import { addItemApi } from "../../../../api/billing/item";
import { getAccessToken } from "../../../../api/auth";
import { getCategoriesActiveApi } from "../../../../api/billing/category";
//import {  minLengthValidation, numberValidation } from "../../../../utils/formValidation";

import "./AddItemForm.scss";
import { getTypesActiveApi } from "../../../../api/billing/type";
import { getBrandsActiveApi } from "../../../../api/billing/brand";
import { getUnitsActiveApi } from "../../../../api/billing/unit";
import { useBranch } from "../../../../hooks/useBranch";

export default function AddItemForm(props) {
  const { setIsVisibleModal, setReloadItems } = props;
  const accessToken = getAccessToken();
  const [listCategoriesActive, setListCategoriesActive] = useState([]);
  const [listTypesActive, setListTypesActive] = useState([]);
  const [listBrandsActive, setListBrandsActive] = useState([]);
  const [listUnitsActive, setListUnitsActive] = useState([]);

  useEffect(() => {
    getCategoriesActiveApi(accessToken, true).then((response) => {
      setListCategoriesActive(response.categories);
    });
  }, []);

  useEffect(() => {
    getTypesActiveApi(accessToken, true).then((response) => {
      setListTypesActive(response.types);
    });
  }, []);

  useEffect(() => {
    getBrandsActiveApi(accessToken, true).then((response) => {
      setListBrandsActive(response.brands);
    });
  }, []);

  useEffect(() => {
    getUnitsActiveApi(accessToken, true).then((response) => {
      setListUnitsActive(response.units);
    });
  }, []);

  const {branch}=useBranch();

  const [inputs, setInputs] = useState({
    item_code: "",
    description: "",
    //registration: "",
    sku:null,
    category: "",
    branch:branch
  });

  //  const [formValid, setFormValid] = useState({
  //    item_code: false,
  //    description: false
  //  });

  // const inputValidation = (e) => {
  //   const { type, name } = e.target;

  //   setInputs({
  //     ...inputs,
  //     [name]: e.target.value,
  //   });

  //   setFormValid({
  //     ...formValid,
  //     [item_code_valid]:"sucess"
  //   });
  // };

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (
      !inputs.description ||
      !inputs.category ||
      !inputs.type 
    ) {
      notification["error"]({
        message:
          "Obligatorios: Código, Descripción, Tipo y Categoria.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addItem = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();

      await addItemApi(accessToken, inputs)
        .then((response) => {
          if (
            response === "ERR_CONNECTION_REFUSED" ||
            response === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: "Producto creado",
            });
            setIsVisibleModal(false);
          }
          setReloadItems(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-item-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        //inputValidation={inputValidation}
        addItem={addItem}
        listCategoriesActive={listCategoriesActive}
        listTypesActive={listTypesActive}
        listBrandsActive={listBrandsActive}
        listUnitsActive={listUnitsActive}
      />
    </div>
  );
}

function AddForm(props) {
  const { inputs, setInputs, addItem, listCategoriesActive, listTypesActive, listBrandsActive, listUnitsActive } = props; //inputValidation
  const { Option } = Select;

  return (
    <Form className="form-add" onSubmit={addItem}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <Input
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.item_code}
              onChange={(e) =>
                setInputs({ ...inputs, item_code: e.target.value })
              }
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Descripción
          </label>
          <Form.Item>
            <Input
              placeholder="Descripción"
              value={inputs.description}
              onChange={(e) =>
                setInputs({ ...inputs, description: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Tipo
          </label>
          <Form.Item>
            <Select
              placeholder="Selecciona Tipo"
              value={inputs.type}
              onChange={(e) => setInputs({ ...inputs, type: e })}
            >
              {listTypesActive
                ? listTypesActive.map((type) => {
                    return (
                      <Option value={type._id}>
                        {type.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">SKU</label>
          <Form.Item>
            <Input
              placeholder="SKU"
              name="sku"
              value={inputs.sku}
              onChange={(e) =>
                setInputs({ ...inputs, sku: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Categoria
          </label>
          <Form.Item>
            <Select
              placeholder="Selecciona Categoria"
              value={inputs.category}
              onChange={(e) => setInputs({ ...inputs, category: e })}
            >
              {listCategoriesActive
                ? listCategoriesActive.map((category) => {
                    return (
                      <Option value={category._id}>
                        {category.description}
                      </Option>
                    );
                  })
                : null}
              {/* <Option value="products">Productos</Option>
              <Option value="services">Servicios</Option>
              <Option value="supplies">Insumos</Option>
              <Option value="fixed-assets">Bienes de uso</Option>
              <Option value="various">Varios</Option>
              <Option value="others">Otros</Option> */}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Marca
          </label>
          <Form.Item>
            <Select
              placeholder="Selecciona Marca"
              value={inputs.brand}
              onChange={(e) => setInputs({ ...inputs, brand: e })}
            >
              {listBrandsActive
                ? listBrandsActive.map((brand) => {
                    return (
                      <Option value={brand._id}>
                        {brand.name}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
             Unidad 1
          </label>
          <Form.Item>
          <Select
              placeholder="Selecciona Unidad"
              value={inputs.unit_1}
              onChange={(e) => setInputs({ ...inputs, unit_1: e })}
            >
              {listUnitsActive
                ? listUnitsActive.map((unit) => {
                    return (
                      <Option value={unit._id}>
                        {unit.name}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
             Unidad 2
          </label>
          <Form.Item>
          <Select
              placeholder="Selecciona Unidad"
              value={inputs.unit_2}
              onChange={(e) => setInputs({ ...inputs, unit_2: e })}
            >
              {listUnitsActive
                ? listUnitsActive.map((unit) => {
                    return (
                      <Option value={unit._id}>
                        {unit.name}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            Unidad 3
          </label>
          <Form.Item>
          <Select
              placeholder="Selecciona Unidad"
              value={inputs.unit_3}
              onChange={(e) => setInputs({ ...inputs, unit_3: e })}
            >
              {listUnitsActive
                ? listUnitsActive.map((unit) => {
                    return (
                      <Option value={unit._id}>
                        {unit.name}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addItem}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
