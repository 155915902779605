import React, { useState, useEffect } from "react";
import { Form, Descriptions, Table } from "antd";
//import { getSupplierApi } from "../../../../api/billing/supplier";
import { formatDateView } from "../../../../utils/formatDate";
import { convertState, convertCategory } from "../../../../utils/convert";
import "./ViewBuyBillForm.scss";

export default function ViewBuyBillForm(props) {
  const { buyBill } = props;
  const [buyBillData, setBuyBillData] = useState({});
  
  //trae los datos de la factura
  useEffect(() => {
    setBuyBillData({
      code: buyBill.code,
      billType: buyBill.billType,
      total: buyBill.total,
      date: buyBill.date,
      inputDate: buyBill.inputDate,
      expirationDate: buyBill.expirationDate,
      periodFrom: buyBill.periodFrom,
      periodUntil: buyBill.periodUntil,
      supplier: buyBill.supplier,
      supplier_denomination: buyBill.supplier.denomination? buyBill.supplier.denomination : null,
      // salePoint_name: buyBill.salePoint.fantasy_name,
      // salePoint_code: buyBill.salePoint.salepoint_code,
      // salePoint_address: buyBill.salePoint.address,
      // salePoint_number: buyBill.salePoint.number,
      // salePoint_postalcode: buyBill.salePoint.postal_code,
      // salePoint_locality: buyBill.salePoint.locality,
      branch_name: buyBill.branch.fantasy_name,
      branch_code: buyBill.branch.salepoint_code,
      branch_address: buyBill.branch.address,
      branch_number: buyBill.branch.number,
      branch_postalcode: buyBill.branch.postal_code,
      branch_locality: buyBill.branch.locality,
      quantity: buyBill.quantity,
      items: buyBill.items,
      item: buyBill.item,
    });
  }, [buyBill]);

  const itemsBill = buyBill.items;

  return (
    <div className="view-buybill-form">
      <ViewForm
        buyBillData={buyBillData}
        setBuyBillData={setBuyBillData}
      />
      <label className="title">Detalle</label>
      <Items itemsBill={itemsBill} />
    </div>
  );
}

function ViewForm(props) {
  const { buyBillData, setBuyBillData } = props;

  return (
    <Form className="form-view">
      <Descriptions column={2} layout="vertical" size="small" bordered>
        <label className="title">Tipo Comprobante</label>
        <Descriptions.Item className="normal">
          {buyBillData.billType}
        </Descriptions.Item>
        <label className="title">Código</label>
        <Descriptions.Item className="normal">
          {buyBillData.code}
        </Descriptions.Item>
        <label className="title">Proveedor</label>
        <Descriptions.Item className="normal">
          {buyBillData.supplier_denomination? buyBillData.supplier_denomination : "Sin datos"}
        </Descriptions.Item>
        <label className="title">Punto de venta</label>
        <Descriptions.Item className="normal">
          {buyBillData.branch_code +
            " " +
            buyBillData.branch_address +
            " " +
            buyBillData.branch_number +
            " " +
            buyBillData.branch_locality}
        </Descriptions.Item>
        <label className="title">Fecha de Ingreso</label>
        <Descriptions.Item className="normal">
          {buyBillData.inputDate
          ? formatDateView(buyBillData.inputDate)
          : "-"}
        </Descriptions.Item>
        <label className="title">Fecha</label>
        <Descriptions.Item className="normal">
          {formatDateView(buyBillData.date)}
        </Descriptions.Item>
        <label className="title">Periodo desde</label>
        <Descriptions.Item className="normal">
          {buyBillData.periodFrom
            ? formatDateView(buyBillData.periodFrom)
            : "-"}
        </Descriptions.Item>
        <label className="title">Hasta</label>
        <Descriptions.Item className="normal">
          {buyBillData.periodUntil
            ? formatDateView(buyBillData.periodUntil)
            : "-"}
        </Descriptions.Item>
        <label className="title">Fecha Vencimiento</label>
        <Descriptions.Item className="normal">
          {buyBillData.expirationDate
            ? formatDateView(buyBillData.expirationDate)
            : "-"}
        </Descriptions.Item>
        <label className="title">Total</label>
        <Descriptions.Item className="normal">
          {"$ " + buyBillData.total}
        </Descriptions.Item>
        <label className="title">Observaciones</label>
        <Descriptions.Item className="normal" span={2}>
          {buyBillData.observation ? buyBillData.observation : "-"}
        </Descriptions.Item>
        <Descriptions.Item className="normal" span={2}></Descriptions.Item>
      </Descriptions>
    </Form>
  );
}

function Items(props) {
  const { itemsBill } = props;
  //console.log(itemsBill[0]._item.description);

  const columns = [
    {
      title: "Producto",
      dataIndex: "_item",
      render: (_item) => _item.description,
    },
    { title: "Valor Unitario ($)", dataIndex: "unitValue" },
    { title: "Cantidad", dataIndex: "quantity" },
  ];
  return (
    <Table
      pagination={false}
      columns={columns}
      dataSource={itemsBill}
      rowKey="_item"
    />
  );
}

function Bills(props) {
  const { billsData } = props;
  const columns = [{ title: "Código", dataIndex: "code" }];
  return (
    <Table
      pagination={false}
      columns={columns}
      dataSource={billsData}
      rowKey="_id"
    />
  );
}
