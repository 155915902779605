import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditTypeForm from "../EditTypeForm";
import AddTypeForm from "../AddTypeForm";
import { activateTypeApi, deleteTypeApi } from "../../../../api/billing/type";
//import { typeExistInItemsApi } from "../../../../api/billing/item";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListTypes.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListTypes(props) {
  const { typesActive, typesInactive, setReloadTypes } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewTypesActives, setViewTypesActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [typesActiveFilter, setTypesActiveFilter] = useState([]);
  const [typesInactiveFilter, setTypesInactiveFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState("description");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listActive = [];
    if (typesActive) {
      typesActive.forEach((element) => {
        if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        } else {
            if (
              element.type_code
                .toString()
                .indexOf(filterText.toUpperCase()) !== -1 ||
              filterText === ""
            ) {
              listActive.push(element);
            }
        }
      });
    }
    setTypesActiveFilter(listActive);
  }, [filterText, typesActive]);

  useEffect(() => {
    const listInactive = [];
    if (typesInactive) {
      typesInactive.forEach((element) => {
        if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        } else {
          if (
            element.type_code
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        }
      });
    }
    setTypesInactiveFilter(listInactive);
  }, [filterText, typesInactive]);

  const addTypeModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Tipo");
    setModalContent(
      <AddTypeForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadTypes={setReloadTypes}
      />
    );
  };

  const verifyTypeDeletion = async (type) => {
    // const accessToken = getAccessToken();
    // //verifico si existe en alguna factura
    // let responseItems = await typeExistInItemsApi(
    //   accessToken,
    //   type._id
    // );

    // if (responseItems.exist) {
    //   return false;
    // }

    return true;
  };

  return (
    <div className="list-types">
      <div className="list-types__header">
        {checkAction(permissionsActive, userToken.role, "types", "all") ||
        checkAction(permissionsActive, userToken.role, "types", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-types__header-switch">
              <Switch
                defaultChecked
                onChange={() => setViewTypesActives(!viewTypesActives)}
              />
              <span>
                {viewTypesActives ? "Activos" : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "types", "all") ||
        checkAction(permissionsActive, userToken.role, "types", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addTypeModal}>
              Nuevo
            </Button>
          </>
          ) : (
            <>
            </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "types", "all") ||
      checkAction(permissionsActive, userToken.role, "types", "add") || isAdmin(roleById) ? (
        <>
          <div className="list-types__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <Radio.Group
              options={[
                { label: "Nombre", value: "description" },
                { label: "Código", value: "code" },
              ]}
              onChange={(e) => {
                setTypeFilter(e.target.value);
              }}
              value={typeFilter}
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> ejemplos de tipos'
            </h3>
          </div>
        </>
        ) : (
          <>
          </>
      )}

      {viewTypesActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "types", "all") ||
          checkAction(permissionsActive, userToken.role, "types", "view") || isAdmin(roleById) ? (
            <>
              <TypesActive
                typesActive={typesActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadTypes={setReloadTypes}
                verifyTypeDeletion={verifyTypeDeletion}
                //showDeleteConfirm={showDeleteConfirm}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "types", "all") ||
          checkAction(permissionsActive, userToken.role, "types", "view") || isAdmin(roleById) ? (
            <>
              <TypesInactive
                typesInactive={typesInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadTypes={setReloadTypes}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                verifyTypeDeletion={verifyTypeDeletion}
                //showDeleteConfirm={showDeleteConfirm}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function TypesActive(props) {
  const {
    typesActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadTypes,
    verifyTypeDeletion,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editType = (type) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${
        type.description ? type.description : "sin descripción"
      }`
    );
    setModalContent(
      <EditTypeForm
        type={type}
        setIsVisibleModal={setIsVisibleModal}
        setReloadTypes={setReloadTypes}
      />
    );
  };

  const desactivateType = (type) => {
    const accessToken = getAccessToken();

    activateTypeApi(accessToken, type._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadTypes(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (type) => {
    const accessToken = getAccessToken();
    let result = await verifyTypeDeletion(type);
    if (!result) {
      confirm({
        title: "Eliminando Tipo",
        content: `No se puede eliminar ${type.description} existen productos asociados, ¿Desea desactivarlo?`,
        okText: "Aceptar",
        okType: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivateType(type);
        },
      });
    } else {
      confirm({
        title: "Eliminando Tipo",
        content: `¿Estás seguro que quieres eliminar a ${type.description}?`,
        okText: "Eliminar",
        okType: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteTypeApi(accessToken, type._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadTypes(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const edit_type = (
    <div>
      {" "}
      <p>Editar Tipo</p>{" "}
    </div>
  );

  const desactivate_type = (
    <div>
      {" "}
      <p>Pasar a Tipos inactivos</p>{" "}
    </div>
  );

  const delete_type = (
    <div>
      {" "}
      <p>Eliminar Tipo</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "type_code",
    },
    {
      title: "Nombre",
      dataIndex: "description",
    },
    {
      title: "Accion",
      key: "action",
      render: (text, type) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "types", "all") ||
          checkAction(permissionsActive, userToken.role, "types", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_type}>
                <Button
                  className="button-type"
                  type="default"
                  onClick={() => editType(type)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "types", "all") ||
          checkAction(permissionsActive, userToken.role, "types", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_type}>
                <Button
                  className="button-type"
                  type="primary"
                  onClick={() => desactivateType(type)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "types", "all") ||
          checkAction(permissionsActive, userToken.role, "types", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_type}>
                <Button
                  className="button-type"
                  type="danger"
                  onClick={() => showDeleteConfirm(type)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={typesActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function TypesInactive(props) {
  const {
    typesInactive,
    setReloadTypes,
    verifyTypeDeletion,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const activateType = (type) => {
    const accessToken = getAccessToken();

    activateTypeApi(accessToken, type._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadTypes(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (type) => {
    const accessToken = getAccessToken();
    let result = await verifyTypeDeletion(type);
    if (!result) {
      confirm({
        title: "Eliminando -> Tipo",
        content: `No se puede eliminar el tipo ${type.description} existen productos asociados`,
        okText: "Aceptar",
        okType: "danger",
        okCancel: false,
      });
    } else {
      confirm({
        title: "Eliminando -> Tipo",
        content: `¿Estás seguro que quieres eliminar a ${type.description}?`,
        okText: "Eliminar",
        okType: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteTypeApi(accessToken, type._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadTypes(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const activate_type = (
    <div>
      {" "}
      <p>Pasar a Tipo activos</p>{" "}
    </div>
  );

  const delete_type = (
    <div>
      {" "}
      <p>Eliminar Tipo</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "type_code",
    },
    {
      title: "Nombre",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, type) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "types", "all") ||
          checkAction(permissionsActive, userToken.role, "types", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_type}>
                <Button
                  className="button-type"
                  type="primary"
                  onClick={() => activateType(type)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "types", "all") ||
          checkAction(permissionsActive, userToken.role, "types", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_type}>
                <Button
                  className="button-type"
                  type="danger"
                  onClick={() => showDeleteConfirm(type)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={typesInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
