import React, { useState, useEffect } from "react";
import { getAccessToken } from "../api/auth";
import { getRoleByIdApi } from "../api/role";
import { getPermissionsActiveApi } from "../api/permission";
//import { getPermissionsActiveApi, getPermissionsActivePopulateApi } from "../api/permission";
import jwtDecode from "jwt-decode";
import { administradores, supervisores } from "./constants";


export function iteroLista(lista, Popover, xls_download, Button, FileExcelOutlined, ExcelFile, ExcelSheet, ExcelColumn) {
  //let studentsSearch = [];
  //lista.map((item) => {
    //studentsSearch.push(item);
  //});
  //console.log(studentsSearch);
  //return studentsSearch;
  
  return(
    <>
      <ExcelFile filename={'ListadoEstudiantes'} element={
        <Popover content={xls_download}>
          <Button
            type="default"
            shape="round"
            >
            <FileExcelOutlined />
          </Button>
        </Popover>
        }>
        <ExcelSheet data={lista ? lista: null} name="Estudiantes">
          <ExcelColumn label="Apellido" value="lastname" />
          <ExcelColumn label="Nombre" value="name" />
          <ExcelColumn label="Teléfono" value="phone" />
          <ExcelColumn label="Correo" value="email" />
          <ExcelColumn label="Especialidad" value="specialty" />
        </ExcelSheet>
      </ExcelFile>
    </>
  );
}

//LO USA EditStudentForm para mostrar un field o no
export function handleYearsShow(value){
  //console.log(value);
  if (value === 'Abandonó') {
    return true;
  } else {
    return false;
  }
}

//PERMISOS EN USO
export function isAdmin(roleById){

  var actualRole = "";
  for (var r in administradores) {
    if ((administradores)[r] === roleById.name) {
      actualRole = "administrador";
      //actualRole = true;
    }
  }
  if (actualRole === "administrador"){
    return true;
  }
}

//PERMISOS EN USO
export function isEmpty(lista){
  if (lista.length === 0) {
    return true;
  } else {
    return false;
  }
}

//PERMISOS EN USO
export function checkAction(permissionsActive, roleId, module, action) { //permission
  let founded = false;
  if (permissionsActive){
    //for (var i = 0; i < permissionsActive.length; ++i) {
    permissionsActive.forEach((i) => {
      if (roleId === i.role && module === i.module && action === i.action){
        founded = true;
        //break;
        return;
      }
    });
    if (founded === true) {
      return true;
    }
  } else {
    return false;
  }
}

//VERIFICA SI UN ESTUDIANTE TIENE ALGUNA INSCRIPCION
//se cambia por checkStudentHasCourses
//se usa para poner o no el boton de "cursos" en el listado de estudiantes
export function checkInscriptions(inscriptionsActive, studentId) {
  let founded = false;
  //var start = Date.now();
  //let progress = true;
  //while (progress === false) {

  //}
  if (inscriptionsActive){
    inscriptionsActive.forEach((inscription) => {
      //console.log("inscription", inscription);
      if ((inscription.students !== undefined) || (inscription.students !== null)){
        (inscription.students).forEach((student) => {
          if (student._id !== null){
            if (student !== null){
              if (student.student !== null){
                //console.log("student", student);
                if (student.student._id !== null){
                  if (student.student._id === studentId){
                    //console.log("student founded:", student);
                    founded = true;
                    //break;
                    return;
                  }
                }
                //console.log(student.student._id);
              }
            }
          }
        });
      }
    });
    //var end = Date.now();
    if (founded === true) {
      console.log("founded");
      //console.log("tiempo checkInscriptions founded: ", (end - start)/1000);
      return true;
    } else {
      console.log("not founded");
      return false;
    }
  } else {
    //var end = Date.now();
    //console.log("tiempo checkInscriptions else: ", (end - start)/1000);
    //console.log(founded);
    return false;
  }

}

//VERIFICA SI UN ESTUDIANTE TIENE ALGUNA INSCRIPCION por el campo hasCourses
export function checkStudentHasCourses(studentsHasCourses, studentId) {
  let founded = false;
  if (studentsHasCourses){
    studentsHasCourses.forEach((student) => {
      if (student._id === studentId){
        //console.log(student._id);
        founded = true;
        return;
      }
    });
    if (founded === true) {
      return true;
    }
  } else {
    return false;
  }
}

export function checkPermission(permission, permissionsActive, roleId, module, action) { //permission
  const [roleById, setRoleById] = useState([]);
  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken);

  //console.log("permission", permission);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  let allowAccessForRoles = {};
  //allowAccessForRoles = { permission: [roleId] };
  let founded = false;
  if (permissionsActive){
    //for (var i = 0; i < permissionsActive.length; ++i) {
    permissionsActive.forEach((i) => {
      if (roleId === i.role && module === i.module && action === i.action){
        founded = true;
        //console.log("ENTRÓ");
        //break;
        return;
      }
    });
    if (founded === true) {
      //return true;
      var actualRole = "";
      for (var r in administradores) {
        //const index = (response.users).indexOf(r);
        if ((administradores)[r] === roleById.name) {
          actualRole = "administrador";
        }
        /*
        else {
          actualRole = userToken.role;
        }*/
      }
      for (var p in supervisores) {
        //const index = (response.users).indexOf(r);
        if ((supervisores)[p] === roleById.name) {
          actualRole = "supervisor";
        }
        /*
        else {
          actualRole = userToken.role;
        }*/
      }

      if (actualRole !== "administrador" && actualRole !== "supervisor") { //&& actualRole != "supervisor"
        actualRole = roleById.name;
      }
      /*
      function myFunction() {
        var str1 = "Hello";
        var str2 = "world!";
        var res = str1.concat("."+str2);
      */

      //console.log("rol:", actualRole);

      if (permission) {
        //checkUserPermission(roleId, permission);
        console.log("rol 1:", actualRole);

        if (actualRole === "administrador") {
          console.log("rol 2:", actualRole);
          allowAccessForRoles = {
            "route.admin": [roleId],
            "route.absences": [roleId],
            "route.courseAbsences": [roleId],
            "route.days": [roleId],
            "route.clients": [roleId],
            "route.departments": [roleId],
            "route.durations": [roleId],
            "route.inscriptions": [roleId],
            "route.persons": [roleId],
            "route.students": [roleId],
            "route.suppliers": [roleId],
            "route.items": [roleId],
            "route.itemsInventoryStock": [roleId],
            "route.movements": [roleId],
            "route.buyBills": [roleId],
            "route.buyOrders": [roleId],
            "route.billSells": [roleId],
            "route.billTypes": [roleId],
            "route.billSystems": [roleId],
            "route.categories": [roleId],
            "route.documentType": [roleId],
            "route.coinTypes": [roleId],
            "route.entryConditions": [roleId],
            "route.finalConditions": [roleId],
            "route.ivaConditions": [roleId],
            "route.supplierTypes": [roleId],
            "route.paymentTypes": [roleId],
            "route.paymentOrders": [roleId],
            "route.places": [roleId],
            //"route.paymentMethods": ["admin", "supervisor"],
            "route.saleConditions": [roleId],
            "route.sellers": [roleId],
            "route.specialties": [roleId],
            "route.concepts": [roleId],
            "route.stateCourses": [roleId],
            "route.stateSchoolYears": [roleId],
            "route.stateStudentCourses": [roleId],
            "route.stateBuyOrders": [roleId],
            "route.salePoints": [roleId],
            "route.studentConditions": [roleId],
            "route.exports": [roleId],
            "route.imports": [roleId],
            "route.editmenu": [roleId],
            "route.roles": [roleId],
            "route.permissions": [roleId],
            "component.users":[roleId],
            "route.weeks": [roleId],
            "route.workloads": [roleId],
          };
        } else {
          console.log("rol 3:", actualRole);
          allowAccessForRoles = {
            permission: [roleId],
          };
        }

        //Busco el permiso en la lista
        if (!Array.isArray(allowAccessForRoles[permission])) {
          return false;
        }

        //Compruebo si alguno de los roles niega el acceso
        if (allowAccessForRoles[permission].includes("!" + roleId)) {
            return false;
        }

        //Si contiene *, acceso permitido
        if (allowAccessForRoles[permission].includes("*")) {
          return true;
        }

        //Compruebo si el rol de usuario permite el acceso
        if (allowAccessForRoles[permission].includes(roleId)) {
          return true;
        }

        return false;
      }
    }
  } else {
    return false;
  }
}

export function checkUserPermission(role, permission) {
  const [roleById, setRoleById] = useState([]);
  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken);

  //console.log("accessToken", accessToken);
  //console.log("role", role);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  //console.log("roleById", roleById);
  //var roleID = roleById._id;
  //console.log("roleID", roleID);

  var actualRole = "";
  for (var r in administradores) {
    //const index = (response.users).indexOf(r);
    if ((administradores)[r] === roleById.name) {
      actualRole = "administrador";
    }
    /*
    else {
      actualRole = userToken.role;
    }*/
  }
  for (var p in supervisores) {
    //const index = (response.users).indexOf(r);
    if ((supervisores)[p] === roleById.name) {
      actualRole = "supervisor";
    }
    /*
    else {
      actualRole = userToken.role;
    }*/
  }

  if (actualRole !== "administrador" && actualRole !== "supervisor") {
    actualRole = roleById.name;
  }

  //console.log("rol:", actualRole);

  let allowAccessForRoles = {};

  if (actualRole === "administrador") {
    allowAccessForRoles = {
      "route.admin": [role],
      "route.absences": [role],
      "route.courseAbsences": [role],
      "route.days": [role],
      "route.durations": [role],
      "route.departments": [role],
      "route.inscriptions": [role],
      "route.persons": [role],
      "route.users": [role],
      "route.students": [role],
      "route.suppliers": [role],
      "route.items": [role],
      "route.itemsInventoryStock": [role],
      "route.movements": [role],
      "route.buyBills": [role],
      "route.buyOrders": [role],
      "route.billSells": [role],
      "route.billTypes": [role],
      "route.billSystems": [role],
      "route.categories": [role],
      "route.documentType": [role],
      "route.coinTypes": [role],
      "route.entryConditions": [role],
      "route.finalConditions": [role],
      "route.ivaConditions": [role],
      "route.supplierTypes": [role],
      "route.paymentTypes": [role],
      "route.paymentOrders": [role],
      "route.places": [role],
      //"route.paymentMethods": ["admin", "supervisor"],
      "route.saleConditions": [role],
      "route.sellers": [role],
      "route.specialties": [role],
      "route.concepts": [role],
      "route.stateCourses": [role],
      "route.stateSchoolYears": [role],
      "route.stateStudentCourses": [role],
      "route.exports": [role],
      "route.imports": [role],
      "route.editmenu": [role],
      "route.clients": [role],
      "route.roles": [role],
      "route.permissions": [role],
      "route.workloads": [role],
      "component.users":[role]
    };
  } else if (actualRole === "supervisor") {
    allowAccessForRoles = {
      "route.admin": [role],
      "route.absences": [role],
      "route.courseAbsences": [role],
      "route.days": [role],
      "route.departments": [role],
      "route.durations": [role],
      "route.inscriptions": [role],
       "route.persons": [role],
      "route.students": [role],
      "route.suppliers": [role],
      "route.items": [role],
      "route.clients": [role],
      "route.itemsInventoryStock": [role],
      "route.movements": [role],
      "route.buyBills": [role],
      "route.buyOrders":[role],
      "route.billSells": [role],
      "route.billTypes": [role],
      "route.billSystems": [role],
      "route.categories": [role],
      "route.documentType": [role],
      "route.coinTypes": [role],
      "route.ivaConditions": [role],
      "route.supplierTypes": [role],
      "route.places": [role],
      "route.paymentTypes": [role],
      "route.paymentOrders": [role],
      "route.stateSchoolYears": [role],
      "route.stateStudentCourses": [role],
      "route.saleConditions": [role],
      "route.studentConditions": [role],
      "route.sellers": [role],
      "route.salePoints": [role],
      "route.weeks": [role],
      "route.workloads": [role],

    };
  } else {
    allowAccessForRoles = {
      permission: [role], //permission
    };
  }
  //Busco el permiso en la lista
  if (!Array.isArray(allowAccessForRoles[permission])) {
    return false;
  }

  /*
  //Compruebo si alguno de los roles niega el acceso
  if (allowAccessForRoles[permission].includes("!" + role)) {
      return false;
  }

  //Si contiene *, acceso permitido
  if (allowAccessForRoles[permission].includes("*")) {
    return true;
  }
  */

  //Compruebo si el rol de usuario permite el acceso
  if (allowAccessForRoles[permission].includes(role)) {
    return true;
  }

  return false;
}

export function checkUserPermission2(role, permission) {
  const [permissionsActive, setPermissionsActive] = useState([]);
  const accessToken = getAccessToken();
  //const userToken = jwtDecode(accessToken);

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  //const ruta = "route."
  //var ruta_ok = ruta.concat(permission);
  let allowAccessForRoles = {};
  //let founded = false;
  //var module = permission;
  var action = "menu-sider";
  let roles = [];
  if (permissionsActive){
    //for (var i = 0; i < permissionsActive.length; ++i) {
    permissionsActive.forEach((i) => {
      for (var r = 0; r < roles.length; ++r) {
        if(i.role === roles[r]) {
          roles.splice(r);
        }
      }

      if ((action === i.action) && (role === i.role)){
        roles.push(i.role);
      //if (role === i.role && module === i.module && action === i.action){
        //founded = true;
        //console.log("ENTRÓ");
        //return;
      }
    });
    //if (isAdmin(roleById)) {
      //console.log("ES ADMIN"); //allowAccessForRoles todas las rutas
    //}
    if (roles) {
      //console.log(roles);
      allowAccessForRoles = {
        //ruta_ok: roles, //permission
        //ruta_ok: [role],
        "route.admin": [role],
        "route.absences": [role],
        "route.courseAbsences": [role],
        "route.days": [role],
        "route.departments": [role],
        "route.durations": [role],
        "route.inscriptions": [role],
        "route.persons": [role],
        "route.clients": [role],
        "route.users": [role],
        "route.students": [role],
        "route.suppliers": [role],
        "route.items": [role],
        "route.itemsInventoryStock": [role],
        "route.movements": [role],
        "route.buyBills": [role],
        "route.billSells": [role],
        "route.billTypes": [role],
        "route.billSystems": [role],
        "route.categories": [role],
        "route.documentType": [role],
        "route.coinTypes": [role],
        "route.entryConditions": [role],
        "route.finalConditions": [role],
        "route.ivaConditions": [role],
        "route.supplierTypes": [role],
        "route.paymentTypes": [role],
        "route.paymentOrders": [role],
        "route.places": [role],
        //"route.paymentMethods": ["admin", "supervisor"],
        "route.saleConditions": [role],
        "route.sellers": [role],
        "route.concepts": [role],
        "route.stateCourses": [role],
        "route.stateSchoolYears": [role],
        "route.stateStudentCourses": [role],
        "route.studentConditions": [role],
        "route.salePoints": [role],
        "route.exports": [role],
        "route.imports": [role],
        "route.editmenu": [role],
        "route.roles": [role],
        "route.permissions": [role],
        "component.users": [role],
        "route.buyOrders": [role],
        "route.weeks": [role],
        "route.workloads": [role],
        /*
        "route.admin": roles,
        "route.users": roles,
        "route.students": roles,
        "route.suppliers": roles,
        "route.items": roles,
        "route.itemsInventoryStock": roles,
        "route.movements": roles,
        "route.buyBills": roles,
        "route.billSells": roles,
        "route.billTypes": roles,
        "route.billSystems": roles,
        "route.categories": roles,
        "route.documentType": roles,
        "route.ivaConditions": roles,
        "route.supplierTypes": roles,
        "route.paymentTypes": roles,
        "route.paymentOrders": roles,
        //"route.paymentMethods": ["admin", "supervisor"],
        "route.saleConditions": roles,
        "route.sellers": roles,
        "route.concepts": roles,
        "route.stateCourses": roles,
        "route.salePoints": roles,
        "route.exports": roles,
        "route.imports": roles,
        "route.editmenu": roles,
        "route.roles": roles,
        "route.permissions": roles,
        "component.users": roles
        */
      };
    }
  }

  //Busco el permiso en la lista
  if (!Array.isArray(allowAccessForRoles[permission])) {
    return false;
  }


  //Compruebo si alguno de los roles niega el acceso
  if (allowAccessForRoles[permission].includes("!" + role)) {
      return false;
  }

  //Si contiene *, acceso permitido
  if (allowAccessForRoles[permission].includes("*")) {
    return true;
  }


  //Compruebo si el rol de usuario permite el acceso
  if (allowAccessForRoles[permission].includes(role)) {
    return true;
  }

  return false;
}

  /*
  const allowAccessForRoles = {
    "route.admin": ["admin", "administrador", "Administrador", "supervisor", "Supervisor"],
    "route.users": ["admin", "administrador", "Administrador", "60135ea794d5f42e0cc08ff0"],
    "route.students": ["admin", "administrador", "Administrador","supervisor", "60135ea794d5f42e0cc08ff0"],
    "route.suppliers": ["supervisor", "Supervisor", "admin", "administrador", "Administrador"],
    "route.items": ["supervisor", "Supervisor", "admin", "administrador", "Administrador"],
    "route.itemsInventoryStock": ["supervisor", "Supervisor", "admin", "administrador", "Administrador"],
    "route.movements": ["supervisor", "Supervisor", "admin", "administrador", "Administrador"],
    "route.buyBills": ["admin", "administrador", "Administrador", "supervisor", "Supervisor"],
    "route.billSells": ["admin", "administrador", "Administrador", "supervisor", "Supervisor"],
    "route.billTypes": ["admin", "administrador", "Administrador", "supervisor", "Supervisor"],
    "route.billSystems": ["admin", "administrador", "Administrador", "supervisor", "Supervisor"],
    "route.categories": ["admin", "administrador", "Administrador", "supervisor", "Supervisor"],
    "route.documentType": ["admin", "administrador", "Administrador", "supervisor", "Supervisor"],
    "route.ivaConditions": ["admin", "administrador", "Administrador", "supervisor", "Supervisor"],
    "route.entryConditions": ["admin", "administrador", "Administrador", "supervisor", "Supervisor"],
    "route.finalConditions": ["admin", "administrador", "Administrador", "supervisor", "Supervisor"],
    "route.supplierTypes": ["admin", "administrador", "Administrador", "supervisor", "Supervisor"],
    "route.paymentTypes": ["admin", "administrador", "Administrador", "supervisor", "Supervisor"],
    "route.paymentOrders": ["admin", "administrador", "Administrador", "supervisor", "Supervisor"],
    //"route.paymentMethods": ["admin", "supervisor"],
    "route.saleConditions": ["admin", "administrador", "Administrador", "supervisor", "Supervisor"],
    "route.sellers": ["admin", "administrador", "Administrador", "supervisor", "Supervisor"],
    "route.concepts": ["admin", "administrador", "Administrador"],
    "route.stateCourses": ["admin", "administrador", "Administrador"],
    "route.specialties": ["admin", "administrador", "Administrador", "supervisor", "Supervisor"],
    "route.exports": ["admin", "administrador", "Administrador"],
    "route.imports": ["admin", "administrador", "Administrador"],
    "route.editmenu": ["admin", "administrador", "Administrador"],
    "route.roles": ["admin", "administrador", "Administrador"],
    "component.users":["admin", "administrador", "Administrador"]
  };

  //Busco el permiso en la lista
  if (!Array.isArray(allowAccessForRoles[permission])) {
    return false;
  }

  //Compruebo si alguno de los roles niega el acceso
  if (allowAccessForRoles[permission].includes("!" + role)) {
      return false;
  }

  //Si contiene *, acceso permitido
  if (allowAccessForRoles[permission].includes("*")) {
    return true;
  }

  //Compruebo si el rol de usuario permite el acceso
  if (allowAccessForRoles[permission].includes(role)) {
    return true;
  }

  return false;
  */
