import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Row,
  Col,
  Divider,
  Card,
  Statistic,
  Descriptions,
} from "antd";

import "./ViewStockInventoryForm.scss";
import { formatDateView } from "../../../../utils/formatDate";

export default function ViewStockInventoryForm(props) {
  const {
    itemStockInventory
  } = props;

  const [itemInventoryStockData, setItemInventoryStockData] = useState({});

  //trae los datos del inventario
  useEffect(() => {
    setItemInventoryStockData({
      item: itemStockInventory.item,
      quantity_init: itemStockInventory.quantity_init,
      expirationDate:itemStockInventory.expirationDate,
      buyOrderPrice:itemStockInventory.buyOrderPrice,
      stock: itemStockInventory.stock,
      state:itemStockInventory.state.name,
      observation: itemStockInventory.observation,
    });
  }, [itemStockInventory]);

  return (
    <div className="view-stock-inventory-form">
      <ViewForm itemInventoryStockData={itemInventoryStockData} />
    </div>
  );
}

function ViewForm(props) {
  const {
    itemInventoryStockData
  } = props;

  return (
    <Form>
      <Divider orientation="center">Producto</Divider>

      <Descriptions column={2} layout="vertical" size="small" bordered>
        <Descriptions.Item label="Codigo">
          {itemInventoryStockData.item
            ? itemInventoryStockData.item.item_code
            : ""}
        </Descriptions.Item>
        <Descriptions.Item label="Descripción">
          {itemInventoryStockData.item
            ? itemInventoryStockData.item.description
            : ""}
        </Descriptions.Item>
        <Descriptions.Item label="Fecha de Vencimiento">
          {itemInventoryStockData.expirationDate
            ? formatDateView(itemInventoryStockData.expirationDate)
            : ""}
        </Descriptions.Item>
        <Descriptions.Item label="Precio orden de compra">
          {itemInventoryStockData.buyOrderPrice
            ? "$ " + itemInventoryStockData.buyOrderPrice
            : ""}
        </Descriptions.Item>
        <Descriptions.Item label="Estado">
          {itemInventoryStockData.state
            ? itemInventoryStockData.state
            : ""}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="center">Control Stock</Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item>
            <Card>
              <Statistic
                title="Cantidad Inicial"
                valueStyle={{ color: "#3b83bd" }}
                value={itemInventoryStockData.quantity_init}
              />
            </Card>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <Card>
              <Statistic
                title="Stock Actual"
                valueStyle={
                  itemInventoryStockData.stock > 5
                    ? { color: "#3f8600" }
                    : { color: "#f00" }
                }
                value={itemInventoryStockData.stock}
              />
            </Card>
          </Form.Item>
        </Col>
      </Row>

      <Divider></Divider>
      <Descriptions column={2} layout="vertical" size="small" bordered>
        <Descriptions.Item label="Observaciones">
          {itemInventoryStockData.observation}
        </Descriptions.Item>
      </Descriptions>
    </Form>
  );
}
