import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import {
  getBuyOrdersActiveApi,
  getBuyOrdersPaidApi,
  getBuyOrdersActiveByBranchApi,
} from "../../../api/billing/buyOrder";
import ListBuyOrders from "../../../components/Billing/BuyOrders/ListBuyOrders";

import "./BuyOrders.scss";
import { useBranch } from "../../../hooks/useBranch";

export default function BuyOrders() {
  const [buyOrdersActive, setBuyOrdersActive] = useState([]);
  const [buyOrdersInactive, setBuyOrdersInactive] = useState([]);
  const [reloadBuyOrders, setReloadBuyOrders] = useState(false);
  const token = getAccessToken();

  const { branch } = useBranch();

  // useEffect(() => {
  //     getBuyOrdersActiveApi(token, true).then(response => {
  //         setBuyOrdersActive(response.buyOrders);
  //     });
  //     setReloadBuyOrders(false);
  // }, [token, reloadBuyOrders]);

  // useEffect(() => {
  //     getBuyOrdersActiveApi(token, false).then(response => {
  //         setBuyOrdersInactive(response.buyOrders);
  //     });
  //     setReloadBuyOrders(false);
  // }, [token, reloadBuyOrders]);
  
  useEffect(() => {
    if (branch && branch !== 0 && branch !== "0") {
      getBuyOrdersActiveByBranchApi(token, branch, true).then((response) => {
        setBuyOrdersActive(response.buyOrders);
      });
    }
    setReloadBuyOrders(false);
  }, [token,branch, reloadBuyOrders]);

  useEffect(() => {
    if (branch && branch !== 0 && branch !== "0") {
      getBuyOrdersActiveByBranchApi(token, branch, false).then((response) => {
        setBuyOrdersInactive(response.buyOrders);
      });
    }
    setReloadBuyOrders(false);
  }, [token, branch, reloadBuyOrders]);

  return (
    <div className="billorders">
      <ListBuyOrders
        buyOrdersActive={buyOrdersActive}
        BuyOrdersInactive={buyOrdersInactive}
        setReloadBuyOrders={setReloadBuyOrders}
        token={token}
      />
    </div>
  );
}
