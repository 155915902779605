import React, { useState, useEffect } from "react";
import {
  Switch,
  List,
  Input,
  onHover,
  Radio,
  Button,
  notification,
  Row,
  Col,
  Form,
  Select,
  Modal as ModalAntd,
} from "antd";
import {
  EditOutlined,
  EyeOutlined,
  FileExclamationOutlined,
  CloseSquareOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";

import Modal from "../../../Modal";
import EditMerchandiseOrderForm from "../EditMerchandiseOrderForm";
import EditStateForm from "../EditStateForm";
import AddMerchandiseOrderForm from "../AddMerchandiseOrderForm";
import { deleteMerchandiseOrderApi } from "../../../../api/billing/merchandiseOrder";
import SearchByDates from "../../SearchByDates/SearchByDates";
// import { MerchandiseOrdersDetailPdf } from "./pdfMerchandiseOrders";
import { convertState } from "../../../../utils/convert";
//import format from "date-fns/format"
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";
import { formatDateView } from "../../../../utils/formatDate";
import "./ListMerchandiseOrders.scss";

const { confirm } = ModalAntd;
const { Search } = Input;
//import TextArea from "antd/lib/input/TextArea";

export default function ListMerchandiseOrders(props) {
  const {
    token,
    merchandiseOrdersActive,
    merchandiseOrdersInactive,
    // merchandiseOrdersPaid,
    // merchandiseOrdersUnpaid,
    setReloadMerchandiseOrders,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  //console.log(billSellsUnpaid);
  const [viewMerchandiseOrdersActive, setViewMerchandiseOrdersActive] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(null);
  const [filterText, setFilterText] = useState("");

  const [merchandiseOrdersActiveFilter, setMerchandiseOrdersActiveFilter] = useState([]);
  const [merchandiseOrdersInactiveFilter, setMerchandiseOrdersInactiveFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState("code");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listPaid = [];
    if (merchandiseOrdersActive) {
      merchandiseOrdersActive.forEach((element) => {
        if (typeFilter === "state") {
            if ( (element.state &&
              element.state.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
                -1)||
              filterText === ""
            ) {
              listPaid.push(element);
            }
        } else {
          if (
            element.code
              .toString()
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listPaid.push(element);
          }
        }
      });
    }
    setMerchandiseOrdersActiveFilter(listPaid);
  }, [filterText, merchandiseOrdersActive]);

  useEffect(() => {
    const listUnpaid = [];
    if (merchandiseOrdersInactive) {
      merchandiseOrdersInactive.forEach((element) => {
        if (typeFilter === "state") {
            if ( (element.state &&
              element.state.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
                -1) ||
              filterText === ""
            ) {
              listUnpaid.push(element);
            }
        } else {
          if (
            element.code
              .toString()
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listUnpaid.push(element);
          }
        }
      });
    }
    setMerchandiseOrdersInactiveFilter(listUnpaid);
  }, [filterText, merchandiseOrdersInactive]);

  const addBillSellModal = async () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Pedido de Mercaderia");
    setModalWidth(900);
    await setModalContent(
      <AddMerchandiseOrderForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadMerchandiseOrders={setReloadMerchandiseOrders}
      />
    );
  };

  return (
    <div className="list-merchandiseorders">
      <div className="list-merchandiseorders__header">
        {/*
        <div className="list-billbuys__header-switch">
          <Switch
            defaultChecked
            onChange={() => setViewBillBuysActives(!viewBillBuysActives)}
          />
          <span>
            {viewBillBuysActives
              ? "Facturas de compra habilitadas"
              : "Facturas de compra inhabilitadas"}
          </span>
        </div>
        */}
        {checkAction(permissionsActive, userToken.role, "merchandiseorders", "all") ||
        checkAction(permissionsActive, userToken.role, "merchandiseorders", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-merchandiseorders__header-switch">
              <Switch
                defaultChecked
                onChange={() => setViewMerchandiseOrdersActive(!viewMerchandiseOrdersActive)}
              />
              <span>{viewMerchandiseOrdersActive ? "Activas" : "Inactivas"}</span>
            </div>
          </>
        ) : (
          <>
          </>
        )}

        {checkAction(permissionsActive, userToken.role, "merchandiseorders", "all") ||
        checkAction(permissionsActive, userToken.role, "merchandiseorders", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addBillSellModal}>
              Nuevo 
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "merchandiseorders", "all") ||
      checkAction(permissionsActive, userToken.role, "merchandiseorders", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-merchandiseorders__search">
            <Row>
              <Col span={20}>
                <Search
                  className="Buscar"
                  placeholder=""
                  onChange={(e) => setFilterText(e.target.value)}
                  enterButton
                />
                <Radio.Group
                  options={[
                    { label: "Código", value: "code" },
                    { label: "Estado", value: "state" }, //TODO: lee el state de la db, no el convertido
                  ]}
                  onChange={(e) => {
                    setTypeFilter(e.target.value);
                  }}
                  value={typeFilter}
                />
              </Col>
              <Col span={4}>
                <SearchByDates
                  searchList={viewMerchandiseOrdersActive ? MerchandiseOrdersActive : merchandiseOrdersInactive}
                  setSearchList={
                    viewMerchandiseOrdersActive
                      ? setMerchandiseOrdersActiveFilter
                      : setMerchandiseOrdersInactiveFilter
                  }
                  setReload={setReloadMerchandiseOrders}
                  dataSearch={"date"}
                />
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewMerchandiseOrdersActive ? (
        <>
          {checkAction(permissionsActive, userToken.role, "merchandiseorders", "all") ||
          checkAction(permissionsActive, userToken.role, "merchandiseorders", "view") || isAdmin(roleById) ? (
            <>
              <MerchandiseOrdersActive
                merchandiseOrdersActive={merchandiseOrdersActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setModalWidth={setModalWidth}
                setReloadMerchandiseOrders={setReloadMerchandiseOrders}
                token={token}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "merchandiseorders", "all") ||
          checkAction(permissionsActive, userToken.role, "merchandiseorders", "view") || isAdmin(roleById) ? (
            <>
              <MerchandiseOrdersInactive
                merchandiseOrdersInactive={merchandiseOrdersInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setModalWidth={setModalWidth}
                setReloadMerchandiseOrders={setReloadMerchandiseOrders}
                token={token}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={modalWidth}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function MerchandiseOrdersActive(props) {
  const {
    merchandiseOrdersActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setModalWidth,
    setReloadMerchandiseOrders,
    token,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props; //showDeleteConfirm

  const editMerchandiseOrder= (merchandiseOrder) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar ${merchandiseOrder.code ? merchandiseOrder.code : "sin código"}`);
    setModalWidth(900);
    setModalContent(
      <EditMerchandiseOrderForm
        merchandiseOrder={merchandiseOrder}
        setIsVisibleModal={setIsVisibleModal}
        setReloadMerchandiseOrders={setReloadMerchandiseOrders}
      />
    );
  };

  const editState = (bOrder) => {
    setIsVisibleModal(true);
    setModalTitle(`Estado de Pedido de mercaderia ${bOrder.code}`);
    setModalWidth(300);
    setModalContent(
      <EditStateForm
        merchandiseOrder={bOrder}
        setIsVisibleModal={setIsVisibleModal}
        setReloadMerchandiseOrders={setReloadMerchandiseOrders}
      />
    );
  };

  // const addAnulationMerchandiseOrder= async (merchandiseOrder) => {
  //   setIsVisibleModal(true);
  //   setModalTitle("Pedido de Mercaderia- Anulación");
  //   setModalWidth(900);
  //   await setModalContent(
  //     <AddMerchandiseOrderForm
  //       setIsVisibleModal={setIsVisibleModal}
  //       setReloadMerchandiseOrders={setReloadMerchandiseOrders}
  //       associatedMerchandiseOrder={merchandiseOrder}
  //     />
  //   );
  // };

  const showDeleteConfirm = (merchandiseOrder) => {

    confirm({
      title: "Eliminando -> Pedido de Mercaderia",
      content: `¿Estás seguro que quieres eliminar a ${merchandiseOrder.code ? merchandiseOrder.code: "sin codigo"}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteMerchandiseOrderApi(accessToken, merchandiseOrder._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadMerchandiseOrders(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  return (
    <List
      className="merchandiseorders"
      itemLayout="horizontal"
      dataSource={merchandiseOrdersActive}
      renderItem={(merchandiseOrder) => (
        <MerchandiseOrderActive
          merchandiseOrder={merchandiseOrder}
          editMerchandiseOrder={editMerchandiseOrder}
          editState={editState}
          showDeleteConfirm={showDeleteConfirm}
          setReloadMerchandiseOrders={setReloadMerchandiseOrders}
          token={token}
          accessToken={accessToken}
          userToken={userToken}
          permissionsActive={permissionsActive}
          roleById={roleById}
        />
      )} //showDeleteConfirm={showDeleteConfirm}
    />
  );
}

function MerchandiseOrderActive(props) {
  const {
    merchandiseOrder,
    editMerchandiseOrder,
    editState,
    showDeleteConfirm,
    setReloadMerchandiseOrders,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props; //showDeleteConfirm
  var supplierDenomination = "";

  if (merchandiseOrder.supplier) {
    supplierDenomination = merchandiseOrder.supplier.denomination;
  }

  const edit_merchandiseorder = (
    <div>
      {" "}
      <p>Editar pedido</p>{" "}
    </div>
  );

  const changeState = (
    <div>
      {" "}
      <p>Editar estado</p>{" "}
    </div>
  );

  const deleteOrder = (
    <div>
      {" "}
      <p>Eliminar Pedido</p>{" "}
    </div>
  );

  const download_merchandiseorder = (
    <div>
      {" "}
      <p>Descargar Pedido</p>{" "}
    </div>
  );

  return (
    <List.Item
      actions={[
        <div>
          {checkAction(permissionsActive, userToken.role, "merchandiseorders", "all") ||
          checkAction(permissionsActive, userToken.role, "merchandiseorders", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_merchandiseorder}>
                <Button type="default" onClick={() => editMerchandiseOrder(merchandiseOrder)}>
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
         <div>
         {checkAction(permissionsActive, userToken.role, "merchandiseorders", "all") ||
         checkAction(permissionsActive, userToken.role, "merchandiseorders", "edit") || isAdmin(roleById) ? (
           <>
             <Popover content={changeState}>
               <Button
                 className="bg-warning"
                 onClick={() => editState(merchandiseOrder)}
               >
                 <FileExclamationOutlined />
               </Button>
             </Popover>
           </>
         ) : (
           <>
           </>
         )}
       </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "merchandiseorders", "all") ||
          checkAction(permissionsActive, userToken.role, "merchandiseorders", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={deleteOrder}>
                <Button type="danger" onClick={() => showDeleteConfirm(merchandiseOrder)}>
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        // <div>
        //   {checkAction(permissionsActive, userToken.role, "merchandiseorders", "all") ||
        //   checkAction(permissionsActive, userToken.role, "merchandiseorders", "pdf") || isAdmin(roleById) ? (
        //     <>
        //       <Popover content={download_merchandiseorder}>
        //         <Button
        //           type="primary"
        //           onClick={() => {
        //             // merchandiseOrderDetailPdf(merchandiseOrder, false);
        //           }}
        //         >
        //           <DownloadOutlined />
        //         </Button>
        //       </Popover>
        //     </>
        //   ) : (
        //     <>
        //     </>
        //   )}
        // </div>,
      ]}
    >
      <List.Item.Meta
        title={`
                  ${ merchandiseOrder.code ? merchandiseOrder.code : "sin código"
                  },
                  ${
                    formatDateView(merchandiseOrder.date)
                      ? formatDateView(merchandiseOrder.date)
                      : "sin fecha"
                  },
                  ${supplierDenomination ? supplierDenomination : "no hay proveedor"}
                `} //${supplierPaid.name ? supplierPaid.name : "no hay proveedor"}
        description={` ${(merchandiseOrder.state? merchandiseOrder.state.name : "sin estado")} `}
      />
    </List.Item>
  );
}

function MerchandiseOrdersInactive(props) {
  const {
    merchandiseOrdersInactive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setModalWidth,
    setReloadMerchandiseOrders,
    token,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props; //showDeleteConfirm

  const editMerchandiseOrder = (merchandiseOrder) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar ${merchandiseOrder.code ? merchandiseOrder.code : "sin código"}`);
    setModalWidth(900);
    setModalContent(
      <EditMerchandiseOrderForm
        merchandiseOrder={merchandiseOrder}
        setIsVisibleModal={setIsVisibleModal}
        setReloadMerchandiseOrders={setReloadMerchandiseOrders}
      />
    );
  };

  const editState = (bOrder) => {
    setIsVisibleModal(true);
    setModalTitle(`Estado de Pedido de compra ${bOrder.code}`);
    setModalWidth(300);
    setModalContent(
      <EditStateForm
        merchandiseOrder={bOrder}
        setIsVisibleModal={setIsVisibleModal}
        setReloadMerchandiseOrders={setReloadMerchandiseOrders}
      />
    );
  };

  const showDeleteConfirm = (merchandiseOrder) => {
    //const accessToken = getAccessToken();

    confirm({
      title: "Eliminando -> Pedido de Mercaderia",
      content: `¿Estás seguro que quieres eliminar a ${merchandiseOrder.code ? merchandiseOrder.code: "sin codigo"}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteMerchandiseOrderApi(accessToken, merchandiseOrder._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadMerchandiseOrders(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  return (
    <List
      className="merchandiseorders"
      itemLayout="horizontal"
      dataSource={merchandiseOrdersInactive}
      renderItem={(merchandiseOrder) => (
        <MerchandiseOrderInactive
          merchandiseOrder={merchandiseOrder}
          editMerchandiseOrder={editMerchandiseOrder}
          editState={editState}
          showDeleteConfirm={showDeleteConfirm}
          setReloadMerchandiseOrders={setReloadMerchandiseOrders}
          token={token}
          accessToken={accessToken}
          userToken={userToken}
          permissionsActive={permissionsActive}
          roleById={roleById}
        />
      )} 
    />
  );
}

function MerchandiseOrderInactive(props) {
  const {
    merchandiseOrder,
    editState,
    viewMerchandiseOrder,
    editMerchandiseOrder,
    showDeleteConfirm,
    setReloadMerchandiseOrder,
    token,
    userToken,
    permissionsActive,
    roleById
  } = props; //showDeleteConfirm
  var supplierDenomination = "";

  if (merchandiseOrder.supplier) {
    supplierDenomination = merchandiseOrder.supplier.denomination;
  }

  const edit_merchandiseorder = (
    <div>
      {" "}
      <p>Editar Pedido</p>{" "}
    </div>
  );

  const changeState = (
    <div>
      {" "}
      <p>Editar estado</p>{" "}
    </div>
  );

  const download_merchandiseorder= (
    <div>
      {" "}
      <p>Descargar Pedido</p>{" "}
    </div>
  );

  const delete_merchandiseorder = (
    <div>
      {" "}
      <p>Eliminar Pedido</p>{" "}
    </div>
  );

  return (
    <List.Item
      actions={[
       
        <div>
          {checkAction(permissionsActive, userToken.role, "merchandiseorders", "all") ||
          checkAction(permissionsActive, userToken.role, "merchandiseorders", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_merchandiseorder}>
                <Button type="default" onClick={() => editMerchandiseOrder(merchandiseOrder)}>
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        <div>
        {checkAction(permissionsActive, userToken.role, "merchandiseorders", "all") ||
        checkAction(permissionsActive, userToken.role, "merchandiseorders", "edit") || isAdmin(roleById) ? (
          <>
            <Popover content={changeState}>
              <Button
                className="bg-warning"
                onClick={() => editState(merchandiseOrder)}
              >
                <FileExclamationOutlined />
              </Button>
            </Popover>
          </>
        ) : (
          <>
          </>
        )}
      </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "merchandiseorders", "all") ||
          checkAction(permissionsActive, userToken.role, "merchandiseorders", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_merchandiseorder}>
                <Button type="danger" onClick={() => showDeleteConfirm(merchandiseOrder)}>
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        // <div>
        //   {checkAction(permissionsActive, userToken.role, "billsells", "all") ||
        //   checkAction(permissionsActive, userToken.role, "billsells", "pdf") || isAdmin(roleById) ? (
        //     <>
        //       <Popover content={download_merchandiseorder}>
        //         <Button
        //           type="primary"
        //           onClick={() => {
        //             // merchandiseOrderDetailPdf(merchandiseOrder, false);
        //           }}
        //         >
        //           <DownloadOutlined />
        //         </Button>
        //       </Popover>
        //     </>
        //   ) : (
        //     <>
        //     </>
        //   )}
        // </div>,
      ]}
    >
      <List.Item.Meta
        title={`
                  ${merchandiseOrder.code ? merchandiseOrder.code : "sin código"},
                  ${
                    formatDateView(merchandiseOrder.date)
                      ? formatDateView(merchandiseOrder.date)
                      : "sin fecha"
                  },
                  ${supplierDenomination ? supplierDenomination : "no hay proveedor"}
                `}
        description={` ${(merchandiseOrder.state? merchandiseOrder.state.name : "sin estado")} `}
      />
    </List.Item>
  );
}
