
import { Input, Button, message } from 'antd';

export function generateInvoiceNumber(input ) {
    // Aseguramos que el prefijo tenga 4 dígitos y el número tenga 8 dígitos.
    if (input && !input.includes('-')) {
        message.error('El formato debe ser "prefijo-numero"');
        return;
    }
    // Dividir la entrada por el guion ('-')
    let [prefix, number] = input.split('-').map(item => parseInt(item)); 

    let prefixWithZeros= prefix.toString().padStart(4, '0');  // Completa con ceros a la izquierda hasta 4 dígitos
    let numberWithZeros = number.toString().padStart(8, '0');    // Completa con ceros a la izquierda hasta 8 dígitos

    // Devuelve el número de factura en el formato requerido.
    return `${prefixWithZeros}-${numberWithZeros}`;
}