import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getStateInventoriesActiveApi } from "../../../api/billing/stateInventory";
import ListStateInventories from "../../../components/Billing/StateInventories/ListStateInventories";


export default function StateInventories() {
    const [stateInventoriesActive, setStateInventoriesActive] = useState([]);
    const [stateInventoriesInactive, setStateInventoriesInactive] = useState([]);
    const [reloadStateInventories, setReloadStateInventories ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getStateInventoriesActiveApi(token, true).then(response => {
            console.log(response)
            setStateInventoriesActive(response.stateInventories);
        });
        getStateInventoriesActiveApi(token, false).then(response => {
            setStateInventoriesInactive(response.stateInventories);
        });
        setReloadStateInventories(false);
    }, [token, reloadStateInventories]);

    return (
        <div className="stateinventories">

            <ListStateInventories stateInventoriesActive={stateInventoriesActive} stateInventoriesInactive={stateInventoriesInactive} setReloadStateInventories={setReloadStateInventories}
                />

        </div>
    );
}