import { basePath, apiVersion } from '../config';

export function getStockInventoryExportApi(token) {
    const url = `${basePath}/${apiVersion}/stockinventory-export-csv`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "text/csv",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'stockInventory.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function addStockInventoryApi(token, itemId, data){
    const url = `${basePath}/${apiVersion}/add-stock-inventory/${itemId}`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    };

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            return result;
        }) 
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function getStockInventoriesApi(token) {
    const url = `${basePath}/${apiVersion}/stock-inventories`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
             Authorization: token
        }
    };
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getStockInventoryApi(token,idInventory) {
    const url = `${basePath}/${apiVersion}/stock-inventory/${idInventory}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
             Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function updateStockInventoryApi(token,idItemInventory,data) {
    const url = `${basePath}/${apiVersion}/update-stock-inventory/${idItemInventory}`;
    const params = {
        method: "PUT",
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    };

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err;
    });
}

export function getStockInventoryByItemApi(token,idItem) {
    const url = `${basePath}/${apiVersion}/stock-inventory-item/${idItem}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
             Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function updateAddStockInventoryApi(token,idItemInventory,data) {
    const url = `${basePath}/${apiVersion}/add-stock-inventory-item/${idItemInventory}`;
    const params = {
        method: "PUT",
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    };

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err;
    });
}

export function updateSubStockInventoryApi(token, idItemInventory,data) {
    const url = `${basePath}/${apiVersion}/sub-stock-inventory-item/${idItemInventory}`;
    const params = {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err;
    });
}

export function deleteStockInventoryApi(token, idItemInventory) {
    const url = `${basePath}/${apiVersion}/delete-stock-inventory/${idItemInventory}`;
    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization:token
        },
    };

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err;
    });
}

export function deleteStockInventoryByItemApi(token, idItem) {
    const url = `${basePath}/${apiVersion}/delete-stock-inventory-item/${idItem}`;
    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization:token
        },
    };

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err;
    });
}
