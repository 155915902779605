import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getBrandsActiveApi } from "../../../api/billing/brand";
import ListBrands from "../../../components/Billing/Brands/ListBrands";

export default function Brands() {
    const [brandsActive, setBrandsActive] = useState([]);
    const [brandsInactive, setBrandsInactive] = useState([]);
    const [reloadBrands, setReloadBrands ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getBrandsActiveApi(token, true).then(response => {
            setBrandsActive(response.brands);
        });
        getBrandsActiveApi(token, false).then(response => {
            setBrandsInactive(response.brands);
        });
        setReloadBrands(false);
    }, [token, reloadBrands]);

    return (
        <div className="brands">

            <ListBrands brandsActive={brandsActive} brandsInactive={brandsInactive} setReloadBrands={setReloadBrands} 
                />
        
        
        </div>
    );
}