import { basePath, apiVersion } from '../config';

/**FUNCIONES PARA MANEJO DEL MODULO BUYORDER QUE CONSUMEN LOS ENDPOINTS DEL SERVER*/

export function getMerchandiseOrderExportApi(token) {
    const url = `${basePath}/${apiVersion}/merchandiseorder-export-csv`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "text/csv",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'buyOrder.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function postMerchandiseOrderImportApi(token, file) {
    const url = `${basePath}/${apiVersion}/merchandiseorder-import-csv`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    const params = {
        method: "POST",
        body: formData,
        headers: {
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            return err.message;
        });
}

export function addMerchandiseOrderApi(token, data, userId){
    const url = `${basePath}/${apiVersion}/add-merchandiseorder/${userId}`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    };

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function getMerchandiseOrderByCodeApi(token,idMerchandiseOrder) {
    const url = `${basePath}/${apiVersion}/merchandiseorder-code/${idMerchandiseOrder}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getMerchandiseOrdersApi(token) {
    const url = `${basePath}/${apiVersion}/merchandiseorders`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getMerchandiseOrdersActiveApi(token, status) {
    const url = `${basePath}/${apiVersion}/merchandiseorders-active?active=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getMerchandiseOrdersPaidApi(token, status) {
    const url = `${basePath}/${apiVersion}/merchandiseorders-paid?paid=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function updateMerchandiseOrderApi(token, buyOrderData, buyOrderId) {
    const url = `${basePath}/${apiVersion}/update-merchandiseorder/${buyOrderId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(buyOrderData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function activateMerchandiseOrderApi(token, buyOrderId, status) {
    const url = `${basePath}/${apiVersion}/activate-merchandiseorder/${buyOrderId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            active: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function paidMerchandiseOrderApi(token, buyOrderId, status) {
    const url = `${basePath}/${apiVersion}/paid-merchandiseorder/${buyOrderId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            paid: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function paidStateMerchandiseOrderApi(token, buyOrderId, state, paid) {
    const url = `${basePath}/${apiVersion}/paid-state-merchandiseorder/${buyOrderId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            state: state,
            paid: paid
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function deleteMerchandiseOrderApi(token, buyOrderId) {
    const url = `${basePath}/${apiVersion}/delete-merchandiseorder/${buyOrderId}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function itemExistInMerchandiseOrdersApi(token,idItem) {
    const url = `${basePath}/${apiVersion}/merchandiseorders-exist-item/${idItem}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getMerchandiseOrdersSupplierApi(token, idSupplier) {
    const url = `${basePath}/${apiVersion}/merchandiseorders-supplier/${idSupplier}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getMerchandiseOrderLastItemPriceApi(token, idItem) {
    const url = `${basePath}/${apiVersion}//merchandiseorder-lastitemprice/${idItem}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getMerchandiseOrdersActiveByStateApi(token, status, state) {
    const url = `${basePath}/${apiVersion}/merchandiseorders-active-state?active=${status}&state=${state}`
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getMerchandiseOrdersByBranchApi(token,idBranch) {
    const url = `${basePath}/${apiVersion}/merchandiseorders-branch/${idBranch}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getMerchandiseOrdersActiveByBranchApi(token,idBranch, status) {
    const url = `${basePath}/${apiVersion}/merchandiseorders-active-branch/${idBranch}?active=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getMerchandiseOrdersActiveByBranchAndStateApi(token,idBranch, status, state) {
    const url = `${basePath}/${apiVersion}/merchandiseorders-active-branch-state/${idBranch}?active=${status}&state=${state}`
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getNextSequenceCodeByBranchApi(token,idBranch) {
    const url = `${basePath}/${apiVersion}/merchandiseorder-code-branch/${idBranch}`
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}