// TiendaSelector.js
import React, { useEffect, useState } from "react";
import { Select, Menu, Dropdown } from "antd";
import { useBranch } from "../../../hooks/useBranch";
import {
  getBranchesActiveByBusinessApi,
} from "../../../api/branch";
import { getAccessToken } from "../../../api/auth";
import { useBusiness } from "../../../hooks/useBusiness";
import {
  ShopOutlined,
} from "@ant-design/icons";

const { Option } = Select;

export default function BranchSelector(props) {
  const { branch, changeBranch } = useBranch();
  const { business } = useBusiness();
  const [listBranchesActive, setListBranchesActive] = useState([]);
  const [branchSelected, setBranchSelected] = useState("");

  useEffect(() => {
    if (business && business !== "0" && business !== 0) {
      const accessToken = getAccessToken();
      getBranchesActiveByBusinessApi(accessToken, true, business).then(
        (response) => {
          let newList = response.branches ? response.branches : [];
          newList.push({ _id: 0, name: "todos" });
          setListBranchesActive(newList);
          const selected = newList.find((s) => s._id === branch);
          setBranchSelected(
            selected ? (selected.name ? selected.name : "Principal") : ""
          );
        }
      );
    } else {
      changeBranch("0");
    }
  }, [business]);

  useEffect(() => {
    const sucursal = listBranchesActive.find((s) => s._id === branch);
    setBranchSelected(
      sucursal ? (sucursal.name ? sucursal.name : "Principal") : ""
    );
  }, [branch]);

  const handleChange = (value) => {
    changeBranch(value.key);
  };

  const spanStyle = {
    color: "black",
    width: "150px",
    textAlign: "left",
  };

  const menu = (
    <Menu onClick={handleChange} style={spanStyle}>
      {listBranchesActive.map((branch) =>
        branch.isMain ? (
          <Menu.Item key={branch._id}>{"Principal"}</Menu.Item>
        ) : (
          <Menu.Item key={branch._id}>{branch.name}</Menu.Item>
        )
      )}
    </Menu>
  );

  return (
    <div>
      {listBranchesActive && listBranchesActive.length > 0 ? (
        <Dropdown.Button
          className="ant-dropdown-button"
          overlay={menu}
          trigger={["click"]}
        >
          <span style={spanStyle}>
            {" "}
            <ShopOutlined />{" "}
            {branchSelected !== "" && branchSelected !== "todos"
              ? branchSelected
              : "Todas las sucursales"}
          </span>
        </Dropdown.Button>
      ) : null}
    </div>
  );
}
