import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  List,
  Radio,
  Avatar,
  Button,
  notification,
  Modal as ModalAntd,
  Popover,
  Space,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  ExportOutlined,
  DownloadOutlined,
  PlusOutlined
} from "@ant-design/icons";

import Modal from "../../../Modal";
import EditPersonForm from "../EditPersonForm";
import AddPersonForm from "../AddPersonForm";
//import MenuTop from "../../MenuTop";
import { getAccessToken } from "../../../../api/auth";
import { deletePersonApi, activatePersonApi } from "../../../../api/person";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";
import useRouteProps from "../../../../hooks/UseRouteProps";
import { PluralizeEsp } from "../../../../utils/convert";
import { personDetailPdf, listPersonsPdf } from "./pdfPersons";

import "./ListPersons.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListPersons(props) {
  const { personsActive, personsInactive, setReloadPersons } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewPersonsActives, setViewPersonsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [personsActiveFilter, setPersonsActiveFilter] = useState([]);
  const [personsInactiveFilter, setPersonsInactiveFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState("name");
  const [title, setTitle] = useState(useRouteProps().title);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then((response) => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then((response) => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listActive = [];
    if (personsActive) {
      personsActive.forEach((element) => {
          if (typeFilter === "name") {
            if (
              element.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
                -1 ||
              filterText === ""
            ) {
              listActive.push(element);
            }
          } else if (typeFilter === "documentNumber") {
            if (
              (element.documentNumber && element.documentNumber.toString().indexOf(filterText) !== -1 )||
              filterText === ""
            ) {
              listActive.push(element);
            }

          } else if (typeFilter === "cuit_cuil") {
            if (
              element.cuit_cuil.toString().indexOf(filterText) !== -1 ||
              filterText === ""
            ) {
              listActive.push(element);
          }
        }
      });
    }
    setPersonsActiveFilter(listActive);
  }, [filterText, personsActive]);

  useEffect(() => {
    const listInactive = [];
    if (personsInactive) {
      personsInactive.forEach((element) => {
        if (typeFilter === "name" && element.name) {
          if (
            element.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
              -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        } else if (typeFilter === "documentNumber" && element.documentNumber) {
          if (
            element.documentNumber.toString().indexOf(filterText) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }

        } else if (typeFilter === "cuit_cuil" && element.cuit_cuil) {
          if (
            element.cuit_cuil.toString().indexOf(filterText) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        }
      });
    }
    setPersonsInactiveFilter(listInactive);
  }, [filterText, personsInactive]);

  const addUserModal = () => {
    setIsVisibleModal(true);
    setModalTitle(`Crear ${"Persona"}`);
    setModalWidth(800);
    setModalContent(
      <AddPersonForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadPersons={setReloadPersons}
      />
    );
  };

  return (
    <div className="list-persons">
      <div className="list-persons__header">
        {checkAction(permissionsActive, userToken.role, "persons", "all") ||
        checkAction(permissionsActive, userToken.role, "persons", "enable") ||
        isAdmin(roleById) ? (
          <>
            <div className="list-users__header-switch">
              <Switch
                defaultChecked
                onChange={() => setViewPersonsActives(!viewPersonsActives)}
              />
              <span>
                {viewPersonsActives ? `Activos` : `Inactivos`}
              </span>
            </div>
          </>
        ) : (
          <></>
        )}

        {checkAction(permissionsActive, userToken.role, "persons", "all") ||
        checkAction(permissionsActive, userToken.role, "persons", "add") ||
        isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addUserModal}>
              {`Nuevo`}
            </Button>
          </>
        ) : (
          <></>
        )}
        </div>
        {checkAction(permissionsActive, userToken.role, "persons", "all") ||
        checkAction(permissionsActive, userToken.role, "persons", "view") ||
        isAdmin(roleById) ? (
          <>
            <div className="list-persons__search">
              <Search
                className="Buscar"
                placeholder=""
                onChange={(e) => setFilterText(e.target.value)}
                enterButton
              />
              <Radio.Group
                options={[
                  { label: "Nombre", value: "name" },
                  { label: "Número de Documento", value: "documentNumber" },
                  { label: "CUIL/CUIT", value: "cuit_cuil" },
                ]}
                onChange={(e) => {
                  setTypeFilter(e.target.value);
                }}
                value={typeFilter}
              />
            </div>
          </>
        ) : (
          <></>
        )}

      {viewPersonsActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "persons", "all") ||
          checkAction(permissionsActive, userToken.role, "persons", "pdf") ||
          isAdmin(roleById) ? (
            <>
              <Space align="center" style={{ marginBottom: 16 }}>
                <Button
                  type="default"
                  shape="round"
                  onClick={() => {
                    listPersonsPdf(personsActiveFilter, false);
                  }}
                >
                  <DownloadOutlined /> Listado Personas
                </Button>
              </Space>
            </>
          ) : (
            <></>
          )}
          {checkAction(permissionsActive, userToken.role, "persons", "all") ||
          checkAction(permissionsActive, userToken.role, "persons", "view") ||
          isAdmin(roleById) ? (
            <>
              <PersonsActive
                personsActive={personsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setModalWidth={setModalWidth}
                setReloadPersons={setReloadPersons}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                //showDeleteConfirm={showDeleteConfirm}
              />
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "persons", "all") ||
          checkAction(permissionsActive, userToken.role, "persons", "pdf") ||
          isAdmin(roleById) ? (
            <>
              <Space align="center" style={{ marginBottom: 16 }}>
                <Button
                  type="default"
                  shape="round"
                  onClick={() => {
                    listPersonsPdf(personsInactiveFilter, false);
                  }}
                >
                  <DownloadOutlined /> Listado Personas
                </Button>
              </Space>
            </>
          ) : (
            <></>
          )}
          {checkAction(permissionsActive, userToken.role, "persons", "all") ||
          checkAction(permissionsActive, userToken.role, "persons", "view") ||
          isAdmin(roleById) ? (
            <>
              <PersonsInactive
                personsInactive={personsInactiveFilter}
                setReloadPersons={setReloadPersons}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                //showDeleteConfirm={showDeleteConfirm}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={modalWidth}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function PersonsActive(props) {
  const {
    personsActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setModalWidth,
    setReloadPersons,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
  } = props; //showDeleteConfirm

  const editPerson = (person) => {
    setIsVisibleModal(true);
    setModalWidth(800);
    setModalTitle(
      `Editar ${person.name ? person.name : "sin nombre"} ${
        person.lastname ? person.lastname : "sin apellido"
      }`
    );
    setModalContent(
      <EditPersonForm
        person={person}
        setIsVisibleModal={setIsVisibleModal}
        setReloadPersons={setReloadPersons}
      />
    );
    //setModalContent(<MenuTop user={user} setIsVisibleModal={setIsVisibleModal} setReloadUsers={setReloadUsers} />);
  };

  return (
    <List
      className="persons-active"
      itemLayout="horizontal"
      dataSource={personsActive}
      renderItem={(person) => (
        <PersonActive
          person={person}
          editPerson={editPerson}
          setReloadPersons={setReloadPersons}
          accessToken={accessToken}
          userToken={userToken}
          permissionsActive={permissionsActive}
          roleById={roleById}
        />
      )} //showDeleteConfirm={showDeleteConfirm}
    />
  );
}

function PersonActive(props) {
  const {
    person,
    editPerson,
    setReloadPersons,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
  } = props; //showDeleteConfirm
  // const [ avatar, setAvatar ] = useState(null);

  // useEffect(() => {
  //     if(person.avatar) {
  //         getPersonAvatarApi(user.avatar).then(response => {
  //             setAvatar(response);
  //         })
  //     } else {
  //         setAvatar(null);
  //     }
  // }, [person]);

  const desactivatePerson = () => {
    const accessToken = getAccessToken();

    activatePersonApi(accessToken, person._id, false)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadPersons(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = () => {
    const accessToken = getAccessToken();

    confirm({
      title: "Eliminando persona",
      content: `¿Estás seguro que quieres eliminar a ${person.email}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deletePersonApi(accessToken, person._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadPersons(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const edit_person = (
    <div>
      {" "}
      <p>Editar persona</p>{" "}
    </div>
  );

  const desactivate_person = (
    <div>
      {" "}
      <p>Pasar a personas desactivadas</p>{" "}
    </div>
  );

  const delete_person = (
    <div>
      {" "}
      <p>Eliminar persona</p>{" "}
    </div>
  );
  const download_person = (
    <div>
      {" "}
      <p>Descargar detalle persona</p>{" "}
    </div>
  );

  return (
    <List.Item
      actions={[
        <div>
          {checkAction(permissionsActive, userToken.role, "persons", "all") ||
          checkAction(permissionsActive, userToken.role, "persons", "edit") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={edit_person}>
                <Button type="default" onClick={() => editPerson(person)}>
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "persons", "all") ||
          checkAction(permissionsActive, userToken.role, "persons", "enable") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_person}>
                <Button type="primary" onClick={desactivatePerson}>
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "persons", "all") ||
          checkAction(permissionsActive, userToken.role, "persons", "delete") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={delete_person}>
                <Button type="danger" onClick={showDeleteConfirm}>
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "persons", "all") ||
          checkAction(permissionsActive, userToken.role, "persons", "pdf") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={download_person}>
                <Button
                  type="primary"
                  onClick={() => {
                    personDetailPdf(person, false);
                  }}
                >
                  <DownloadOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>,
      ]}
    >
      <List.Item.Meta
        // avatar={<Avatar src={avatar ? avatar : NoAvatar} />}
        title={`
                    ${person.name ? person.name : "..."}
                    ${person.lastname ? person.lastname : "..."}
                `}
        description={person.email}
      />
    </List.Item>
  );
}

function PersonsInactive(props) {
  const {
    personsInactive,
    setReloadPersons,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
  } = props; //showDeleteConfirm
  return (
    <List
      className="persons-active"
      itemLayout="horizontal"
      dataSource={personsInactive}
      renderItem={(person) => (
        <PersonInactive
          person={person}
          setReloadPersons={setReloadPersons}
          accessToken={accessToken}
          userToken={userToken}
          permissionsActive={permissionsActive}
          roleById={roleById}
        />
      )} //showDeleteConfirm={showDeleteConfirm}
    />
  );
}

function PersonInactive(props) {
  const {
    person,
    setReloadPersons,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
  } = props; //showDeleteConfirm
  const [avatar, setAvatar] = useState(null);

  // useEffect(() => {
  //     if(person.avatar) {
  //         getPersonAvatarApi(person.avatar).then(response => {
  //             setAvatar(response);
  //         })
  //     } else {
  //         setAvatar(null);
  //     }
  // }, [person]);

  const activatePerson = () => {
    const accessToken = getAccessToken();

    activatePersonApi(accessToken, person._id, true)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadPersons(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = () => {
    const accessToken = getAccessToken();

    confirm({
      title: "Eliminando person",
      content: `¿Estás seguro que quieres eliminar a ${person.email}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deletePersonApi(accessToken, person._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadPersons(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const activate_person = (
    <div>
      {" "}
      <p>Pasar a personas activas</p>{" "}
    </div>
  );

  const delete_person = (
    <div>
      {" "}
      <p>Eliminar persona</p>{" "}
    </div>
  );

  const download_person = (
    <div>
      {" "}
      <p>Descargar detalle persona</p>{" "}
    </div>
  );

  return (
    <List.Item
      actions={[
        <div>
          {checkAction(permissionsActive, userToken.role, "persons", "all") ||
          checkAction(permissionsActive, userToken.role, "persons", "enable") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={activate_person}>
                <Button type="primary" onClick={activatePerson}>
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "persons", "all") ||
          checkAction(permissionsActive, userToken.role, "persons", "delete") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={delete_person}>
                <Button type="danger" onClick={showDeleteConfirm}>
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "persons", "all") ||
          checkAction(permissionsActive, userToken.role, "persons", "pdf") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={download_person}>
                <Button
                  type="primary"
                  onClick={() => {
                    personDetailPdf(person, false);
                  }}
                >
                  <DownloadOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>,
      ]}
    >
      <List.Item.Meta
        // avatar={<Avatar src={avatar ? avatar : NoAvatar} />}
        title={`
                ${person.name ? person.name : "..."}
                ${person.lastname ? person.lastname : "..."}
            `}
        description={person.email}
      />
    </List.Item>
  );
}
