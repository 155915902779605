export function minLengthValidation(inputData, minLength) {
    const { value } = inputData;

    removeClassErrorSuccess(inputData);

    if (value.length >= minLength) {
        inputData.classList.add("success");
        return true;
    } else {
        inputData.classList.add("error");
        return false;
    }
}

export function passwordValidation(inputData) {
    // eslint-disable-next-line no-useless-escape
    const passwordValid= /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
    const { value } = inputData;

    removeClassErrorSuccess(inputData);

    const resultValidation = passwordValid.test(value);
    if(resultValidation) {
        inputData.classList.add("success");
        return true;
    } else {
        inputData.classList.add("error");
        return false;
    }
}

export function stringValidation (inputData) {
    // eslint-disable-next-line no-useless-escape
    const charsValid = /^([a-zA-Z0-9ÑñÁáÉéÍíÓóÚúÜü_\u00E0\u00FC\.\,\(\)\|\&\- ])*$/;
    const { value } = inputData;

    removeClassErrorSuccess(inputData);

    const resultValidation = charsValid.test(value);
    if(resultValidation) {
        inputData.classList.add("success");
        return true;
    } else {
        inputData.classList.add("error");
        return false;
    }
}

export function phoneValidation1(inputData){
    //eliminamos todo lo que no es dígito
    const { value } = inputData;
    const num = value.replace( /\D+/, '');
    // //devolver si coincidió con el regex/^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/
    const numberValid =  /^[\s()+-]*([0-9][\s()+-]*){6,20}$/;

    removeClassErrorSuccess(inputData);

    const resultValidation = numberValid.test(num);
    if(resultValidation) {
        inputData.classList.add("success");
        return true;
    } else {
        inputData.classList.add("error");
        return false;
    }
}

export function phoneValidation2(inputData) {
    // Eliminamos todo lo que no es dígito
    const { value } = inputData;
    const num = value.replace(/\D+/g, '');

    // Expresión regular específica para números de teléfono en Argentina
    //const numberValid = /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/;
    //const numberValid = /^(?:(?:\+|0{0,2})54|\()?(11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??(\d{8})$/;
    //const numberValid = /^(?:(?:\+|0{0,2})54)?(?:\s?|\()?([2368]\d)(?:(?=\d{0,2}15)\d{2})??(\d{8})$/;
    const numberValid = /^(?:(?:\+|0{0,2})54)?(?:\s?|\()?([2368]\d)?(?:(?=\d{0,2}15)\d{2})??(\d{8})$/;

    // Remover clases de error o éxito
    removeClassErrorSuccess(inputData);
    console.log("Número de teléfono:", num);
    const resultValidation = numberValid.test(num);
    if (resultValidation) {
        inputData.classList.add("success");
        return num;  // Devolver el número validado
    } else {
        inputData.classList.add("error");
        return false;
    }
}

export function phoneValidation3(inputData) {
    console.log("Ingresando a phoneValidation");
    // Eliminamos todo lo que no es dígito
    const num = inputData.value.replace(/\D+/g, '');
    console.log("Número después de eliminar no dígitos:", num);

    // Expresión regular para números de teléfono en Argentina
    const numberValid = /^(?:(?:\+|0{0,2})54)?(?:\s?|\()?([2368]\d)?(?:(?=\d{0,2}15)\d{2})??(\d{8})$/;

    const resultValidation = numberValid.test(num);
    console.log("Resultado de la validación:", resultValidation);

    if (resultValidation) {
        inputData.classList.add("success");
        return true;
    } else {
        inputData.classList.add("error");
        return false;
    }
}

export function phoneValidation5(inputData) {
    const { value } = inputData;
    const num = value.replace(/\D+/g, ''); // Elimina caracteres no numéricos
    const numberValid = /^(?:(?:\+|0{0,2})54)?(?:\s?|\()?0?(\d{0,2})?(\d{8})$/;
  
    removeClassErrorSuccess(inputData);
  
    const match = numberValid.exec(num);
  
    if (match && match[1] !== undefined && match[2] !== undefined && num.length >= 8) {
      inputData.classList.add("success");
      return true;
    } else {
      inputData.classList.add("error");
      return false;
    }
}

export function phoneValidation(inputData) {
    //inputData.addEventListener('input', () => {
    const { value } = inputData;
    const num = value.replace(/\D+/g, ''); // Elimina caracteres no numéricos
    //const numberValid = /^(?:(?:\+|0{0,2})54)?(?:\s?|\()?([2368]\d)?(?:(?=\d{0,2}15)\d{2})??(\d{8})$/;
    //const numberValid = /^(?:(?:\+|0{0,2})54)?(?:\s?|\()?(\d{0,2})?(\d{8})$/;
    const numberValid = /^(?:(?:\+|0{0,2})54)?(?:\s?|\()?0?(\d{0,2})?(\d{8})$/;

    removeClassErrorSuccess(inputData);

    const resultValidation = numberValid.test(value);
    if(resultValidation && (num.length >= 8)) {
        inputData.classList.add("success");
        return true;
    } else {
        inputData.classList.add("error");
        return false;
    }
}
  
  

export function emailValidation (inputData) {
    // eslint-disable-next-line no-useless-escape
    const emailValid = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const { value } = inputData;

    removeClassErrorSuccess(inputData);

    const resultValidation = emailValid.test(value);
    if(resultValidation) {
        inputData.classList.add("success");
        return true;
    } else {
        inputData.classList.add("error");
        return false;
    }
}

export function numberValidation (inputData) {
    console.log("Ingresando a numberValidation");
    // eslint-disable-next-line no-useless-escape
    //const numberValid = /^(?=.*?[0-9]).{0,11}$/;
    const numberValid = /^([0-9])*$/;
    const { value } = inputData;

    removeClassErrorSuccess(inputData);

    const resultValidation = numberValid.test(value);
    if(resultValidation) {
        inputData.classList.add("success");
        return true;
    } else {
        inputData.classList.add("error");
        return false;
    }
}  

export function documentNumberValidation(numero) {
    // Convertir el número a cadena para contar los dígitos
    const numeroStr = numero.toString();
    
    // Verificar que tiene entre 1 y 8 dígitos
    return numeroStr.length <= 8 && /^\d+$/.test(numeroStr);
  };

export function removeClassErrorSuccess(inputData) {
    //inputData.classList.remove('success');
    //inputData.classList.remove('error');
    inputData.classList.remove('error', 'success');
}
