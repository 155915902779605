import React, { useState, useEffect } from "react";
import { Descriptions } from "antd";
import { formatDateView } from "../../../../utils/formatDate";
import { convertMovementType, convertOrigin } from "../../../../utils/convert";

import "./ViewMovementForm.scss";

export default function ViewMovementForm(props) {
  const { movement } = props;

  const [movementData, setMovementData] = useState({});

  //trae los datos del movimiento
  useEffect(() => {
    setMovementData({
      date: movement.date,
      movement_type: movement.movement_type,
      items: movement.items,
      quantity: movement.quantity,
      origin: movement.origin,
      buyBill: movement.buyBill,
      billSell: movement.billSell,
      user: movement.creatorUser,
      observation: movement.observation,
    });
  }, [movement]);

  return (
    <div className="view-movement-form">
      <ViewForm movementData={movementData} />
    </div>
  );
}

function ViewForm(props) {
  const { movementData } = props;

  return (
    <div>
      <Descriptions column={2} layout="vertical" size="small" bordered>
        <Descriptions.Item label="Fecha">
          {movementData.date ? formatDateView(movementData.date) : ""}
        </Descriptions.Item>
        <Descriptions.Item label="Tipo">
          {movementData.movement_type
            ? convertMovementType(movementData.movement_type)
            : null}
        </Descriptions.Item>
        <Descriptions.Item label="Origen" span={2}>
          {movementData.origin ? convertOrigin(movementData.origin) : ""}
        </Descriptions.Item>
        {movementData.buyBill ? (
          <Descriptions.Item label="Factura">
            {movementData.buyBill ? movementData.buyBill.code : ""}
          </Descriptions.Item>
        ) : null}

        {movementData.billSell ? (
          <Descriptions.Item label="Factura">
            {movementData.billSell ? movementData.billSell.billsell_code : ""}
          </Descriptions.Item>
        ) : null}
     {/* {movementData.items.map((item)=> 
    <>
          {item
            ? `${item._item.item_code}-${item._item.description}`
            : ""}

          {item.quantity ? item.quantity : ""}
          </>)} */} 
          //FALTA MOSTRAR LOS PRODUCTOS

        <Descriptions.Item label="Responsable" span={2}>
          {movementData.user
            // ? `${movementData.user.name} ${movementData.user.lastname}`
            // : ""}
            ? `${movementData.user.username}`
            : ""}
        </Descriptions.Item>
        <Descriptions.Item label="Observaciones" span={2}>
          {movementData.observation ? movementData.observation : ""}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
