import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import { TagOutlined } from "@ant-design/icons";
import { updateBrandApi } from "../../../../api/billing/brand";
import { getAccessToken } from "../../../../api/auth";
//import { minLengthValidation, numberValidation } from "../../../../utils/formValidation";

import "./EditBrandForm.scss";

export default function EditBrandForm(props) {
  const { brand, setIsVisibleModal, setReloadBrands } = props;
  const [brandData, setBrandData] = useState({});

  useEffect(() => {
    setBrandData({
      brand_code: brand.brand_code,
      name: brand.name,
    });
  }, [brand]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!brandData.brand_code || !brandData.name) {
      notification["error"]({
        message: "Obligatorios: Código y Nombre.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const updateBrand = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let brandUpdate = brandData; //es let porque se va actualizando

      updateBrandApi(token, brandUpdate, brand._id).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadBrands(true);
        }
      });
    }
  };

  return (
    <div className="edit-brand-form">
      <EditForm
        brandData={brandData}
        setBrandData={setBrandData}
        updateBrand={updateBrand}
      />
    </div>
  );
}

function EditForm(props) {
  const { brandData, setBrandData, updateBrand } = props;

  return (
    <Form className="form-edit" onSubmit={updateBrand}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Código"
              value={brandData.brand_code}
              onChange={(e) =>
                setBrandData({ ...brandData, brand_code: e })
              }
              min={1}
              style={{ width: "90%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">* </span> Nombre
          </label>
          <Form.Item>
            <Input
              placeholder="Nombre"
              value={brandData.name}
              onChange={(e) =>
                setBrandData({
                  ...brandData,
                  name: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateBrand}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
