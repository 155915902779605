import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getCoinTypesActiveApi } from "../../../api/billing/coinType";
import ListCoinTypes from "../../../components/Billing/CoinTypes/ListCoinTypes";


export default function DocumentTypes() {
    const [coinTypesActive, setCoinTypesActive] = useState([]);
    const [coinTypesInactive, setCoinTypesInactive] = useState([]);
    const [reloadCoinTypes, setReloadCoinTypes ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getCoinTypesActiveApi(token, true).then(response => {
            setCoinTypesActive(response.coinTypes);
        });
        getCoinTypesActiveApi(token, false).then(response => {
            setCoinTypesInactive(response.coinTypes);
        });
        setReloadCoinTypes(false);
    }, [token, reloadCoinTypes]);

    return (
        <div className="coinTypes">

            <ListCoinTypes coinTypesActive={coinTypesActive} coinTypesInactive={coinTypesInactive} setReloadCoinTypes={setReloadCoinTypes} 
                />

        </div>
    );
}