// TiendaSelector.js
import React, { useEffect, useState } from "react";
import { Select, Menu, Dropdown } from "antd";
import { useBusiness } from "../../../hooks/useBusiness";
import { getBusinessesActiveApi } from "../../../api/business";
import { getAccessToken } from "../../../api/auth";
import { GlobalOutlined } from "@ant-design/icons";
import { useBranch } from "../../../hooks/useBranch";
import "./BusinessSelector.scss";

const { Option } = Select;

export default function BusinessSelector(props) {
  const { business, changeBusiness } = useBusiness();
  const [listBusinessesActive, setListBusinessesActive] = useState([]);
  const [businessSelected, setBusinessSelected] = useState("");
  const { changeBranch } = useBranch();

  useEffect(() => {
    const accessToken = getAccessToken();
    getBusinessesActiveApi(accessToken, true).then((response) => {
      let newList = response.businesses ? response.businesses : [];
      newList.push({ _id: "0", name: "todos" });
      setListBusinessesActive(newList);
      const selected = newList.find((s) => s._id === business);
      setBusinessSelected(selected ? selected.name : "");
    });
  }, []);

  const handleChange = (value) => {
    changeBusiness(value.key);
    changeBranch("0");
  };

  const menu = (
    <Menu onClick={handleChange}>
      {listBusinessesActive.map((sucursal) => (
        <Menu.Item key={sucursal._id}>{sucursal.name}</Menu.Item>
      ))}
    </Menu>
  );

  const spanStyle = {
    color: "black",
    width: "150px",
    textAlign: "left",
  };

  return (
    <div>
      <Dropdown.Button
        className="ant-dropdown-button"
        overlay={menu}
        //trigger={['click']}
      >
        <span style={spanStyle}>
          {" "}
          <GlobalOutlined />{" "}
          {businessSelected !== "" && businessSelected !== "todos"
            ? businessSelected
            : "Negocios"}
        </span>
      </Dropdown.Button>
    </div>
  );
}
