import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  notification,
  Table,
  Divider,
  Popconfirm,
  Typography,
  Modal,
  Statistic,
  Card,
} from "antd";
import {
  TagOutlined,
  PlusOutlined,
  CloseOutlined,
  SaveOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  addBillSellApi,
  getBillSellsActiveApi,
} from "../../../../api/billing/billsell";
import { getClientsActiveApi } from "../../../../api/billing/client";
import {
  getSalePointsBySellerApi,
  getSalePointsActiveApi,
} from "../../../../api/billing/salePoint";
import { getStateBillsActiveApi } from "../../../../api/billing/stateBill";
import { getItemsActiveApi } from "../../../../api/item";
import { getAccessToken } from "../../../../api/auth";
import {
  updateAddStockInventoryApi,
  updateSubStockInventoryApi,
  getStockInventoriesApi,
  getStockInventoryApi,
} from "../../../../api/billing/stockInventory";
import { addMovementApi } from "../../../../api/billing/movement";
import { getBillTypesActiveApi } from "../../../../api/billing/billType";
import jwtDecode from "jwt-decode";

import "./AddBillSellForm.scss";
// import TextArea from "antd/lib/input/TextArea";
import { formatDateCalendar } from "../../../../utils/formatDate";
import moment from "moment";

const { Text } = Typography;

export default function AddBillSellForm(props) {
  const { associatedBillSell, setIsVisibleModal, setReloadBillSells } = props;

  const [activeClients, setActiveClients] = useState([]);
  const [activeStateBills, setActiveStateBills] = useState([]);
  const [activeItems, setActiveItems] = useState([]);
  const [listStockInventory, setListStockInventory] = useState([]);
  const [activeBillTypes, setActiveBillTypes] = useState([]);
  const [activeBillSells, setActiveBillSells] = useState([]);
  const [idBillSell, setIdBillSell] = useState("");
  const [cancelledBill, setCancelledBill] = useState(false);
  const [billsData, setBillsData] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [activeTypes, setActiveTypes] = useState([]);

  const accessToken = getAccessToken();
  const metaToken = jwtDecode(accessToken); //para obtener los datos del user logueado
  const [inputs, setInputs] = useState({
    code: "",
    billType: "",
    client:"",
    address:"",
    saleCondition: "",
    date: formatDateCalendar(moment()),
    deliveryDate:"",
    subtotal: "",
    total: 0,
    discount: 0,
    iva: "",
    state: "",
    items: [],
    item: "",
    quantity: 1,
    unitValue: "",
    paid: false,
    active: true,
  });

  useEffect(() => {
    getClientsActiveApi(accessToken, true).then((response) => {
      setActiveClients(response.clients);
    });
  }, []);

  useEffect(() => {
    getStateBillsActiveApi(accessToken, true).then((response) => {
      setActiveStateBills(response.stateBills);
    });
  }, []);

  useEffect(() => {
    getItemsActiveApi(accessToken, true).then((response) => {
      setActiveItems(response.items);
    });
  }, []);

  useEffect(() => {
    getStockInventoriesApi(accessToken).then((response) => {
      setListStockInventory(response.stockInventories);
    });
  }, []);

  useEffect(() => {
    getBillTypesActiveApi(accessToken, true).then((response) => {
      setActiveBillTypes(response.billTypes);
    });
  }, []);

  useEffect(() => {
    getBillSellsActiveApi(accessToken, true).then((response) => {
      setActiveBillSells(response.billsells);
    });
  }, []);

  useEffect(() => {
    getBillTypesActiveApi(accessToken, true).then((response) => {
      setActiveTypes(response.types);
    });
  }, []);

  useEffect(() => {
    //verifico que exista el id de la factura de compra
    if (idBillSell !== "") {
      //verifico que no sea anulacion de factura
      if (!cancelledBill) {
        //Por cada item verifico que tenga inventario, en caso afirmativo actualizo stock y creo un movimiento
        inputs.items.map((item) => {
          let inventory = listStockInventory
            ? listStockInventory.find((i) => i.item._id === item._item)
            : false;
          if (inventory) {
            let data = {
              quantity: item.quantity,
            };

            subStock(accessToken, inventory._id, data, item);
          }
          return true;
        });
      } else if (
        inputs.cancellationReason === "refund" ||
        inputs.cancellationReason === "cancelled"
      ) {
        //Verifico el nuevo detalle de items
        inputs.items.map((item) => {
          //primero verifico si tiene inventario
          let inventory = listStockInventory.find(
            (i) => i.item._id === item._item || i.item._id === item._item._id
          );
          if (inventory) {
            let data = {
              quantity: item.quantity,
            };
            addStock(accessToken, inventory._id, data, item);
          }
          return true;
        });
      }
    }
  }, [idBillSell]);

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (
      !inputs.billType ||
      !inputs.date ||
      !inputs.client 
    ) {
      notification["error"]({
        message:
          "Obligatorios: Tipo de comprobante, Cliente y Fecha.",
      });
      errorExists = true;
    }
    if (
      inputs.items.length === 0
    ) {
      notification["error"]({
        message:
          "Obligatorio: Debe seleccionar al menos un producto.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addBillSell = async (e) => {
    e.preventDefault();
   
    if (!isFormValid()) {
      
      // setButtonLoading(true);

      const accessToken = getAccessToken();
      await addBillSellApi(accessToken, inputs, metaToken._id)
        .then((response) => {
          //console.log(response);
          if (
            response === "ERR_CONNECTION_REFUSED" ||
            response === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: "Factura de venta creada",
            });
            setIsVisibleModal(false);
            setIdBillSell(response.billSell._id);
          }
          setReloadBillSells(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  const addStock = (accessToken, inventoryId, data, item) => {
    updateAddStockInventoryApi(accessToken, inventoryId, data)
      .then((response) => {
        if (
          response.message === "ERR_CONNECTION_REFUSED" ||
          response.message === "Failed to fetch" ||
          response === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          return false;
        } else if (response.code !== 200) {
          notification["error"]({
            message: response.message,
          });
          return false;
        } else {
          // notification["success"]({
          //   message: "Stock actualizado",
          // });
          let movementData = {
            date: formatDateCalendar(moment()),
            movement_type: "input",
            item: item._item,
            origin: "billSell",
            billSell: idBillSell,
            quantity: data.quantity,
            user: jwtDecode(accessToken).id,
          };
          return addMovementApi(accessToken, movementData);
        }
      })
      .then((response) => {
        if (response) {
          if (
            response.message === "ERR_CONNECTION_REFUSED" ||
            response.message === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            // notification["success"]({
            //   message: "Movimiento creado",
            // });
          }
        }
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const subStock = (accessToken, inventoryId, data, item) => {
    updateSubStockInventoryApi(accessToken, inventoryId, data)
      .then((response) => {
        if (
          response.message === "ERR_CONNECTION_REFUSED" ||
          response.message === "Failed to fetch" ||
          response === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          return false;
        } else if (response.code !== 200) {
          notification["error"]({
            message: response.message,
          });
          return false;
        } else {
          // notification["success"]({
          //   message: "Stock actualizado",
          // });
          let movementData = {
            date: formatDateCalendar(moment()),
            movement_type: "output",
            item: item._item,
            origin: "billSell",
            billSell: idBillSell,
            quantity: data.quantity,
            user: jwtDecode(accessToken).id,
            observation: "comprobante modificado",
          };
          return addMovementApi(accessToken, movementData);
        }
      })
      .then((response) => {
        if (response) {
          if (
            response.message === "ERR_CONNECTION_REFUSED" ||
            response.message === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            // notification["success"]({
            //   message: "Movimiento creado",
            // });
          }
        }
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  return (
    <div className="add-billsell-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        addBillSell={addBillSell}
        activeClients={activeClients}
        activeStateBills={activeStateBills}
        accessToken={accessToken}
        activeItems={activeItems}
        activeBillTypes={activeBillTypes}
        activeBillSells={activeBillSells}
        activeTypes={activeTypes}
        // itemsData={itemsData}
        // setItemsData={setItemsData}
        billsData={billsData}
        setBillsData={setBillsData}
        cancelledBill={cancelledBill}
        setCancelledBill={setCancelledBill}
        listStockInventory={listStockInventory}
        buttonLoading={buttonLoading}
      />
    </div>
  );
}

function AddForm(props) {
  const {
    inputs,
    setInputs,
    addBillSell,
    activeClients,
    // activeSalePoints,
    // activeSaleConditions,
    accessToken,
    // activeSellers,
    // activeStateBills,
    // activeConcepts,
    activeItems,
    activeBillTypes,
    // activePaymentTypes,
    // activeBillSells,
    // itemsData,
    // setItemsData,
    // billsData,
    // setBillsData,
    // cancelledBill,
    // setCancelledBill,
    listStockInventory,
    buttonLoading
  } = props;

  const { Option } = Select;
  const [activeSellerSalePoints, setActiveSellerSalePoints] = useState([]);
  const [isItem, setIsItem]=useState(false);

  useEffect(() => {
    inputs.salePoint = "";
    if (inputs.seller) {
      getSalePointsBySellerApi(accessToken, inputs.seller).then((response) => {
        setActiveSellerSalePoints(response.salePoints);
      });
    }
  }, [inputs.seller]);

  useEffect(() => {
    if (inputs.client) {
      let client = activeClients.find((i) => i._id === inputs.client);
      setInputs({...inputs, address:client.person.address});
    }
  }, [inputs.client]);

  const setListItems = (items) =>{
    setInputs({...inputs, items: items})
}

  const addItems = async () => {
    if (inputs.item && inputs.quantity) {
      //Verifico si el item no esta en el detalle
      let itemExistInDetail = inputs.items.find((i) => i._item === inputs.item);
      if (!itemExistInDetail) {
        //verifico si tiene stock
        let resultVerifyStock = await verifyStock(inputs.item, inputs.quantity);
        if (resultVerifyStock) {
          let item = activeItems.filter((i) => i._id === inputs.item)[0];
          let description = item.description;
          let item_code = item.item_code;

          const newData = {
            _item: inputs.item,
            item_code: item_code,
            description: description,
            unitValue: inputs.unitValue,
            quantity: inputs.quantity
          };
          // setItemsData([...itemsData, newData]);
          setInputs({ ...inputs, items: [...inputs.items,newData]});
        }
      } else {
        notification["error"]({
          message: "El producto ya se encuentra en el detalle de la factura",
        });
      }
    } else {
      notification["error"]({
        message:
          "Debe elegir al menos un producto e ingresar la cantidad requerida",
      });
    }
  };



  const verifyStock = async (item, quantity) => {
    //busco el item en el listado de inventarios
    let inventory = listStockInventory
      ? listStockInventory.find((i) => i.item._id === item)
      : false;
    //si existe inventario realizo la consulta de stock
    if (inventory) {
      //consulto su stock actual en la BD
      let accessToken = getAccessToken();
      let inventoryActual = await getStockInventoryApi(
        accessToken,
        inventory._id
      );
      // console.log("inventoryActual")
      // console.log(inventoryActual)
      if (inventoryActual.stockInventory) {
        //verifico que la cantidad se encuentre dentro del stock
        if (0 <= inventoryActual.stockInventory[0].stock - quantity) {
          return true;
        } else {
          Modal.error({
            title: ` Producto ${inventoryActual.stockInventory[0].item.item_code} - ${inventoryActual.stockInventory[0].item.description}`,
            content: (
              <div>
                <p>
                  No hay stock suficiente del producto seleccionado para la
                  cantidad solicitada
                </p>
                <Card>
                  <Statistic
                    title={"Stock Actual"}
                    valueStyle={{ color: "#3d546d", align: "cenyter" }}
                    value={inventoryActual.stockInventory[0].stock}
                  />
                </Card>
              </div>
            ),
            onOk() {},
          });
          return false;
        }
      } else {
        notification["error"]({
          message:
            "Error al consultar el stock actual del producto, intente nuevamente",
        });
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    let totals = 0;
    inputs.items.forEach(({ unitValue, quantity }) => {
      totals += unitValue * quantity;
    });

    setInputs({ ...inputs, total: totals });
  }, [inputs.items]);


  return (
    <Form className="form-add" size="middle" onSubmit={addBillSell}>
      {/* <Divider orientation="left">Datos</Divider> */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              name="code"
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.code}
              onChange={(e) => setInputs({ ...inputs, code: e.target.value })}
              disabled={true}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Tipo comprobante
          </label>
          <Form.Item rules={[{ required: true }]}>
            <Select
              showSearch
              name="billType"
              value={inputs.billType}
              onChange={(e) => setInputs({ ...inputs, billType: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activeBillTypes
                ? activeBillTypes.map((i) => {
                    return <Option key={i.description}>{i.description}</Option>;
                  })
                : "No tipo de comprobantes"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Cliente
          </label>
          <Form.Item>
            <Select
              showSearch
              name="client"
              value={inputs.client}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  client: e,
                })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activeClients
                ? activeClients.map((i) => {
                    return <Option key={i._id}>{i.denomination}</Option>;
                  })
                : "No hay clientes disponibles"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
             Direccion
          </label>
          <Form.Item>
            <Input
              name="address"
              value={inputs.address}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  address: e,
                })
              }
            />
             
          </Form.Item>
        </Col> 
        {/* <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Vendedor
          </label>
          <Form.Item>
            <Select
              showSearch
              name="seller"
              value={inputs.seller}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  seller: e,
                  salePoint: "",
                })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activeSellers
                ? activeSellers.map((i) => {
                    return <Option key={i._id}>{i.name}</Option>;
                  })
                : "No hay vendedores disponibles"}
            </Select>
          </Form.Item>
        </Col> */}
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Fecha Emision
          </label>
          <Form.Item rules={[{ required: true }]}>
            <Input
              type="date"
              placeholder="Fecha"
              value={inputs.date}
              onChange={(e) => setInputs({ ...inputs, date: e.target.value })}
              required
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            Fecha Entrega
          </label>
          <Form.Item rules={[{ required: true }]}>
            <Input
              type="date"
              name="deliveryDate"
              placeholder="Fecha de entrega"
              value={inputs.deliveryDate}
              onChange={(e) =>
                setInputs({ ...inputs, deliveryDate: e.target.value })
              }
              required
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Período desde</label>
          <Form.Item rules={[{ required: true }]}>
            <Input
              type="date"
              name="periodFrom"
              placeholder="desde"
              value={inputs.periodFrom}
              onChange={(e) =>
                setInputs({ ...inputs, periodFrom: e.target.value })
              }
              required
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Hasta</label>
          <Form.Item rules={[{ required: true }]}>
            <Input
              type="date"
              placeholder="hasta"
              value={inputs.periodUntil}
              onChange={(e) =>
                setInputs({ ...inputs, periodUntil: e.target.value })
              }
              required
            />
          </Form.Item>
        </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">Fecha Vencimiento</label>
          <Form.Item rules={[{ required: true }]}>
            <Input
              type="date"
              name="expirationDate"
              placeholder="Vencimiento"
              value={inputs.expirationDate}
              onChange={(e) =>
                setInputs({ ...inputs, expirationDate: e.target.value })
              }
              required
            />
          </Form.Item>
        </Col>
      </Row>
      {/* {cancelledBill ? (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <label className="control-label">
              <span className="control-required">*</span> Motivo de cancelación
            </label>
            <Form.Item>
              <Select
                name="cancellationReason"
                value={inputs.cancellationReason}
                onChange={(e) =>
                  setInputs({ ...inputs, cancellationReason: e })
                }
                required
              >
                <Option value="adjustment">Ajuste pago</Option>
                <Option value="refund">Devolución</Option>
                <Option value="cancelled">Anulación total</Option>
                <Option value="discount">Ajuste Descuento</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      ) : null} */}
      <Divider orientation="left" style={{margin:5}} ></Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Producto/Servicio
          </label>
          <Form.Item>
            <Select
              showSearch
              name="items"
              placeholder="Seleccione un producto/servicio"
              value={inputs.item}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  item: e,
                })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activeItems
                ? activeItems.map((i) => {
                    return <Option key={i._id}>{i.description}</Option>;
                  })
                : "No hay productos disponibles"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Valor Unitario</label>
          <Form.Item>
            <Input
              name="unitValue"
              value={inputs.unitValue}
              onChange={(e) =>
                setInputs({ ...inputs, unitValue: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Cantidad</label>
          <Form.Item>
            <InputNumber
              placeholder="Cantidad"
              value={inputs.quantity}
              onChange={(e) => setInputs({ ...inputs, quantity: e })}
              min={1}
              max={1000000}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <Button type="primary" className="button-add" size="middle" onClick={addItems}>
              <PlusOutlined />  Agregar
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          {/* {itemsData.length > 0 ? ( */}
            <Items
              itemsData={inputs.items}
              setItemsData={setListItems}
              verifyStock={verifyStock}
            />
          {/* ) : null} */}
        </Col>
      </Row>
      <Divider></Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Total
          </label>
          <Form.Item>
            <Input
              type="text"
              disabled={true}
              value={inputs.total}
              // onChange={(e) => setInputs({ ...inputs, total: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            {/* {itemsData.length > 0 ? (
              <Total hidden="true" itemsData={itemsData} totalSum={totalSum} />
            ) : null} */}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addBillSell}
          // loading={buttonLoading}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}

function Items(props) {
  const { itemsData, setItemsData, verifyStock } = props;
  //console.log(itemsData);

  const columns = [
    {
      title: "Código",
      dataIndex: "item_code",
      render: (_item, row) =>
        row.item_code ? row.item_code : row._item.item_code,
      width: "15%",
      editable: false,
    },
    {
      title: "Producto",
      dataIndex: "description",
      render: (_item, row) =>
        row.description ? row.description : row._item.description,
      width: "25%",
      editable: false,
    },
    {
      title: "Valor Unitario",
      dataIndex: "unitValue",
      width: "20%",
      editable: true,
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      width: "15%",
      editable: true,
    },
    {
      title: "Total",
      // dataIndex:"quantity",
      render: (_item, row) =>
             row.unitValue ? row.unitValue * row.quantity : 0,
      width: "10%",
      editable: false,
    },
  ];
  return (
    <EditableItemTable
      columnsData={columns}
      data={itemsData}
      setData={setItemsData}
      rowKey="_item"
      verifyStock={verifyStock}
    />
  );
}


function EditableItemTable(props) {
  const { data, setData, columnsData, rowKey, verifyStock } = props;
  const [form] = Form.useForm();
  // const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Por favor ingrese ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record) => record._item === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      unitValue: "",
      quantity: "",
      ...record,
    });
    setEditingKey(record._item);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item._item);

      if (index > -1) {
        const item = newData[index];
        let resultVerifyStock = await verifyStock(item._item, row.quantity);
        if (resultVerifyStock) {
          newData.splice(index, 1, { ...item, ...row });
          setData(newData);
          setEditingKey("");
        }
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const showDeleteConfirm = (record) => {
    const d = data.filter((i) => i !== record);
    if (d.length === 0) {
      setData([]);
    } else {
      setData(d);
    }
  };

  const columns = [
    ...columnsData,
    {
      title: "Acción",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="primary"
              size="small"
              onClick={() => save(record._item)}
            >
              <SaveOutlined />
            </Button>
            <Popconfirm title="Está seguro de cancelar?" onConfirm={cancel}>
              <Button type="danger" size="small">
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Button
              type="default"
              size="small"
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <EditOutlined />
            </Button>
            <Button
              className="button-item"
              type="danger"
              size="small"
              onClick={(e) => showDeleteConfirm(record)}
            >
              <DeleteOutlined />
            </Button>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "quantity" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        size="small"
        style={{ margin:20 }}
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        rowKey={rowKey}
        scroll={{y:150}}
        summary={(pageData) => {
          let total = 0;

          pageData.forEach(({ unitValue, quantity }) => {
            total += unitValue * quantity;
          });

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell>Total</Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text type="danger">{total}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </Form>
  );
}
