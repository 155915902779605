import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import { TagOutlined } from "@ant-design/icons";
import { updateCategoryApi } from "../../../../api/billing/category";
import { getAccessToken } from "../../../../api/auth";
//import { minLengthValidation, numberValidation } from "../../../../utils/formValidation";

import "./EditCategoryForm.scss";

export default function EditCategoryForm(props) {
  const { category, setIsVisibleModal, setReloadCategories } = props;
  const [categoryData, setCategoryData] = useState({});

  useEffect(() => {
    setCategoryData({
      category_code: category.category_code,
      description: category.description,
    });
  }, [category]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!categoryData.category_code || !categoryData.description) {
      notification["error"]({
        message: "Obligatorios: Código y Descripción.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const updateCategory = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let categoryUpdate = categoryData; //es let porque se va actualizando

      updateCategoryApi(token, categoryUpdate, category._id).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadCategories(true);
        }
      });
    }
  };

  return (
    <div className="edit-category-form">
      <EditForm
        categoryData={categoryData}
        setCategoryData={setCategoryData}
        updateCategory={updateCategory}
      />
    </div>
  );
}

function EditForm(props) {
  const { categoryData, setCategoryData, updateCategory } = props;

  return (
    <Form className="form-edit" onSubmit={updateCategory}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Código"
              value={categoryData.category_code}
              onChange={(e) =>
                setCategoryData({ ...categoryData, category_code: e })
              }
              min={1}
              style={{ width: "90%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">* </span>Descripción
          </label>
          <Form.Item>
            <Input
              placeholder="Descripción"
              value={categoryData.description}
              onChange={(e) =>
                setCategoryData({
                  ...categoryData,
                  description: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateCategory}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
