import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  notification,
  Divider,
  List,
  Modal,
  Popconfirm,
  Switch,
} from "antd";
import {
  EditFilled,
  DeleteFilled,
  PlusOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import {
  addSupplierApi,
  addSupplierApi2,
} from "../../../../api/billing/supplier";
import { getAccessToken } from "../../../../api/auth";
import { getPersonsActiveApi } from "../../../../api/person";
import { getSupplierTypesActiveApi } from "../../../../api/billing/supplierType";
import { getIvaConditionsActiveApi } from "../../../../api/billing/ivaCondition";

import "./AddSupplierForm.scss";

const { Option } = Select;

export default function AddSupplierForm(props) {
  const { setIsVisibleModal, setReloadSuppliers } = props;

  const [listPersonsActive, setListPersonsActive] = useState([]);
  const [listSupplierTypesActive, setListSupplierTypesActive] = useState([]);

  useEffect(() => {
    const accessToken = getAccessToken();
    getPersonsActiveApi(accessToken, true).then((response) => {
      setListPersonsActive(response.persons);
    });
  }, []);

  useEffect(() => {
    const accessToken = getAccessToken();
    getSupplierTypesActiveApi(accessToken, true).then((response) => {
      setListSupplierTypesActive(response.supplierTypes);
    });
  }, []);

  const [inputs, setInputs] = useState({
    person: "",
    denomination: "",
    taxPosition: "",
  });

  const isFormValid = (e) => {
    let errorExists = false;
    if (!inputs.person || !inputs.denomination) {
      notification["error"]({
        message: "Obligatorios: persona y denominacion",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addSupplier = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();

      await addSupplierApi(accessToken, inputs)
        .then((response) => {
          // if(response.message === "ERR_CONNECTION_REFUSED" || response === "Failed to fetch" || response === undefined) {
          //     notification["error"]({
          //         message: "Servidor caido"
          //     });
          // } else if (response.message === "El proveedor ya existe") {
          //     notification["error"]({
          //         message: "El proveedor ya existe."
          //     });
          if (
            response.message === "ERR_CONNECTION_REFUSED" ||
            response.message === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (!response.supplier) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              //message: response
              message: "Proveedor creado",
            });
            setIsVisibleModal(false);
          }
          console.log(response);
          setReloadSuppliers(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-supplier-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        addSupplier={addSupplier}
        listSupplierTypesActive={listSupplierTypesActive}
        listPersonsActive={listPersonsActive}
      />
    </div>
  );
}

function AddForm(props) {
  const {
    form,
    inputs,
    setInputs,
    addSupplier,
    listPersonsActive,
    listSupplierTypesActive,
  } = props;
  const { Option } = Select;

  const [bankDetails, setBankDetails] = useState([]);
  const [reloadBankDetails, setReloadBankDetails] = useState(false);

  useEffect(() => {
    if (reloadBankDetails) {
      setInputs({ ...inputs, bankDetails: bankDetails });
      setReloadBankDetails(false);
    }
  }, [reloadBankDetails]);

  return (
    <Form className="form-add" onSubmit={addSupplier}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            <span className="control-required">*</span>Persona
          </label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona una persona"
              value={inputs.person}
              onChange={(e) => setInputs({ ...inputs, person: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listPersonsActive
                ? listPersonsActive.map((person) => {
                    return (
                      <Option key={person._id}>
                        {person.name + " " + person.lastname}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            <span className="control-required">*</span> Denominacion
          </label>
          <Form.Item>
            <Input
              // prefix={<EnvironmentOutlined />}
              placeholder="Denominacion"
              value={inputs.denomination}
              onChange={(e) =>
                setInputs({ ...inputs, denomination: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">Posicion de impuestos</label>
          <Form.Item>
            <Input
              name="taxPosition"
              placeholder="Posicion de impuestos"
              onChange={(e) =>
                setInputs({ ...inputs, taxPosition: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left">Datos Bancarios</Divider>
      <ListBankDetails
        bankDetails={bankDetails}
        setBankDetails={setBankDetails}
        setReloadBankDetails={setReloadBankDetails}
      />
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addSupplier}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}

function ListBankDetails(props) {
  const { bankDetails, setReloadBankDetails, setBankDetails } = props;

  const [showSelect, setShowSelect] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const handleEdit = (detail) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar ${detail.bank ? detail.bank : "sin nombre"}`);
    setModalContent(
      <EditDetails
        bankDetail={detail}
        setIsVisibleModal={setIsVisibleModal}
        setShowSelect={setShowSelect}
        setReloadBankDetails={setReloadBankDetails}
        handleEditConfirm={handleEditConfirm}
      />
    );
  };

  const handleAddDetails = () => {
    setIsVisibleModal(true);
    setModalTitle(`Nuevo Dato Bancario`);
    setModalContent(
      <AddDetails
        //variationCategories={variationCategories}
        addVariationCategory={handleAdd}
        handleClick={handleClick}
        setShowSelect={setShowSelect}
        setIsVisibleModal={setIsVisibleModal}
      />
    );
  };
  // Función para eliminar un elemento
  const handleDelete = (index) => {
    let newArry = bankDetails.filter((_, i) => i !== index);
    setBankDetails(newArry);
    setReloadBankDetails(true);
  };

  const handleClick = () => {
    setShowSelect(!showSelect); // Alterna la visibilidad del Select
  };

  const handleAdd = (data) => {
    if (data.isMain === true) {
      bankDetails.forEach((cuenta, index) => {
        cuenta.isMain = false; // Cambiar todas las demás cuentas a false
      });
    }
    setBankDetails([...bankDetails, data]);
    setReloadBankDetails(true);
  };

  const handleEditConfirm = (data) => {
    if (data.isMain === true) {
      bankDetails.forEach((cuenta, index) => {
        if (
          (cuenta.cbu && cuenta.cbu !== data.cbu) ||
          (cuenta.alias && cuenta.alias !== data.alias)
        ) {
          cuenta.isMain = false; // Cambiar todas las demás cuentas a false
        }
      });
    }
    setBankDetails([...bankDetails]);
    setReloadBankDetails(true);
  };

  return (
    <div>
      {bankDetails.length > 0
        ? bankDetails.map((bank, index) => {
            return (
              <List.Item
                actions={[
                  <>
                    <Button
                      shape="circle"
                      onClick={() => handleEdit(bank, index)}
                    >
                      <EditFilled />
                    </Button>
                    <Divider type="vertical" />
                    <Popconfirm
                      title="¿Está seguro de eliminar el dato bancario?"
                      onConfirm={() => handleDelete(index)}
                      okText="Si"
                      cancelText="No"
                    >
                      <Button shape="circle">
                        <DeleteFilled color="red" />
                      </Button>
                    </Popconfirm>
                  </>,
                ]}
              >
                <Divider type="vertical" />
                <List.Item.Meta
                  title={bank.bank}
                  description={
                    <div style={{ color: "black" }}>{bank.holder}</div>
                  }
                />
              </List.Item>
            );
          })
        : null}
      {showSelect ? (
        <></>
      ) : (
        <Button
          type="dashed"
          onClick={handleAddDetails}
          style={{ marginBottom: 30, marginTop: 10, marginLeft: 30 }}
        >
          <PlusOutlined /> Agregar nueva cuenta
        </Button>
      )}
      <Modal
        title={modalTitle}
        visible={isVisibleModal}
        footer={null}
        //width={800}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function AddDetails(props) {
  const { addVariationCategory, setShowSelect, setIsVisibleModal } = props;

  const [data, setData] = useState({ isMain: false });

  const handleConfirmAdd = () => {
    if (data && data.bank && data.holder && (data.cbu || data.alias)) {
      addVariationCategory(data);
      setShowSelect(false);
      setIsVisibleModal(false);
      setData({ isMain: false });
    } else {
      notification["error"]({
        message: "Datos Obligatorios: Banco, Titular, CBU y/o Alias",
      });
    }
  };

  return (
    <div>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Banco
          </label>
          <Form.Item>
            <Input
              value={data.bank}
              onChange={(e) => setData({ ...data, bank: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Titular
          </label>
          <Form.Item>
            <Input
              value={data.holder}
              onChange={(e) => setData({ ...data, holder: e.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">CBU</label>
          <Form.Item>
            <Input
              value={data.cbu}
              onChange={(e) => setData({ ...data, cbu: e.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Alias</label>
          <Form.Item>
            <Input
              value={data.alias}
              onChange={(e) => setData({ ...data, alias: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Principal</label>
          <Form.Item>
            <Switch
              defaultUnchecked
              checked={data.isMain}
              onChange={(e) => setData({ ...data, isMain: e })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Button type="primary" onClick={handleConfirmAdd}>
            <CheckOutlined /> Guardar
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type="primary"
            onClick={() => {
              setIsVisibleModal(false);
            }}
            danger
          >
            <CloseOutlined /> Cancelar
          </Button>
        </Col>
      </Row>
    </div>
  );
}

function EditDetails(props) {
  const {
    bankDetail,
    setShowSelect,
    setIsVisibleModal,
    setReloadBankDetails,
    handleEditConfirm,
  } = props;

  const [data, setData] = useState({});

  useEffect(() => {
    setData({
      bank: bankDetail.bank,
      cbu: bankDetail.cbu,
      holder: bankDetail.holder,
      alias: bankDetail.alias,
      isMain: bankDetail.isMain,
    });
  }, [bankDetail]);

  const handleEdit = () => {
    bankDetail.bank = data.bank;
    bankDetail.cbu = data.cbu;
    bankDetail.holder = data.holder;
    bankDetail.alias = data.alias;
    bankDetail.isMain = data.isMain;

    handleEditConfirm(bankDetail);
    setShowSelect(false);
    setIsVisibleModal(false);
  };

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Banco
          </label>
          <Form.Item>
            <Input
              value={data.bank}
              onChange={(e) => setData({ ...data, bank: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Titular
          </label>
          <Form.Item>
            <Input
              value={data.holder}
              onChange={(e) => setData({ ...data, holder: e.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">CBU</label>
          <Form.Item>
            <Input
              value={data.cbu}
              onChange={(e) => setData({ ...data, cbu: e.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Alias</label>
          <Form.Item>
            <Input
              value={data.alias}
              onChange={(e) => setData({ ...data, alias: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Principal</label>
          <Form.Item>
            <Switch
              checked={data.isMain === false ? false : true}
              onChange={(e) => setData({ ...data, isMain: e })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Button type="primary" onClick={handleEdit}>
            <CheckOutlined /> Guardar
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type="primary"
            onClick={() => {
              setIsVisibleModal(false);
            }}
            danger
          >
            <CloseOutlined /> Cancelar
          </Button>
        </Col>
      </Row>
    </>
  );
}
