import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getUnitsActiveApi } from "../../../api/billing/unit";
import ListUnits from "../../../components/Billing/Units/ListUnits";

export default function Units() {
    const [unitsActive, setUnitsActive] = useState([]);
    const [unitsInactive, setUnitsInactive] = useState([]);
    const [reloadUnits, setReloadUnits ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getUnitsActiveApi(token, true).then(response => {
            setUnitsActive(response.units);
        });
        getUnitsActiveApi(token, false).then(response => {
            setUnitsInactive(response.units);
        });
        setReloadUnits(false);
    }, [token, reloadUnits]);

    return (
        <div className="units">

            <ListUnits unitsActive={unitsActive} unitsInactive={unitsInactive} setReloadUnits={setReloadUnits} 
                />
        
        
        </div>
    );
}