import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Button, Row, Col, 
  notification,
  Select,
  Checkbox,
  Table,
  Popconfirm,
  Divider,
  Switch
 } from "antd";
import {
  TagOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  MenuOutlined,
  DeleteFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { updateVariationCategoryApi } from "../../../../api/variationCategory";
import { getAccessToken } from "../../../../api/auth";
import arrayMove from "array-move";
import { getVariationsActiveApi } from "../../../../api/variation";

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";

import "./EditVariationCategoryForm.scss";

const { Option } = Select;

export default function EditVariationCategoryForm(props) {
  const { id, variationCategory, setIsVisibleModal, setData, setReloadVariationCategories } = props;
  const [variationCategoryData, setVariationCategoryData] = useState({});
  const [variationsActive, setVariationsActive] = useState([]);
  const [variations, setVariations]=useState([]);

  useEffect(() => {
    if(variationCategory.variations){
      setVariations([...variationCategory.variations]);
    }
  }, [variationCategory]);

  console.log(variationCategory)

  useEffect(() => {
    setVariationCategoryData({
      // variation_code: variationCategory.variation_code,
      name: variationCategory.name,
      isRequired:variationCategory.isRequired,
      selectMultiple:{
        value:variationCategory.selectMultiple? variationCategory.selectMultiple.value === true? true : false : false,
        valueMin:variationCategory.selectMultiple? variationCategory.selectMultiple.valueMin? variationCategory.selectMultiple.valueMin : 0 : 0,
        valueMax:variationCategory.selectMultiple? variationCategory.selectMultiple.valueMax? variationCategory.selectMultiple.valueMax : 0 : 0,
      },
      variations:variationCategory.variations,
      order:variationCategory.order? variationCategory.order : null,
      active:variationCategory.active
    });
  }, [variationCategory]);

  useEffect(() => {
    const accessToken = getAccessToken();
    getVariationsActiveApi(accessToken, true).then((response) => {
      setVariationsActive(response.variations);
    });
  }, []);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!variationCategoryData.name) {
      notification["error"]({
        message: "Obligatorio: name.",
      });
      errorExists = true;
    } else {
      errorExists = false;
    }
    return errorExists;
  };

  const updateVariationCategory = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let variationUpdate = variationCategoryData; //es let porque se va actualizando
      variationUpdate.variations=variations;
      // updateVariationCategoryApi(token, variationUpdate, variationCategory._id).then(
      //   (result) => {
      //     if (
      //       result.message === "ERR_CONNECTION_REFUSED" ||
      //       result.message === "Failed to fetch" ||
      //       result.message === undefined
      //     ) {
      //       notification["error"]({
      //         message: "Servidor caido",
      //       });
      //       setIsVisibleModal(true);
      //     } else if (result.code !== 200) {
      //       notification["error"]({
      //         message: result.message,
      //       });
      //       setIsVisibleModal(true);
      //     } else {
      //       notification["success"]({
      //         message: result.message, //el mensaje que viene del server
      //       });
      //       //console.log("respuesta: " + result.message);
      //       setIsVisibleModal(false);
      //       setReloadVariationCategories(true);
      //     }
      //   }
      // );
      setData(id,variationUpdate);
      setIsVisibleModal(false);
      //setReloadVariationCategories(true);
    }
  };

  return (
    <div className="edit-variation-category-form">
      <EditForm
        variationCategoryData={variationCategoryData}
        setVariationCategoryData={setVariationCategoryData}
        updateVariationCategory={updateVariationCategory}
        variationsActive={variationsActive}
        variations={variations}
        setVariations={setVariations}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    variationCategoryData,
    setVariationCategoryData,
    updateVariationCategory,
    variationsActive,
    variations,
    setVariations
  } = props;


  const changeSelectMultiple=(index, e)=>{
    let newData= {...variationCategoryData.selectMultiple};
    newData[index]=e.target.value
    setVariationCategoryData({ ...variationCategoryData, selectMultiple: newData })
  }

  return (
    <Form className="form-edit" onSubmit={updateVariationCategory}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            <span className="control-required">*</span> Nombre de la categoria
          </label>
          <Form.Item>
            <Input
              value={variationCategoryData.name? variationCategoryData.name : ""}
              onChange={(e) => setVariationCategoryData({ ...variationCategoryData, name: e.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left" orientationMargin="0">
      Editar categoria de topping
    </Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          Seleccionar la condición
        </Col>
        <Col span={12}>
          <Form.Item>
            <Checkbox
              checked={variationCategoryData.isRequired === true}
              onChange={(e) =>
                setVariationCategoryData({ ...variationCategoryData, isRequired: e.target.value })
              }
              value={true}
            >
              Obligatorio
            </Checkbox>

            <Checkbox
              checked={variationCategoryData.isRequired === false}
              onChange={(e) =>
                setVariationCategoryData({ ...variationCategoryData, isRequired: e.target.value })
              }
              value={false}
            >
              Opcional
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
            En esta categoria se pueden seleccionar
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Form.Item>
            <Checkbox
              checked={variationCategoryData.selectMultiple?variationCategoryData.selectMultiple.value === false? true: false : false}
              onChange={(e) =>
                changeSelectMultiple("value", e)
              }
              value={false}
            >
              Solo uno
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Row>
            <Form.Item>
              <Checkbox
                checked={variationCategoryData.selectMultiple? variationCategoryData.selectMultiple.value === true? true : false : false}
                onChange={(e) =>
                  changeSelectMultiple("value", e)
                }
                value={true}
              >
                Varios
              </Checkbox>
            </Form.Item>
          </Row>
          <Row>
            Min
            <Input
              style={{
                width: "20%",
              }}
              onChange={(e) =>
                changeSelectMultiple("valueMin", e)
              }
              value={variationCategoryData.selectMultiple? variationCategoryData.selectMultiple.valueMin? variationCategoryData.selectMultiple.valueMin : 0 : 0}
            />
            Max
            <Input
              style={{
                width: "20%",
              }}
              onChange={(e) =>
                changeSelectMultiple("valueMax", e)
              }
              value={variationCategoryData.selectMultiple? variationCategoryData.selectMultiple.valueMax? variationCategoryData.selectMultiple.valueMax : 0 : 0}
            />
          </Row>
        </Col>
      </Row>
      <Divider orientation="left" orientationMargin="0">
      Agregar los toppings a esta categoria
    </Divider>
      <Row>
        <Col span={24}>
        <div>
          <EditableTable 
          dataSource={variations} 
          setDataSource={setVariations}
          variationsActive={variationsActive} />
        </div>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{width:"100%"}}
          className="btn-submit"
          onClick={updateVariationCategory}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}

const initialData = [

];

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

function EditableTable(props) {
  const { dataSource, setDataSource, variationsActive } = props;
  // const [dataSource, setDataSource] = useState(data);
  const [count, setCount] = useState(initialData.length);
  const [showSelect, setShowSelect] = useState(false);

  const handleAdd = (data) => {
    data.key = count + 1;
    data.maxQuantity = 0;
    data.available = true;
    data.order = dataSource.length;
    setDataSource([...dataSource, data]);
    setCount(count + 1);
  };

  const handleClick = () => {
    setShowSelect(!showSelect); // Alterna la visibilidad del Select
  };

  const handleDelete = (indexToRemove) => {
    const filteredData = dataSource.filter((_, index) => index !== indexToRemove);
    setDataSource(filteredData);
  };

  const handleSave = (key, index, data) => {
    const newData = [...dataSource];
    newData[index][key] = data;
    setDataSource(newData);
  };


  const onInputChange = (key, index) => (e) => {
    const newData = [...dataSource];
    newData[index][key] = e.target.value;
    setDataSource(newData);
  };
  const DragHandle = SortableHandle(() => (
    <MenuOutlined
      style={{
        cursor: "grab",
        color: "#999",
      }}
    />
  ));

  const handleSwitchChange = (checked, key) => {
    // Actualiza el estado de la tabla
    console.log(dataSource)
    const newData = dataSource.map((item) =>
      item.variation._id === key ? { ...item, available: checked } : item
    );
    setDataSource(newData);
  };

  const columns = [
    {
      title: "",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Topping",
      dataIndex: "variation",
      editable: true,
      render: variation =>variation.name,
      // render: (text, record, index) => (
      //   record.name),
      // render: (text, record, index) => (
      //   <Input value={text.name} onChange={onInputChange("name", index)} />
      // ),
    },
    {
      title: "Precio",
      dataIndex: "variation",
      editable: true,
      //render: variation =>variation.price,
      render: (text, record, index) => (
      //   // <EditableCell
      //   //   title="age"
      //   //   editable={true}
      //   //   children={text}
      //   //   record={record}
      //   //   index={ index}
      //   //   handleSave={handleSave}
      //   // />      render: (text, record, index) => (
         <Input value={text.price} style={{width:"70%"}} onChange={onInputChange("price", index)} />
       ),
    },
    {
      title: "Cant. Max",
      editable: true,
      dataIndex: "variation",
      render: variation =>variation.maxQuantity,
      // render: (text, record, index) => (
      //   record.maxQuantity
      //   // <Input value={text} onChange={onInputChange("maxQuantity", index)} />
      // ),
    },
    {
      title: "Disponible",
      editable: true,
      //dataIndex: "available",
      render: variation=> (
        <Switch
          checked={variation.available}
          onChange={(checked) => handleSwitchChange(checked, variation.variation._id)}
        />
      ),
    },
    {
      title: "",
      render: (_, record,index) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="¿Está seguro que desea eliminarlo?"
            onConfirm={() => handleDelete(index)}
          >
            <DeleteFilled style={{ color: "red" }} />
          </Popconfirm>
        ) : null,
    },
  ];

  const components = {
    body: {
      row: SortableItem,
      cell: EditableCell,
    },
  };
  function sortArr(arr, order) {
    var newArr = [];
    for (var i = 0; i < order.length; i++) {
      var index = arr.findIndex((x) => x.order === order[i].toString());
      if (index >= 0) {
        newArr.push(arr.splice(index, 1));
      }
    }

    return newArr.concat(arr.sort((a, b) => (a.title > b.title ? 1 : -1)));
  }

  const updateOrderField = (data) => {
    return data.map((item, index) => ({
      ...item,
      order: index + 1, // Actualiza el campo `order` basado en la posición actual
    }));
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(dataSource, oldIndex, newIndex)
      let newArr= updateOrderField(newData);
      setDataSource(newArr);
    }
  };
  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x.order === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <div>
      <Table
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        dataSource={dataSource}
        columns={columns}
        //rowClassName="editable-row"
        className="no-border-table"
        pagination={false}
        onRow={(record, index) => ({
          index,
        })}
        bordered={false}
        onSortEnd={onSortEnd}
        useDragHandle
      />
      {showSelect ? (
        <AddVariation
          variationsActive={variationsActive}
          addVariation={handleAdd}
          handleClick={handleClick}
          setShowSelect={setShowSelect}
        />
      ) : (
        <Button
           type="dashed"
          onClick={handleClick}
          style={{ marginBottom: 16 }}
        >
          <PlusOutlined /> Agregar topping
        </Button>
      )}
    </div>
  );
}

const EditableCell = ({
  title,
  editable,
  children,
  record,
  handleSave,
  index,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(children);

  const save = () => {
    //{ ...record, [title]: value }
    handleSave(title, value, index);
    setEditing(false);
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <td {...restProps}>
      {editable ? (
        editing ? (
          <Input
            value={value}
            onChange={onChange}
            onBlur={save}
            onPressEnter={save}
          />
        ) : (
          <span onClick={() => setEditing(true)}>{value}</span>
        )
      ) : (
        children
      )}
    </td>
  );
};

function AddVariation(props) {
  const { variationsActive, addVariation, setShowSelect, handleClick } = props;
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };

  // const handleAddClick = () => {
  //   setShowSelect(!showSelect); // Alterna la visibilidad del Select
  // };

  const handleConfirmAdd = () => {
    if (selectedOption) {
      let variationSelected = variationsActive.find(
        (v) => v._id === selectedOption
      );
      const newData = {
        //key: Date.now(), // Un identificador único para cada fila
        variation: variationSelected,
      };
      addVariation(newData);
      //setDataSource([...dataSource, newData]);
      setSelectedOption(""); // Limpiar la selección después de añadir
      setShowSelect(false); // Ocultar el Select después de añadir
    }
  };

  return (
    <Row>
      <Col span={12}>
        <Select
          //prefix={<DollarCircleOutlined />}
          showSearch
          placeholder="Selecciona el ciclo"
          value={selectedOption}
          onChange={handleSelectChange}
          optionFilterProp="children"
          style={{ width: "100%" }}
          filterOption={(input, option) =>
            option.children
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {variationsActive
            ? variationsActive.map((variation) => {
                return <Option key={variation._id}> {variation.name}</Option>;
              })
            : null}
        </Select>
      </Col>
      <Col span={6}>
        <Button
          type="primary"
          onClick={handleConfirmAdd}
          style={{ marginLeft: 10 }}
        >
          Confirmar
        </Button>
      </Col>
      <Col span={6}>
        <Button
          type="primary"
          danger
          onClick={handleClick}
          style={{ marginBottom: 16 }}
        >
          Cancelar
        </Button>
      </Col>
    </Row>
  );
}
