import { basePath, apiVersion } from '../config';

// ESTADO INVENTARIO

export function getStateInventoryExportApi(token) {
    const url = `${basePath}/${apiVersion}/stateinventory-export-csv`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "text/csv",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'stateinventory.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function addStateInventoryApi(token, data){
    const url = `${basePath}/${apiVersion}/add-stateinventory`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    };

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function getStateInventoriesApi(token) {
    const url = `${basePath}/${apiVersion}/stateinventorys`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getStateInventoriesActiveApi(token, status) {
    const url = `${basePath}/${apiVersion}/stateinventories-active?active=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function updateStateInventoryApi(token, stateInventoryData, stateInventoryId) {
    const url = `${basePath}/${apiVersion}/update-stateinventory/${stateInventoryId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(stateInventoryData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function activateStateInventoryApi(token, stateInventoryId, status) {
    const url = `${basePath}/${apiVersion}/activate-stateinventory/${stateInventoryId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            active: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function deleteStateInventoryApi(token, stateInventoryId) {
    const url = `${basePath}/${apiVersion}/delete-stateinventory/${stateInventoryId}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });
}
