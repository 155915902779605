import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditUnitForm from "../EditUnitForm";
import AddUnitForm from "../AddUnitForm";
import { activateUnitApi, deleteUnitApi } from "../../../../api/billing/unit";
//import { unitExistInItemsApi } from "../../../../api/billing/item";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListUnits.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListUnits(props) {
  const { unitsActive, unitsInactive, setReloadUnits } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewUnitsActives, setViewUnitsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [unitsActiveFilter, setUnitsActiveFilter] = useState([]);
  const [unitsInactiveFilter, setUnitsInactiveFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState("name");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listActive = [];
    if (unitsActive) {
      unitsActive.forEach((element) => {
        if (typeFilter === "name") {
          if (
            element.name
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        } else {
            if (
              element.unit_code
                .toString()
                .indexOf(filterText.toUpperCase()) !== -1 ||
              filterText === ""
            ) {
              listActive.push(element);
            }
        }
      });
    }
    setUnitsActiveFilter(listActive);
  }, [filterText, unitsActive]);

  useEffect(() => {
    const listInactive = [];
    if (unitsInactive) {
      unitsInactive.forEach((element) => {
        if (typeFilter === "name") {
          if (
            element.name
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        } else {
          if (
            element.unit_code
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        }
      });
    }
    setUnitsInactiveFilter(listInactive);
  }, [filterText, unitsInactive]);

  const addUnitModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Unidad");
    setModalContent(
      <AddUnitForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadUnits={setReloadUnits}
      />
    );
  };

  const verifyUnitDeletion = async (unit) => {
    // const accessToken = getAccessToken();
    // //verifico si existe en alguna factura
    // let responseItems = await unitExistInItemsApi(
    //   accessToken,
    //   unit._id
    // );

    // if (responseItems.exist) {
    //   return false;
    // }

    return true;
  };

  return (
    <div className="list-units">
      <div className="list-units__header">
        {checkAction(permissionsActive, userToken.role, "units", "all") ||
        checkAction(permissionsActive, userToken.role, "units", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-units__header-switch">
              <Switch
                defaultChecked
                onChange={() => setViewUnitsActives(!viewUnitsActives)}
              />
              <span>
                {viewUnitsActives ? "Activos" : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "units", "all") ||
        checkAction(permissionsActive, userToken.role, "units", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addUnitModal}>
              Nuevo
            </Button>
          </>
          ) : (
            <>
            </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "units", "all") ||
      checkAction(permissionsActive, userToken.role, "units", "add") || isAdmin(roleById) ? (
        <>
          <div className="list-units__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <Radio.Group
              options={[
                { label: "Nombre", value: "name" },
                { label: "Código", value: "code" },
              ]}
              onChange={(e) => {
                setTypeFilter(e.target.value);
              }}
              value={typeFilter}
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> ejemplos de unidades'
            </h3>
          </div>
        </>
        ) : (
          <>
          </>
      )}

      {viewUnitsActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "units", "all") ||
          checkAction(permissionsActive, userToken.role, "units", "view") || isAdmin(roleById) ? (
            <>
              <UnitsActive
                unitsActive={unitsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadUnits={setReloadUnits}
                verifyUnitDeletion={verifyUnitDeletion}
                //showDeleteConfirm={showDeleteConfirm}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "units", "all") ||
          checkAction(permissionsActive, userToken.role, "units", "view") || isAdmin(roleById) ? (
            <>
              <UnitsInactive
                unitsInactive={unitsInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadUnits={setReloadUnits}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                verifyUnitDeletion={verifyUnitDeletion}
                //showDeleteConfirm={showDeleteConfirm}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function UnitsActive(props) {
  const {
    unitsActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadUnits,
    verifyUnitDeletion,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editUnit = (unit) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${
        unit.name ? unit.name : "sin descripción"
      }`
    );
    setModalContent(
      <EditUnitForm
        unit={unit}
        setIsVisibleModal={setIsVisibleModal}
        setReloadUnits={setReloadUnits}
      />
    );
  };

  const desactivateUnit = (unit) => {
    const accessToken = getAccessToken();

    activateUnitApi(accessToken, unit._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadUnits(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (unit) => {
    const accessToken = getAccessToken();
    let result = await verifyUnitDeletion(unit);
    if (!result) {
      confirm({
        title: "Eliminando Unidad",
        content: `No se puede eliminar ${unit.name} existen productos asociados, ¿Desea desactivarlo?`,
        okText: "Aceptar",
        okType: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivateUnit(unit);
        },
      });
    } else {
      confirm({
        title: "Eliminando Unidad",
        content: `¿Estás seguro que quieres eliminar a ${unit.name}?`,
        okText: "Eliminar",
        okType: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteUnitApi(accessToken, unit._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadUnits(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const edit_unit = (
    <div>
      {" "}
      <p>Editar Unidad</p>{" "}
    </div>
  );

  const desactivate_unit = (
    <div>
      {" "}
      <p>Pasar a Unidads inactivos</p>{" "}
    </div>
  );

  const delete_unit = (
    <div>
      {" "}
      <p>Eliminar Unidad</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "unit_code",
    },
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Accion",
      key: "action",
      render: (text, unit) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "units", "all") ||
          checkAction(permissionsActive, userToken.role, "units", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_unit}>
                <Button
                  className="button-unit"
                  type="default"
                  onClick={() => editUnit(unit)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "units", "all") ||
          checkAction(permissionsActive, userToken.role, "units", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_unit}>
                <Button
                  className="button-unit"
                  type="primary"
                  onClick={() => desactivateUnit(unit)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "units", "all") ||
          checkAction(permissionsActive, userToken.role, "units", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_unit}>
                <Button
                  className="button-unit"
                  type="danger"
                  onClick={() => showDeleteConfirm(unit)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={unitsActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function UnitsInactive(props) {
  const {
    unitsInactive,
    setReloadUnits,
    verifyUnitDeletion,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const activateUnit = (unit) => {
    const accessToken = getAccessToken();

    activateUnitApi(accessToken, unit._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadUnits(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (unit) => {
    const accessToken = getAccessToken();
    let result = await verifyUnitDeletion(unit);
    if (!result) {
      confirm({
        title: "Eliminando -> Unidad",
        content: `No se puede eliminar la unidad ${unit.name} existen productos asociados`,
        okText: "Aceptar",
        okType: "danger",
        okCancel: false,
      });
    } else {
      confirm({
        title: "Eliminando -> Unidad",
        content: `¿Estás seguro que quieres eliminar a ${unit.name}?`,
        okText: "Eliminar",
        okType: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteUnitApi(accessToken, unit._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadUnits(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const activate_unit = (
    <div>
      {" "}
      <p>Pasar a Unidad activos</p>{" "}
    </div>
  );

  const delete_unit = (
    <div>
      {" "}
      <p>Eliminar Unidad</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "unit_code",
    },
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, unit) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "units", "all") ||
          checkAction(permissionsActive, userToken.role, "units", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_unit}>
                <Button
                  className="button-unit"
                  type="primary"
                  onClick={() => activateUnit(unit)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "units", "all") ||
          checkAction(permissionsActive, userToken.role, "units", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_unit}>
                <Button
                  className="button-unit"
                  type="danger"
                  onClick={() => showDeleteConfirm(unit)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={unitsInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
