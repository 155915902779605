import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  notification,
  Table,
  Divider,
  Popconfirm,
  Typography,
} from "antd";
import {
  TagOutlined,
  PlusOutlined,
  CloseOutlined,
  UserOutlined,
  SaveOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { addBuyBillApi, getBuyBillsActiveApi } from "../../../../api/billing/buyBill";
import { getSuppliersActiveApi } from "../../../../api/billing/supplier";
import {
  getSalePointsByUserApi,
  getSalePointsActiveApi,
} from "../../../../api/billing/salePoint";
import { getBuyOrdersActiveApi, getBuyOrdersActiveByBranchAndStateApi, getBuyOrdersActiveByStateApi } from "../../../../api/billing/buyOrder";
import { getStateBuyOrderByCodeApi } from "../../../../api/billing/stateBuyOrder";
import { getItemsActiveApi } from "../../../../api/billing/item";
import { getAccessToken } from "../../../../api/auth";
import {
  updateAddStockInventoryApi,
  updateSubStockInventoryApi,
  getStockInventoriesApi,
} from "../../../../api/billing/stockInventory";
import { addMovementApi } from "../../../../api/billing/movement";
import { getBillTypesActiveApi } from "../../../../api/billing/billType";
import jwtDecode from "jwt-decode";

import "./AddBuyBillForm.scss";
import { formatDateCalendar , formatDateView} from "../../../../utils/formatDate";
import { COINS } from "../../../../utils/constants";
import moment from "moment";
import { getCoinTypesActiveApi } from "../../../../api/billing/coinType";
import { useBranch } from "../../../../hooks/useBranch";
import { getBranchApi } from "../../../../api/branch";
import generateCalendar from "antd/lib/calendar/generateCalendar";
import { generateInvoiceNumber } from "../../../../utils/invoiceFunctions";

const { Text } = Typography;

export default function AddBuyBillForm(props) {
  const { associatedBuyBill, setIsVisibleModal, setReloadBuyBills } = props;

  const [activeSuppliers, setActiveSuppliers] = useState([]);
  const [activeBuyOrders, setActiveBuyOrders] = useState([]);
  const [activeSalePoints, setActiveSalePoints] = useState([]);
  const [activeUserSalePoints, setActiveUserSalePoints] = useState([]);
  const [activeCoinTypes, setActiveCoinTypes] = useState([]);
  const [activeItems, setActiveItems] = useState([]);
  const [listStockInventory, setListStockInventory] = useState([]);
  const [activeBillTypes, setActiveBillTypes] = useState([]);
  const [idBuyBill, setIdBuyBill] = useState("");
  const [cancelledBill, setCancelledBill] = useState(false);
  // const [itemsData, setItemsData] = useState([]);
  const [billsData, setBillsData] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [branchData, setBranchData] = useState([]);

  const accessToken = getAccessToken();
  const metaToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  const { branch } = useBranch();

  const [inputs, setInputs] = useState({
    code: "",
    billType: "",
    buyOrders: [],
    saleCondition: "",
    date: formatDateCalendar(moment()),
    inputDate: formatDateCalendar(moment()),
    concept: "",
    total: 0,
    items: [],
    item: "",
    quantity: 1,
    unitValue: "",
    //coin:"pesos",
    paid: false,
    active: true,
    branch:branch
  });

  // useEffect(() => {
  //   getSalePointsByUserApi(accessToken, metaToken._id).then((response) => {
  //     setActiveUserSalePoints(response.salePoints);
  //   });
  // }, []);

  useEffect(() => {
    getSuppliersActiveApi(accessToken, true).then((response) => {
      setActiveSuppliers(response.suppliers);
    });
  }, []);

  useEffect(() => {
    getSalePointsActiveApi(accessToken, true).then((response) => {
      setActiveSalePoints(response.salePoints);
    });
  }, []);

  useEffect(() => {
    getItemsActiveApi(accessToken, true).then((response) => {
      setActiveItems(response.items);
    });
  }, []);

  useEffect(() => {
    getStockInventoriesApi(accessToken).then((response) => {
      setListStockInventory(response.stockInventories);
    });
  }, []);

  useEffect(() => {
    getBillTypesActiveApi(accessToken, true).then((response) => {
      setActiveBillTypes(response.billTypes);
    });
  }, []);

  useEffect(() => {
    getCoinTypesActiveApi(accessToken, true).then((response) => {
      setActiveCoinTypes(response.coinTypes);
    });
  }, []);

  useEffect(() => {
     getStateBuyOrderByCodeApi(accessToken, 1).then((response) => {
        if(response.stateBuyOrder){
          // getBuyOrdersActiveByStateApi(accessToken, true, response.stateBuyOrder._id).then((response) => {
          //   setActiveBuyOrders(response.buyOrders);
          // });
          getBuyOrdersActiveByBranchAndStateApi(accessToken,branch, true, response.stateBuyOrder._id).then((response) => {
            setActiveBuyOrders(response.buyOrders);
          });
        }
    });
  }, []);

  useEffect(() => {
    getBranchApi(accessToken, branch).then((response) => {
      setBranchData(response.branch);
    });
  }, [branch]); 

  useEffect(() => {
    //verifico que exista el id de la factura de compra
    if (idBuyBill !== "") {
      //verifico que no sea anulacion de factura
      if (!cancelledBill) {
        //Por cada item verifico que tenga inventario, en caso afirmativo actualizo stock y creo un movimiento
        inputs.items.map((item) => {
          let inventory = listStockInventory
            ? listStockInventory.find((i) => i.item._id === item._item)
            : false;
          if (inventory) {
            let data = {
              quantity: item.quantity,
            };

            addStock(accessToken, inventory._id, data, item);
          }
          return true;
        });
      } else if (
        inputs.cancellationReason === "refund" ||
        inputs.cancellationReason === "cancelled"
      ) {
        //Verifico el nuevo detalle de items
        inputs.items.map((item) => {
          //primero verifico si tiene inventario
          let inventory = listStockInventory.find(
            (i) => i.item._id === item._item || i.item._id === item._item._id
          );
          if (inventory) {
            let data = {
              quantity: item.quantity,
            };
            subStock(accessToken, inventory._id, data, item);
          }
          return true;
        });
      }
    }
  }, [idBuyBill]);

  const isFormValid = (e) => {
    let errorExists = false;
    if (
      !inputs.billType ||
      !inputs.date ||
      !inputs.inputDate ||
      !inputs.supplier 
      // !inputs.buyer ||
      //!inputs.salePoint
    ) {
      notification["error"]({
        message:
          "Obligatorios: Tipo de comprobante, Punto de venta , Proveedor, Fecha y Fecha de Ingreso.",
      });
      errorExists = true;
    }
    if (inputs.items.length === 0) {
      notification["error"]({
        message: "Obligatorio: Debe seleccionar al menos un producto.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addBuyBill = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      // setButtonLoading(true);

      const accessToken = getAccessToken();
      await addBuyBillApi(accessToken, inputs, metaToken._id)
        .then((response) => {
          if (
            response === "ERR_CONNECTION_REFUSED" ||
            response === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: "Factura de compra creada",
            });
            setIsVisibleModal(false);
            setIdBuyBill(response.buyBill._id);
          }
          setReloadBuyBills(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  const addStock = (accessToken, inventoryId, data, item) => {
    updateAddStockInventoryApi(accessToken, inventoryId, data)
      .then((response) => {
        if (
          response.message === "ERR_CONNECTION_REFUSED" ||
          response.message === "Failed to fetch" ||
          response === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          return false;
        } else if (response.code !== 200) {
          notification["error"]({
            message: response.message,
          });
          return false;
        } else {
          // notification["success"]({
          //   message: "Stock actualizado",
          // });
          let movementData = {
            date: formatDateCalendar(moment()),
            movement_type: "input",
            item: item._item,
            origin: "buyBill",
            buyBill: idBuyBill,
            quantity: data.quantity,
            user: jwtDecode(accessToken).id,
          };
          return addMovementApi(accessToken, movementData);
        }
      })
      .then((response) => {
        if (response) {
          if (
            response.message === "ERR_CONNECTION_REFUSED" ||
            response.message === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            // notification["success"]({
            //   message: "Movimiento creado",
            // });
          }
        }
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const subStock = (accessToken, inventoryId, data, item) => {
    updateSubStockInventoryApi(accessToken, inventoryId, data)
      .then((response) => {
        if (
          response.message === "ERR_CONNECTION_REFUSED" ||
          response.message === "Failed to fetch" ||
          response === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          return false;
        } else if (response.code !== 200) {
          notification["error"]({
            message: response.message,
          });
          return false;
        } else {
          // notification["success"]({
          //   message: "Stock actualizado",
          // });
          let movementData = {
            date: formatDateCalendar(moment()),
            movement_type: "output",
            item: item._item,
            origin: "buyBill",
            buyBill: idBuyBill,
            quantity: data.quantity,
            user: jwtDecode(accessToken).id,
            observation: "comprobante modificado",
          };
          return addMovementApi(accessToken, movementData);
        }
      })
      .then((response) => {
        if (response) {
          if (
            response.message === "ERR_CONNECTION_REFUSED" ||
            response.message === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            // notification["success"]({
            //   message: "Movimiento creado",
            // });
          }
        }
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  return (
    <div className="add-buybill-form">
      <AddForm
        metaToken={metaToken}
        inputs={inputs}
        setInputs={setInputs}
        addBuyBill={addBuyBill}
        activeSuppliers={activeSuppliers}
        activeSalePoints={activeSalePoints}
        activeBuyOrders={activeBuyOrders}
        accessToken={accessToken}
        activeItems={activeItems}
        activeBillTypes={activeBillTypes}
        activeUserSalePoints={activeUserSalePoints}
        activeCoinTypes={activeCoinTypes}
        // itemsData={itemsData}
        // setItemsData={setItemsData}
        billsData={billsData}
        setBillsData={setBillsData}
        cancelledBill={cancelledBill}
        setCancelledBill={setCancelledBill}
        buttonLoading={buttonLoading}
        branchData={branchData}
      />
    </div>
  );
}

function AddForm(props) {
  const {
    inputs,
    setInputs,
    addBuyBill,
    activeSuppliers,
    activeUserSalePoints,
    activeBuyOrders,
    activeItems,
    activeBillTypes,
    buttonLoading,
    activeCoinTypes,
    branchData
    // setCancelledBill,
  } = props;

  const { Option } = Select;
  const [optionsActiveBuyOrders, setOptionsActiveBuyOrders] = useState([]);
  const [isProduct, setIsProduct] = useState(false);
  const [inputCode, setInputCode]=useState("");

  useEffect(() => {
    setOptionsActiveBuyOrders(activeBuyOrders);
  }, [activeBuyOrders]);

  useEffect(() => {
  if(!inputs.salePoint && activeUserSalePoints.length > 0){
    setInputs({...inputs, salePoint: activeUserSalePoints[0]._id});
  }
  }, [activeUserSalePoints]);

  useEffect(() => {
    if (inputs.buyOrders.length > 0) {
      let updateItems = [];
      inputs.buyOrders.map((buyorder) => {
        let order = activeBuyOrders.find((i) => i._id === buyorder);
        if (order) {
          updateItems = updateItems.concat(order.items);
          inputs.supplier? setInputs({
            ...inputs,
            items: updateItems,
          }) : setInputs({
            ...inputs,
            supplier: order.supplier._id,
            items: updateItems,
          })  ;
        }
        return true;
      });
    } else {
      setInputs({ ...inputs, items: [], supplier: null});
    }
  }, [inputs.buyOrders]);

  //Filtrar lista por Proveedor
  useEffect(() => {
    if(inputs.supplier){
        setOptionsActiveBuyOrders(
        activeBuyOrders.filter((i) => i.supplier._id === inputs.supplier)
      );
      // setInputs({ ...inputs, buyOrders: [] });
    }else{
      setOptionsActiveBuyOrders(activeBuyOrders);
    }
  }, [inputs.supplier]);

  const setListItems = (items) => {
    setInputs({ ...inputs, items: items });
  };

  const addItems = () => {
    if (inputs.item && inputs.unitPrice && inputs.quantity) {
      //Verifico si el item no esta en el detalle
      let itemExistInDetail = inputs.items.find(
        (i) => i._item._id === inputs.item || i._item === inputs.item
      );
      if (!itemExistInDetail) {
        let item = activeItems.filter((i) => i._id === inputs.item)[0];
        let description = item.description;
        let item_code = item.item_code;

        const newData = {
          _item: inputs.item,
          item_code: item_code,
          description: description,
          unitValue: inputs.unitValue,
          unitPrice: inputs.unitPrice,
          quantity: inputs.quantity,
        };
        setInputs({ ...inputs, items: [...inputs.items, newData] });
        //setItemsData([...itemsData, newData]);
      } else {
        notification["error"]({
          message: "El producto ya se encuentra en el detalle de la factura",
        });
      }
    } else {
      notification["error"]({
        message:
          "Debe elegir al menos un producto e ingresar el precio y la cantidad requerida",
      });
    }
  };

  useEffect(() => {
    let totals = 0;
    inputs.items.forEach(({ unitPrice, quantity }) => {
      totals += unitPrice * quantity;
    });
    //setInputs({ ...inputs, subtotal: totals });
    setInputs({ ...inputs, total: totals });
  }, [inputs.items]);

  useEffect(() => {
    if(inputs.item){
      let item = activeItems.filter((i) => i._id === inputs.item)[0];
      let type = item.type;   
      if(type.description){
        return setIsProduct(type.description.toLowerCase().includes( "producto"));
      }
    }
  }, [inputs.item]);

    // Manejar el evento de presionar Enter en el input
    const handleEnterPress = (e) => {
      e.preventDefault();
      let value=generateInvoiceNumber(e.target.value);
      setInputs({ ...inputs, code: value});
      setInputCode(value);
    }

  return (
    <Form
      className="form-add"
      layout="vertical"
      bodyStyle={{ height: "100vh" }}
      size={"middle"}
      onSubmit={addBuyBill}
    >
      {/* <Divider orientation="left">Datos</Divider> */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">Código</label>
          <Form.Item required>
            <Input
              name="code"
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputCode}
              onChange={(e) => setInputCode(e.target.value)}
              onPressEnter={handleEnterPress}  // Activar al presionar Enter
              //onChange={(e) => setInputs({ ...inputs, code: generateInvoiceNumber(e) })}
              // disabled={true}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span>Tipo comprobante
          </label>
          <Form.Item rules={[{ required: true }]}>
            <Select
              showSearch
              name="billType"
              value={inputs.billType}
              onChange={(e) => setInputs({ ...inputs, billType: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activeBillTypes
                ? activeBillTypes.map((i) => {
                    return <Option key={i.description}>{i.description}</Option>;
                  })
                : "No tipo de comprobantes"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Sucursal
          </label>
          <Form.Item>
          <Input
              showSearch
              name="branch"
              value={branchData.name? branchData.name : branchData.business? branchData.business.name : ""}
              //onChange={(e) => setInputs({ ...inputs, branch: e.target.value })}
              disabled={true}
            />
            {/* <Select
              showSearch
              name="salePoint"
              value={inputs.salePoint}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  salePoint: e,
                })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activeUserSalePoints
                ? activeUserSalePoints.map((i) => {
                    return (
                      <Option key={i._id}>
                        {i.salepoint_code} {"-"}
                        {i.fantasy_name}
                      </Option>
                    );
                  })
                : "No hay puntos de venta disponibles"}
            </Select> */}
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Orden de Compra</label>
          <Form.Item>
            <Select
              showSearch
              mode="multiple"
              name="buyOrders"
              value={inputs.buyOrders}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  buyOrders: e,
                })
              }
              maxTagCount="responsive"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {optionsActiveBuyOrders
                ? optionsActiveBuyOrders.map((i) => {
                    return (
                      <Option key={i._id}>
                        {i.code + "-" + i.supplier.denomination}
                      </Option>
                    );
                  })
                : "No hay ordenes de compra disponibles"}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Proveedor
          </label>
          <Form.Item>
            <Select
              showSearch
              prefix={<UserOutlined />}
              name="supplier"
              value={inputs.supplier}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  supplier: e,
                })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activeSuppliers
                ? activeSuppliers.map((i) => {
                    return <Option key={i._id}>{i.denomination}</Option>;
                  })
                : "No hay proveedores disponibles"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Fecha
          </label>
          <Form.Item rules={[{ required: true }]}>
            <Input
              type="date"
              placeholder="Fecha"
              value={inputs.date}
              onChange={(e) => setInputs({ ...inputs, date: e.target.value })}
              required
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Fecha Ingreso
          </label>
          <Form.Item rules={[{ required: true }]}>
            <Input
              type="date"
              name="inputDate"
              placeholder="Fecha de ingreso"
              value={inputs.inputDate}
              onChange={(e) =>
                setInputs({ ...inputs, inputDate: e.target.value })
              }
              required
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Fecha Vencimiento</label>
          <Form.Item rules={[{ required: true }]}>
            <Input
              type="date"
              name="expirationDate"
              placeholder="Fecha de vencimiento"
              value={inputs.expirationDate}
              onChange={(e) =>
                setInputs({ ...inputs, expirationDate: e.target.value })
              }
              required
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {!isProduct?
        <>
        <Col span={6}>
          <label className="control-label">Período desde</label>
          <Form.Item rules={[{ required: true }]}>
            <Input
              type="date"
              name="periodFrom"
              placeholder="Periodo desde"
              value={inputs.periodFrom}
              onChange={(e) =>
                setInputs({ ...inputs, periodFrom: e.target.value })
              }
              required
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Hasta</label>
          <Form.Item rules={[{ required: true }]}>
            <Input
              type="date"
              placeholder="Periodo hasta"
              value={inputs.periodUntil}
              onChange={(e) =>
                setInputs({ ...inputs, periodUntil: e.target.value })
              }
              required
            />
          </Form.Item>
        </Col>
        </>: null}
        <Col span={6}>
          <label className="control-label">Moneda</label>
          <Form.Item rules={[{ required: true }]}>
            <Select
              showSearch
              name="coin"
              value={inputs.coinType}
              //defaultValue={"pesos"}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  coin: e,
                })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activeCoinTypes
                ? activeCoinTypes.map((i) => {
                    return <Option key={i._id}>{i.description}</Option>;
                  })
                : "No hay monedas disponibles"}
            </Select>
          </Form.Item>
        </Col>
        {inputs.coin !== "pesos" && inputs.coin ? (
          <Col span={6}>
            <label className="control-label">Cotizacion</label>
            <Form.Item rules={[{ required: true }]}>
              <InputNumber
                placeholder="Cotizacion"
                value={inputs.quotation}
                onChange={(e) => setInputs({ ...inputs, quotation: e })}
                decimalSeparator={","}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        ) : null}
      </Row>
      <Divider orientation="left" style={{ margin: 5 }}></Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={5}>
          <label className="control-label">
            <span className="control-required">*</span> Producto/Servicio
          </label>
          <Form.Item>
            <Select
              showSearch
              placeholder="Seleccione un producto/servicio"
              name="items"
              value={inputs.item}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  item: e,
                  // unitValue: activeItems.filter((i) => i._id === e)[0].price1,
                })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activeItems
                ? activeItems.map((i) => {
                    return <Option key={i._id}>{i.description}</Option>;
                  })
                : "No hay productos disponibles"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <label className="control-label">Valor Unitario</label>
          <Form.Item>
            <Input
              name="unitValue"
              value={inputs.unitValue}
              // onChange={(e) =>
              //   setInputs({ ...inputs, unitValue: e.target.value })
              // }
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <label className="control-label">Precio Unitario</label>
          <Form.Item>
            <Input
              name="unitPrice"
              value={inputs.unitPrice}
              onChange={(e) =>
                setInputs({ ...inputs, unitPrice: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <label className="control-label">Cantidad</label>
          <Form.Item>
            <InputNumber
              placeholder="Cantidad"
              value={inputs.quantity}
              onChange={(e) => setInputs({ ...inputs, quantity: e })}
              min={1}
              max={100}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item>
            <Button
              type="primary"
              className="button-add"
              size="middle"
              onClick={addItems}
            >
              <PlusOutlined /> Agregar
            </Button>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          {/* {itemsData.length > 0 ? ( */}
          <Items itemsData={inputs.items} setItemsData={setListItems} />
          {/* ) : null} */}
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            TOTAL
          </label>
          <Form.Item>
            <Input
              type="text"
              disabled={true}
              value={inputs.total}
              // onChange={(e) => setInputs({ ...inputs, total: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            {/* {itemsData.length > 0 ? (
              <Total hidden="true" itemsData={itemsData} totalSum={totalSum} />
            ) : null} */}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addBuyBill}
          // loading={buttonLoading}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}

function Items(props) {
  const { itemsData, setItemsData } = props;
  //console.log(itemsData);

  const columns = [
    {
      title: "Código",
      dataIndex: "item_code",
      render: (_item, row) =>
        row.item_code ? row.item_code : row._item.item_code,
      width: "10%",
      editable: false,
    },
    {
      title: "Descripción",
      dataIndex: "description",
      render: (_item, row) =>
        row.description ? row.description : row._item.description,
      width: "20%",
      editable: true,
    },
    {
      title: "Valor Unitario",
      dataIndex: "unitValue",
      render: (_item, row) =>
        row.unitValue ? "$" + row.unitValue.value + " - " + formatDateView(row.unitValue.date) : null,
      width: "20%",
      editable: false,
    },
    {
      title: "Precio Unitario",
      dataIndex: "unitPrice",
      width: "15%",
      editable: true,
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      width: "10%",
      editable: true,
    },
    {
      title: "Total",
      // dataIndex:"quantity",
      render: (_item, row) =>
        row.unitPrice ? row.unitPrice * row.quantity : 0,
      width: "10%",
      editable: false,
    },
  ];
  return (
    <EditableItemTable
      columnsData={columns}
      data={itemsData}
      setData={setItemsData}
      rowKey="_item"
    />
  );
}

function EditableItemTable(props) {
  const { data, setData, columnsData, rowKey } = props;
  const [form] = Form.useForm();
  // const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Por favor ingrese ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record) => record._item === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      unitValue: "",
      quantity: "",
      ...record,
    });
    setEditingKey(record._item);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item._item);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      //console.log("Validate Failed:", errInfo);
    }
  };

  const showDeleteConfirm = (record) => {
    const d = data.filter((i) => i !== record);
    if (d.length === 0) {
      setData([]);
    } else {
      setData(d);
    }
  };

  const columns = [
    ...columnsData,
    {
      title: "Acción",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="primary"
              size="small"
              onClick={() => save(record._item)}
            >
              <SaveOutlined />
            </Button>
            <Popconfirm title="Está seguro de cancelar?" onConfirm={cancel}>
              <Button type="danger" size="small">
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Button
              type="default"
              size="small"
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <EditOutlined />
            </Button>
            <Button
              className="button-item"
              type="danger"
              size="small"
              onClick={(e) => showDeleteConfirm(record)}
            >
              <DeleteOutlined />
            </Button>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "quantity" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        size="small"
        style={{ margin: 20 }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        rowKey={rowKey}
        scroll={{ y: 150 }}
        summary={(pageData) => {
          let total = 0;

          pageData.forEach(({ unitPrice, quantity }) => {
            total += unitPrice * quantity;
          });

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell>Total</Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text type="danger">{total}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </Form>
  );
}
